/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Icon } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import {
  CONTENT_AD,
  CONTENT_EPISODE,
  CONTENT_MOVIE,
  CONTENT_SEASONS,
  CONTENT_SERIES,
  CONTENT_TRAILER,
  CONTENT_VIDEO,
  ModuleName,
  getModulePermissions,
  getUrlWithOptions,
} from '../../utils';
import { ContentDetailData, ContentLocalesData } from '../../interfaces/Content';
import useGoBack from '../../hooks/useGoBack';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import PublicationStateButtons from '../../components/PublicationStateButtons/PublicationStateButtons';
import Loading from '../../components/Loading/Loading';

import DetailSeriesSeasons from './DetailSeriesSeasons';
import DetailEpisode from './DetailEpisodeMovie';
import DetailAdTrailer from './DetailAdTrailer';
import { saveAs } from 'file-saver'
import Button from '../../components/Button/Button';
import DeleteConfirmationModal from '../../components/Modals/DeleteConfirmationModal';
import { withModal } from '../../components/Modals';
import { useSelector } from 'react-redux';
const DeleteButton = withModal(Button, DeleteConfirmationModal);

const ContentManagementDetail = () => {
  const { id } = useParams();
  const { apiGet, apiDelete } = useApi();
  const navigate = useNavigate();
  const { goBackLabel, goBack } = useGoBack(ROUTES.CONTENT_MANAGEMENT, 'Back');
  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState<ContentDetailData>();
  const [currentLocalesData, ] = useState<ContentLocalesData[]>([]);
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentId, setCurrentId] = useState<string>();
  const [isSavedData, setIsSavedData] = useState(false);
  const CONTENT_ENDPOINT = '/ops/content';
  const contentUrl = getUrlWithOptions(`${CONTENT_ENDPOINT}/${id}`);
  const permissions = useSelector((state:any) => state.permissions);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.CONTENT_MANAGEMENT_SCREEN, permissions);

  useEffect(() => {
    if (id?.length) {
      setCurrentId(id);
      getData();
      // getLocaleData();
      setLoading(true);
    }
  }, [id]);

  useEffect(() => {
    if (isSavedData) {
      if (id?.length) {
        getData();
        // getLocaleData();
        setIsSavedData(false);
      }
    }
  }, [isSavedData]);

  const onDelete = async () => {
    const { error } = await apiDelete(getUrlWithOptions(`/ops/content/${id}`)
    );
    // setShowDeleteModal(false);

    if (!error) {
      navigate(ROUTES.HOME);
      // const { data } = await refetchGet();

      // setString(data?.results?.items || []);
      // setTotalCount(data?.results?.totalCount);
    }
    // window.open(data.url);
  };

  const getData = async () => {
    const { data } = await apiGet(contentUrl);
    setCurrentData(data);
    setLoading(false);
    setCurrentTitle(data?.display_name || data?.name);
  };

  const getTypeOfContent = (contentData, localesData, contentId) => {
    const type = contentData.content_type;
    if (type === CONTENT_SERIES || type === CONTENT_SEASONS) {
      return (
        <DetailSeriesSeasons
          data={contentData}
          localesData={localesData}
          id={contentId}
          onSavedData={() => setIsSavedData(true)}
          onLocaleInformationSave={() => {}}
        />
      );
    } else if (type === CONTENT_EPISODE || type === CONTENT_MOVIE || type === CONTENT_VIDEO || type === CONTENT_TRAILER) {
      return (
        <DetailEpisode
          data={contentData}
          localesData={localesData}
          id={contentId}
          onSavedData={() => setIsSavedData(true)}
        />
      );
    } else if (type === CONTENT_AD) {
      return (
        <DetailAdTrailer
          data={contentData}
          id={contentId}
          onSavedData={() => setIsSavedData(true)}
          localesData={localesData}
        />
      );
    }
  };

  const exportAsJSON = (id) => {
    let blob = new Blob([JSON.stringify(currentData)], { type: 'application/json' })
    
    saveAs(blob, `${id}.json`);
  };

  
  return (
    <>
    <div 
        className="SubHeader-view">
      <SubHeader
        icon={<Icon name="ARROW_RIGHT" rotate="180" />}
        title={currentTitle ? currentTitle : ''}
        subtitle={goBackLabel}
        detailMode
        onBack={goBack}
        actions={
          <><PublicationStateButtons
            publication={currentData?.publication}
            id={currentId}
            allowRevert
            allowPreview={currentData?.type !== CONTENT_TRAILER}
            onExport={() => exportAsJSON(currentId)} 
            disabled={!hasWriteAccess}/>
            <DeleteButton
            className="button"
              variant="secondary"
              modalProps={{
                title: 'Delete',
                children: (
                  <>
                    Permanently delete 1 media from the system? This cannot be undone.
                  </>
                ),
              }}
              onModalConfirm={onDelete}
              disabled={!hasWriteAccess}
            >
              Delete
            </DeleteButton></>
          
        }
      />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {currentData &&
            // currentLocalesData?.length &&
            getTypeOfContent(currentData, currentLocalesData, currentId)}
        </>
      )}
    </>
  );
};

export default ContentManagementDetail;
