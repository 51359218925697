/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useTable,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useSortBy,
  useFilters,
} from "react-table";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import update from "immutability-helper";
import classnames from "classnames";
import { DropdownMenu, DropdownMenuItem, Icon } from "@brightcove/studio-components";

import Pagination from "../Pagination/Pagination";

import Row from "./Row";
import "./Table.scss";
import { SVGImage } from "../../assets/images";

const IndeterminateCheckbox: FC<any> = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          className="Table-checkbox"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

const IndeterminateRadioButton: FC<any> = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="Table-custom-radio-button">
        <label>
          <input type="radio" ref={resolvedRef} {...rest} />
          <span></span>
        </label>
      </div>
    );
  }
);

export interface TableProps {
  data: unknown[];
  columns: unknown[];
  onChangeSelection?: (val: []) => void;
  hasPagination?: boolean;
  hasSelection?: boolean;
  hasDeletion?: boolean;
  hasMoveTopDown?: boolean;
  hasDragging?: boolean;
  className?: string;
  globalFilter?: any;
  columnFilters?: unknown[];
  setGlobalFilter?: (val: string) => void;
  onChangeRowsQuantity?: (val: number) => void;
  onPaginationChange?: (val: any) => void;
  onClickDeleteRow?: (val: string, val2: any) => void;
  onDragRow?: (val: any[]) => void;
  defaultSortingValues?: Array<{ id: string; desc: boolean }>;
  pageCount?: number;
  pageIndex?: number;
  pageSize?: number;
  onClickSort?: (val1: string, val2: string) => void;
  singleSelection?: boolean;
  resetSelections?: boolean;
  totalCount?: number;
  manualPagination?: boolean;
}

const Table: FC<TableProps> = ({
  data: currentData,
  columns: tableColumns,
  onChangeSelection,
  onChangeRowsQuantity,
  onPaginationChange,
  onClickSort,
  onClickDeleteRow,
  onDragRow,
  hasPagination,
  hasSelection,
  hasDeletion,
  hasMoveTopDown,
  hasDragging,
  className,
  globalFilter: currentFilter,
  columnFilters,
  defaultSortingValues,
  pageCount: controllerPageCount = 0,
  pageIndex: controlledPageIndex = 0,
  pageSize: controlledPageSize = 20,
  singleSelection,
  resetSelections = true,
  totalCount,
  manualPagination
}) => {

  if (hasMoveTopDown) {
    tableColumns.push({
      id: "moveTopDown",
      className: "moveTopDown",
      Cell: ({ row }) => {
        if (!row.original.disableMoveTopDown) {
          return (
            <div>
              <DropdownMenu
                className="collection-item-menu"
                iconName="MORE_VERTICAL"
                label=""
                buttonProps={{
                  className: "collection-item-menu-button"
                }}>
                <div className="dropdown-content">
                  <DropdownMenuItem
                    onClick={() => {
                      moveRow(row.id, 0)
                    }}
                  >
                    Move to top
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      moveRow(row.id, totalCount!-1)
                    }}
                  >
                    Move to bottom
                  </DropdownMenuItem>
                </div>
              </DropdownMenu>
            </div>
          );
        }
      },
    })
  }

  if (hasDeletion) {
    tableColumns.push({
      id: "deletion",
      accessor: "deletion",
      className: "deletion",
      Cell: ({ row: { original } }) => {
        if (!original.disableDeletion) {
          return (
            <div
              onClick={() => {
                onClickDeleteRow && onClickDeleteRow(original.id, original);
              }}
            >
              Delete
            </div>
          );
        }
      },
    });
    let newElement = {
      id: "deletion",
      accessor: "deletion",
      className: "deletion",
      Cell: ({ row: { original } }) => {
        if (!original.disableDeletion) {
          return (
            <div
              onClick={() => {
                onClickDeleteRow && onClickDeleteRow(original.id, original);
              }}
            ><img
                // className="status-icon"
                alt="check mark"
                src={SVGImage.trashcan}
              />
              {/* <Icon name="ARCHIVE" color={"#3886F7"}/> */}
            </div>
          );
        }
      },
    };
    let prev = tableColumns.filter((element: any) => {
      if (element.id !== newElement.id) return element;
    });
    tableColumns = [...prev, newElement];
  }

  if (hasDragging) {
    tableColumns.push({
      id: "dragging",
      className: "dragging",
      Cell: ({ row }) => {
        if (!row.original.disableDragging) {
          return <img
            // className="status-icon"
            alt="check mark"
            src={SVGImage.dragIcon}
          />;
        }
      },
    });
  }

  const data: any = useMemo(() => currentData, [currentData]);
  const columns = useMemo(() => tableColumns, [hasDeletion, hasDragging, hasMoveTopDown]);
  const [storedData, setStoredData] = useState(data);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy },
    selectedFlatRows,
    setGlobalFilter,
    setAllFilters,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data: storedData,
      initialState: {
        pageIndex: controlledPageIndex,
        pageSize: controlledPageSize,
        sortBy: [
          ...(defaultSortingValues
            ? defaultSortingValues
            : [{ id: undefined }]),
        ],
      }, // Pass our hoisted table state
      manualPagination: manualPagination === false ? false : true, // Tell the usePagination
      pageCount: controllerPageCount,
      /* autoResetPage: false, */
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSelectedRows: resetSelections,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!hasSelection) {
        return null;
      }
      //console.log("visibleColumns"+JSON.stringify(columns))
      hooks.visibleColumns.push((visibleColumns) => [
        // Let's make a column for selection
        {
          id: "selection",
          className: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({
            toggleRowSelected,
            rows: selectionRows,
            selectedFlatRows: selectedRows,
            getToggleAllRowsSelectedProps,
          }) => {
            const containsDisabledRow = !!data.find(
              (item) => item.disableSelection
            );
            let modifiedToggleAllRowsProps;

            if (containsDisabledRow) {
              const style = {
                cursor: "pointer",
              };
              // consider all rows selected if all but one are checked
              const checked: boolean =
                selectionRows.length > 1 &&
                selectionRows.length - selectedRows.length === 1;
              const title = "Toggle All Rows Selected";
              // header is indeterminate while it is unchecked and some rows are selected
              const indeterminate: boolean =
                !checked && selectedRows.length > 0;
              // header is disabled if only one row exists
              const disabled: boolean = selectionRows.length === 1;
              const overiddenOnChange = (
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                selectionRows.forEach(({ id, original }) => {
                  if (!original.disableSelection) {
                    toggleRowSelected(id, event.currentTarget.checked);
                  }
                });
              };

              modifiedToggleAllRowsProps = {
                onChange: overiddenOnChange,
                style,
                checked,
                title,
                indeterminate,
                disabled,
              };
            }

            return (
              <div>
                {!singleSelection && (
                  <>
                    {containsDisabledRow ? (
                      <IndeterminateCheckbox {...modifiedToggleAllRowsProps} />
                    ) : (
                      <IndeterminateCheckbox
                        {...getToggleAllRowsSelectedProps()}
                      />
                    )}
                  </>
                )}
              </div>
            );
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              {row.original.disableSelection ? null : (
                <>
                  {singleSelection ? (
                    <IndeterminateRadioButton
                      {...row.getToggleRowSelectedProps({
                        onChange: () => {
                          toggleAllRowsSelected(false);
                          row.toggleRowSelected(!row.isSelected);
                        },
                      })}
                    />
                  ) : (
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                    />
                  )}
                </>
              )}
            </div>
          ),
        },
        ...visibleColumns,
      ]);
    }
  );
  const [dragPageSize, setDragPageSize] = useState(20);
  const stateRef:any = useRef();
  stateRef.current = dragPageSize;

  useEffect(() => {
    setStoredData(data);
  }, [data]);
  useEffect(() => {
    if (onChangeSelection) {
      onChangeSelection(selectedFlatRows.map((d) => d.original));
    }
  }, [selectedFlatRows]);
  useEffect(() => {
    if (currentFilter) {
      setGlobalFilter(currentFilter);
    }
  }, [currentFilter]);
  useEffect(() => {
    if (columnFilters) {
      setAllFilters(columnFilters);
    }
  }, [columnFilters]);
  useEffect(() => {
    if (onChangeRowsQuantity) {
      onChangeRowsQuantity(rows.length);
    }
  }, [rows.length]);
  useEffect(() => {
    setDragPageSize(pageSize)
    if (onPaginationChange) {
      onPaginationChange({ pageSize, pageIndex });
    }
  }, [pageIndex, pageSize]);
  useEffect(() => {
    if (onClickSort) {
      const { id, desc } = sortBy[0];
      const order = desc ? "dsc" : "asc";

      onClickSort(id, order);
    }
  }, [sortBy]);
  useEffect(() => {
    if (totalCount) {
      if (totalCount <= pageSize) {
        gotoPage(0);
      }
    }
  }, [pageSize, totalCount]);

  const moveRow = useCallback((dragIndex, hoverIndex) => {
    setStoredData((prevRows) => {
      const reorderedRows = update(prevRows, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevRows[dragIndex]],
        ],
      });
      onDragRow && onDragRow(reorderedRows);

      return reorderedRows;
    });
  }, []);

  return (
    <>
      <div className={classnames("Table", className)}>
        <DndProvider backend={HTML5Backend}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.sortable
                            ? column.getSortByToggleProps({
                              className: column.className,
                            })
                            : { className: column.className }
                        )}
                        style={{ width: column.colWidth }}
                        key={column.id}
                      >
                        {column.render("Header")}
                        {column.id !== "selection" && (
                          <span
                            className={`sorting-icon${column.sortable ? " " : "-visibility"
                              }`}
                          >
                            <Icon
                              name="triangle_right_filled"
                              rotate={
                                column.isSorted
                                  ? column.isSortedDesc === undefined
                                    ? "90"
                                    : column.isSortedDesc === false
                                      ? "90"
                                      : "270"
                                  : "270"
                              }
                              color={"#DDD"}
                            />
                          </span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);

                return (
                  <Row
                    key={row.original.id}
                    id={row.original.id}
                    index={index}
                    row={row}
                    moveRow={moveRow}
                  />
                );
              })}
            </tbody>
          </table>
        </DndProvider>
        {hasPagination && (
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            setPageSize={setPageSize}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalCount={totalCount}
          />
        )}
      </div>
    </>
  );
};

export default Table;
