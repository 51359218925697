import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Flex } from '@brightcove/studio-components';
import './Counter.scss';

interface CounterProps {
  className?: string;
  initialCount?: number;
  onChange?: (val: number) => void;
}

const Counter: FC<CounterProps> = ({ initialCount, onChange, className }) => {
  const [count, setCount] = useState<any>(initialCount || 1);

  useEffect(() => {
    initialCount && setCount(initialCount);
  }, [initialCount]);

  const updateCount = (event) => {
    if (
      event.target.value !== "0" &&
      Number(event.target.value) <= 50 &&
      Number(event.target.value) >= 1
    ) {
      setCount(event.target.value);
      onChange?.(Number(event.target.value));
    } else if (Number(event.target.value) < 1) {
      setCount("");
      onChange?.(1);
    }
  };
  return (
    <Flex className={classNames('Counter', className)} alignItems="center">
      <div
        className="Counter-icon"
        onClick={() => {
          const newCount = Math.max(count - 1, 1);

          setCount(newCount);
          onChange?.(newCount);
        }}
      >
        -
      </div>
      <input className="Counter-input mx-2" type="number" value={count} min={1} onChange={updateCount}/>
      <div
        className="Counter-icon"
        onClick={() => {
          const newCount = Number(count) + 1;

          setCount(newCount);
          onChange?.(newCount);
        }}
      >
        +
      </div>
    </Flex>
  );
};

export default Counter;
