/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, KeyboardEvent, useCallback } from 'react';
import { Icon } from '@brightcove/studio-components';
import './InputSearch.scss';

interface InputSearchProps {
  placeholder: string;
  onSearch: (val: string) => void;
}

const InputSearch: FC<InputSearchProps> = ({ placeholder, onSearch }) => {
  const [currentValue, setCurrentValue] = useState('');
  const minCharCount = 3;

  const onClickSearch = () => {
    if (onSearch) {
      if (currentValue.length >= minCharCount) {
        onSearch(currentValue);
      } else {
        onSearch('');
      }
    }
  };
  const onKeyDown = useCallback(
    ({ key, target }: KeyboardEvent<HTMLInputElement>) => {
      const input = target as HTMLInputElement;
      const { value } = input;
    if (key === 'Enter' && value.length >= minCharCount) {
        onSearch(value);
    } else if (value.length <= 2) {
        // using 1 since at this point the rerender is not finished
        onSearch('');
    } else if (value.length >= minCharCount && key !== 'Enter') {
      onSearch(value);
    }
    },
    [currentValue]
  );

  const handleInputChange = (e) => {
    const input = e.target as HTMLInputElement;
    const value = input.value;
    const selectionStart = input.selectionStart;
    const selectionEnd = input.selectionEnd;

    setCurrentValue(e.target.value);

    if (selectionStart === selectionEnd && value.length < 1) {
      onSearch('');
    }
  };

  const handleCut = (e) => {
    const value = e.target;

    setCurrentValue(value);

    if (!value.length) {
      onSearch('');
    }
  };

  return (
    <div className="Input-search">
      <input
        type="text"
        placeholder={placeholder}
        onChange={handleInputChange}
        className="search-input"
        defaultValue={currentValue}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyDown}
        onCut={handleCut}
        autoFocus={true}
      />
      <span onClick={onClickSearch}>
        <Icon name="SEARCH" className="Input-search-icon" />
      </span>
    </div>
  );
};

export default InputSearch;
