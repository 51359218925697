import React, { FC } from 'react';
import { Flex } from '@brightcove/studio-components';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import './ReactPlayer.scss';

interface ReactPlayerProps {
  accountId: string | undefined;
  playerId?: string;
  videoId?: string | undefined;
  playlistId?: string;
  onSuccess?: (val: any) => void;
  small?: boolean;
}

const ReactPlayer: FC<ReactPlayerProps> = ({
  accountId,
  playerId,
  videoId,
  playlistId,
  onSuccess,
  small,
}) => {
  return (
    <Flex justifyContent="center" className={`ReactPlayer-container ${small ? 'small' : 'standard'}`}>
      <ReactPlayerLoader
        attrs={{
          className: 'ReactPlayer',
        }}
        accountId={accountId}
        playerId={playerId || 'default'}
        playlistId={playlistId}
        videoId={!playlistId ? videoId : ''}
        options={{
          autoplay: false,
        }}
        onSuccess={onSuccess}
        onFailure={(e) => {
          // eslint-disable-next-line no-console
          console.log('Player Error: ', e);
        }}
      />
    </Flex>
  );
};

export default ReactPlayer;
