/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Flex, Icon } from '@brightcove/studio-components';

import { ModuleName, Toaster, getModulePermissions, getUrlWithOptions, updateItems } from '../../utils';
import { ScheduledTextPanelData, StatusData } from '../../interfaces/ScheduledTextPanel';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import GenericModal from '../../components/Modals/GenericModal';
import DeleteConfirmationModal from '../../components/Modals/DeleteConfirmationModal';
import Button from '../../components/Button/Button';
import { SVGImage } from "../../assets/images";

import MaintenanceModeView from './MaintenanceModeView';
import './MaintenanceMode.scss';
import MaintenanceModeCreate from './MaintenanceModeCreate';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';

const resource = '/ops/maintenance_modes';

const MaintenanceMode = () => {
  const { apiGet, apiDelete, apiPut, apiPost, isLoading, currentMethod } = useApi();
  const [editingId, setEditingId] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showShowCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [currentDeletion, setCurrentDeletion] = useState<string>('');
  const [messages, setMessages] = useState<ScheduledTextPanelData[]>();
  const permissions = useSelector((state:any) => state.permissions);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.MAINTENANCE_MODE_SCREEN, permissions);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data } = await apiGet(getUrlWithOptions(resource));
    setMessages(data?.results?.items);
  };

  const onCreateMaintenanceModes = async (message: ScheduledTextPanelData, id?: string) => {
    
    if (id?.length && message.message.length) {
      const  response  = await apiPut(getUrlWithOptions(`${resource}/${id}`), {
        body: message,
      });

      if (response?.data?.id.length) {
        setEditingId('');
        setMessages(updateItems(messages, [response?.data], 'update'));
      }
      if (response?.error) {
        Toaster.error({
          message: "Maintenance mode has already been created for the selected platforms.",
        });
      }
    } else if (message.message.length) {
      const  response  = await apiPost(getUrlWithOptions(`${resource}`), {
        body: message,
      });

      if (response?.data?.id.length) {
        setEditingId('');
        setShowCreateModal(false);
        setMessages(updateItems(messages, [response?.data], 'add'));
      }
      if (response?.error) {
        Toaster.error({
          message: "Maintenance mode has already been created for the selected platforms.",
        });
      }
    } else {
      Toaster.warn({
        message: "Missing information.",
      });
    }
  };

  const onDeleteMaintenanceMode = async () => {
    const response = await apiDelete(getUrlWithOptions(`${resource}/${currentDeletion}`));

    if (response.status === 204) {
      setMessages(updateItems(messages, [{ id: currentDeletion }], 'delete'));
      setEditingId('');
    }

    setShowDeleteModal(false);
  };

  return (
    <>
    {isLoading && <Loading />}
    <div className="MaintenanceMode">
      <SubHeader
        icon={<img
          className="status-icon"
          alt="check mark"
          src={SVGImage.warningIcon}
        />}
        title={'Maintenance Mode'}
        subtitle={''}
        quantitySubtitle={`${messages?.length ? messages.length : 0} Maintenance Mode `}
        className="pl-4"
      />

      <div className="ml-11 mr-11">
        <DeleteConfirmationModal
          title="Delete"
          show={showDeleteModal}
          onDelete={onDeleteMaintenanceMode}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onClose={()=>{
            setShowDeleteModal(false);
          }}
        >
          <>
          Permanently delete 1 maintenance mode from the system? This cannot be undone.
          </>
        </DeleteConfirmationModal>
        
        <div className="mt-5">
         <Button
            variant="primary"
            text="Create Maintenance Mode"
            onClick={() => {
              setShowCreateModal(true);
            }}
            disabled={isLoading || editingId?.length > 0 || !hasWriteAccess || showShowCreateModal}
          />
        </div>
        {/* {isLoading && currentMethod === 'GET' ? (
          <Flex justifyContent="center" alignContent="center" className="mt-8">
            <Icon name="spinner" spin className="spinner-large" />
          </Flex>
        ) : ( */}
          <div className="mt-4">
            {showShowCreateModal && <MaintenanceModeCreate
            setEditingId={setEditingId}
            id={''}
            setShowCreateModal={setShowCreateModal}
            onCreate={onCreateMaintenanceModes}
            status={'Inactive'}
            scope={'web'}
            start_time={''}
            end_time={''}
            message={''}
          /> }
          { messages?.map((message: any) =>
              editingId === message.id ? (
                <MaintenanceModeCreate
                  key={message.id}
                  setEditingId={setEditingId}
                  showDeleteModal={(value: boolean, id: string) => {
                    setCurrentDeletion(id);
                    setShowDeleteModal(value);
                  }}
                  onCreate={onCreateMaintenanceModes}
                  id={message.id}
                  status={message.maintenance_status as StatusData}
                  start_time={message.start_time}
                  end_time={message.end_time}
                  message={message.message}
                  scope={message.maintenance_scope}
                />
              ) : (
                <MaintenanceModeView
                  key={message.id}
                  setEditingId={setEditingId}
                  id={message.id}
                  scope={message.maintenance_scope}
                  status={message.maintenance_status}
                  message={message.message}
                  start_time={message.start_time}
                  end_time={message.end_time}
                  showDeleteModal={(value: boolean, id: string) => {
                    setCurrentDeletion(id);
                    setShowDeleteModal(value);
                  }}
                  disabled={!!editingId?.length || !hasWriteAccess || showShowCreateModal}
                />
              )
            )}
          </div>
        {/* )} */}
      </div>
    </div>
    </>
  );
};

export default MaintenanceMode;