/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _, { flowRight as compose } from "lodash";
import { Flex, Icon } from "@brightcove/studio-components";

import * as ROUTES from "../routes";
import { ModuleName, getModulePermissions, getUrlWithOptions } from "../../utils";
// import COLORS from '../../styles/_colors.export.scss';
import useGoBack from "../../hooks/useGoBack";
import { useApi } from "../../hooks/useApi";
import AddBodyClasses from "../../helpers/AddBodyClasses";
import { withRouter } from "../../components/withRouter/withRouter";
import TableOfContents from "../../components/TableOfContents/TableOfContents";
import SubHeader from "../../components/SubHeader/SubHeader";
import EditPanel from "../../components/Panel/EditPanel";
import { DeleteConfirmationModal, withModal } from "../../components/Modals";
import Loading from "../../components/Loading/Loading";
// import Counter from '../../components/Counter/Counter';
import TextInput from "../../components/ControlledComponents/TextInput";
import Button from "../../components/Button/Button";
import "./ChannelDetail.scss";
import { TextArea } from "../../components/ControlledComponents";
import { useSelector } from "react-redux";

const DeleteButton = withModal(Button, DeleteConfirmationModal);

const ChannelDetail = ({ params: { id: channelId } }) => {
  const { goBackLabel, goBack } = useGoBack(
    ROUTES.CHANNELS,
    "Back to Channels"
  );

  const { apiGet, apiDelete, apiPut } = useApi();
  const [channel, setChannel] = useState<any>({});
  const [channelName, setChannelName] = useState<string>("");
  const [channelURL, setChannelURL] = useState<string>("");
  const [channelAsset, setChannelAsset] = useState<string>("");
  const [epgURL, setEpgURL] = useState<string>("");
  const [nielsonName, setnielsonName] = useState<string>("");
  const [adConfigId, setAdConfigId] = useState<string>("");
  const [disableEditing, setDisableEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refetch] = useState(false);
  const CHANNEL_ENDPOINT = "/ops/channel";
  const channelUrl = getUrlWithOptions(`${CHANNEL_ENDPOINT}/${channelId}`);
  const permissions = useSelector((state:any) => state.permissions);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.CHANNELS_SCREEN, permissions);

  useEffect(() => {
    (async () => {
      const [channelResponse] = await Promise.all([fetchChannel()]);
      if (channelResponse?.data) {
        const { name, image, epg_url, asset_id, nielson_name, ad_config_id } = channelResponse.data;
        setChannel(channelResponse.data);
        setChannelName(name);
        setChannelURL(image);
        setEpgURL(epg_url);
        setChannelAsset(asset_id);
        setnielsonName(nielson_name);
        setAdConfigId(ad_config_id);
      }
      setLoading(false);
    })();
  }, [refetch, loading]);

  const fetchChannel = async () => {
    return apiGet(channelUrl);
  };

  const onUpdateChannelInformation = async (): Promise<any> => {
    let bodyString = {
      name: channelName,
      image: channelURL,
      epg_url: epgURL,
      asset_id: channelAsset,
      nielson_name: nielsonName,
      ad_config_id: adConfigId,
    };
    const { data, error } = await apiPut(`${channelUrl}`, {
      body: bodyString,
    });
    
    if (data) {
      setChannel(data);
      setChannelName(data?.name);
      setChannelURL(data?.image);
      setEpgURL(data?.epg_url);
      setChannelAsset(data?.asset_id);
      setnielsonName(data?.nielson_name);
      setAdConfigId(data?.ad_config_id);
    }

    if (error) {
      return { error };
    }

    toggleDisableEditing();

    return {};
  };

  const onDeleteCollection = async () => {
    const { error } = await apiDelete(
      getUrlWithOptions(`${CHANNEL_ENDPOINT}/${channelId}`)
    );

    if (!error) {
      goBack();
    }
  };

  const toggleDisableEditing = () => setDisableEditing(!disableEditing);

  return loading ? (
    <Loading />
  ) : (
    // !_.isEmpty(channel) && (
    <>
      <AddBodyClasses classes={["disabled-overflow"]} />
      <div className="Channel-detail">
        <SubHeader
          title={channel.name}
          subtitle={goBackLabel}
          className="pl-5 pr-4"
          actions={
            <>
              <DeleteButton
                variant="secondary"
                disabled={!hasWriteAccess}
                modalProps={{
                  title: "Delete",
                  children: (
                    <>
                      Permanently delete 1 channel from the system? This cannot be undone.
                    </>
                  ),
                }}
                onModalConfirm={onDeleteCollection}
              >
                Delete
              </DeleteButton>
            </>
          }
          detailMode={true}
          onBack={goBack}
          icon={<Icon name="ARROW_RIGHT" rotate="180" />}
        />

        <Flex>
          <div style={{ flex: 1 }} className="main-content">
            <EditPanel
              id="information"
              title="Channel Information"
              disableEditing={disableEditing || !hasWriteAccess}
              disableSave={channelName === ""}
              onEnableEditing={toggleDisableEditing}
              onCancel={() => {
                setChannelName(channel.name);
                setChannelURL(channel.image);
                setEpgURL(channel.epg_url);
                setChannelAsset(channel.asset_id);
                setAdConfigId(channel.ad_config_id);
                toggleDisableEditing();
              }}
              save={onUpdateChannelInformation}
            >
              {(isEditing) => (
                <Flex>
                  <div className="pl-6">
                  <div className="my-4">
                      <div className="label mb-1">ID</div>
                      <div className="content">{channel.id}</div>
                    </div>
                    <div className="mt-8">
                      <div
                        className={`label mb-1${isEditing ? " required" : ""}`}
                      >
                        Channel Name
                      </div>
                      {isEditing ? (
                        <TextInput
                          value={channelName}
                          onChange={(updatedName) =>
                            setChannelName(updatedName)
                          }
                        />
                      ) : (
                        <div className="content">{channelName}</div>
                      )}
                    </div>
                   
                    <div className="mt-8">
                      <div
                        className={`label mb-1${isEditing ? " required" : ""}`}
                      >
                        Channel Asset
                      </div>
                      {isEditing ? (
                        <TextInput
                          value={channelAsset}
                          onChange={(updatedAsset) =>
                            setChannelAsset(updatedAsset)
                          }
                        />
                      ) : (
                        <div className="content">{channelAsset}</div>
                      )}
                    </div>
                    <div className="mt-8">
                      <div
                        className={`label mb-1${isEditing ? " required" : ""}`}
                      >
                        Nielsen Name
                      </div>
                      {isEditing ? (
                        <TextInput
                          value={nielsonName}
                          onChange={(updatednielsonName) =>
                            setnielsonName(updatednielsonName)
                          }
                        />
                      ) : (
                        <div className="content">{nielsonName}</div>
                      )}
                    </div>
                  </div>
                  <Flex
                    flexDirection="column"
                   // justifyContent="space-between"
                    className="py-5 pl-6"
                  >
                    <div>
                      <div
                        className={`label mb-1${isEditing ? " required" : ""}`}
                      >
                        Image URL
                      </div>
                      {isEditing ? (
                        <TextArea
                          className="textArea"
                          value={channelURL}
                          onChange={(updatedURL) => setChannelURL(updatedURL)}
                        />
                      ) : (
                        <div className="textArea">{channel.image}</div>
                      )}
                    </div>
                    <div className="mt-3">
                      <div
                        className={`label mb-1${isEditing ? " required" : ""}`}
                      >
                        Epg URL
                      </div>
                      {isEditing ? (
                        <TextInput
                          value={epgURL}
                          onChange={(updatedEpgURL) =>
                            setEpgURL(updatedEpgURL)
                          }
                        />
                      ) : (
                        <div className="content">{epgURL}</div>
                      )}
                    </div>
                    <div className="mt-8">
                      <div
                        className={`label mb-1${isEditing ? " required" : ""}`}
                      >
                        Ad Config ID
                      </div>
                      {isEditing ? (
                        <TextInput
                          value={adConfigId}
                          onChange={(updateAdConfigId) =>
                            setAdConfigId(updateAdConfigId)
                          }
                        />
                      ) : (
                        <div className="content">{adConfigId}</div>
                      )}
                    </div>
                  </Flex>
                </Flex>
              )}
            </EditPanel>
          </div>
          <TableOfContents
            className="pt-12 mr-11"
            data={[
              {
                id: "information",
                title: "Channel Information",
                offset: -250,
              },
            ]}
            isSticky
            hasBorder
          />
        </Flex>
      </div>
    </>
  );
  // );
};

export default compose(withRouter)(ChannelDetail);
