import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { Flex } from '@brightcove/studio-components';
import Button from '../Button/Button';
import { usePrevious } from '../../hooks/usePrevious';
import './PublicationStateButtons.scss';
interface Publication {
  publishing?: boolean;
  unpublishedChanges?: boolean;
  canRevert?: boolean;
  scheduledPublishAt?: string | undefined | null;
}

interface PublicationStateButtonsProps {
  id?: string;
  disabled?: boolean;
  publication: Publication | undefined | null;
  isPublishing?: boolean;
  showUnpublishedChangesWarning?: boolean;
  allowPreview?: boolean;
  allowRevert?: boolean;
  onPublish?: (val: any) => void;
  onExport?: () => void;
  onUnschedule?: () => void;
}


const PublicationStateButtons: FC<PublicationStateButtonsProps> = ({
  disabled,
  publication = {},
  isPublishing,
  showUnpublishedChangesWarning = true,
  onPublish,
  onExport,
  onUnschedule,
  allowPreview,
  allowRevert,
  id = null,
}) => {
  const [publicationInfo, setPublicationInfo] = useState<any>(publication || {});
  const prevPublicationInfo = usePrevious(publicationInfo);

  useEffect(() => {
    if (prevPublicationInfo && !_.isEqual(prevPublicationInfo, publication)) {
      setPublicationInfo(publication);
    }
  }, [publication, prevPublicationInfo]);

  return (
    <div className="Publication-state-buttons">
      <Flex alignItems="center">
        <Button
          // className="Publication-state-buttons-export-button"
          disabled={disabled}
          onClick={() => {
            onExport?.();
          }}
          variant={"secondary-invert"}
          text={"Export as JSON"}
        />
      </Flex>
    </div>
  );
};

export default PublicationStateButtons;
