/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { Flex } from "@brightcove/studio-components";

import { ContentData, CustomData } from "../../../interfaces/Content";
import Panel from "../../../components/Panel/Panel";
import { AutosaveTagList } from "@brightcove/studio-components";
import Button from "../../../components/Button/Button";
import { useApi } from "../../../hooks/useApi";
import "./Content.scss";
import TextInput from "../../../components/ControlledComponents/TextInput";
import { getUrlWithOptions } from "../../../utils";

interface ContentEpisodesProps {
  idPanel: string;
  data: ContentData;
  isSeries?: boolean;
  name?: string;
  display_name?: string;
  tags?: string[];
  custom_fields?: CustomData;
  reference_id?: string;
  description?: string;
  disabledEditing?: boolean;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
}

const ContentEpisode: FC<ContentEpisodesProps> = ({
  idPanel,
  data,
  disabledEditing,
  onEditModeChange,
}) => {
  const {
    id,
    reference_id,
    name,
    display_name,
    tags,
    custom_fields,
    description,
    long_description,
    genre,
    cast,
    director,
    year,
    studio
  } = data;
  const [editing, setEditing] = useState<boolean>(false);
  const { apiPatch, isLoading } = useApi();
  const [contentName, setName] = useState<string>(name || "");
  const [displayName, setDisplayName] = useState<string>(display_name || "");
  const [, setReferenceId] = useState<string>(
    reference_id || ""
  );
  const [contentTags, setTags] = useState<string[]>(tags || []);
  const [contentDescription, setDescription] = useState<string>(
    description || ""
  );
  const [contentLongDescription, setLongDescription] = useState<string>(
    long_description || ""
  );
  const [contentGenre, setGenre] = useState<string>(genre || "");
  const [contentCast, setCast] = useState<string>(custom_fields?.cast || "");
  const [contentDirector, setDirector] = useState<string>(custom_fields?.director || "");
  const [contentYear, setYear] = useState<string>(custom_fields?.year || "");
  const [contentStudio, setStudio] = useState<string>(custom_fields?.studio || "");




  const [inputState, setInputState] = useState<any>({});

  const restoreData = () => {
    const {
      reference_id,
      name,
      display_name,
      tags,
      custom_fields,
      description,
      long_description,
      genre
    } = data;

    setName(name || "");
    setDisplayName(display_name || "");
    setReferenceId(reference_id || "");
    setTags(tags || []);
    setDescription(description || "");
    setLongDescription(long_description || "");
    setGenre(genre || "");
    setCast(custom_fields?.cast || "");
    setDirector(custom_fields?.director || "");
    setStudio(custom_fields?.studio || "");
    setYear(custom_fields?.year || "");
  };

  useEffect(() => {
    if (data) {
      setName(name || "");
      setReferenceId(reference_id || "");
      setTags(tags?.length ? tags : []);
      setDescription(description || "");
      setLongDescription(long_description || "");
      setGenre(genre || "");
      setCast(custom_fields?.cast || "");
      setDirector(custom_fields?.director || "");
      setStudio(custom_fields?.studio || "");
      setYear(custom_fields?.year || "");
    }
  }, [data]);

  const onSaveInformation = async () => {
    const body = {
      name: contentName,
      display_name: displayName,
      // reference_id: contentReferenceId,
      description: contentDescription,
      long_description: contentLongDescription,
      tags: contentTags,
      genre: contentGenre,
      custom_fields: {
        cast: contentCast,
        director: contentDirector,
        studio: contentStudio,
        year: contentYear
      }
    };

    const response = await apiPatch(getUrlWithOptions(`/ops/content/${id}`), {
      body,
    });

    if (response?.data) {
      setEditing(false);
      onEditModeChange?.(!disabledEditing, true);
    }
  };
  return (
    <div id={idPanel} className="Content">
      <Panel
        title="Video Info"
        actions={
          <>
            {editing ? (
              <>
                <Button
                  variant="secondary-invert"
                  text="Cancel"
                  onClick={() => {
                    setEditing(false);
                    restoreData();
                    onEditModeChange?.(!disabledEditing);
                  }}
                />
                <Button
                  variant="primary"
                  text="Save"
                  onClick={() => {
                    onSaveInformation();
                  }}
                  disabled={isLoading}
                />
              </>
            ) : (
              <Button
                variant="secondary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                  onEditModeChange?.(!disabledEditing)
                }}
                disabled={disabledEditing}
              />
            )}
          </>
        }
      >
        <>
          {" "}
          {editing ? (
            <>
              <Flex>
                <div className="Content panel">
                  <div className="ml-4">
                    <p className="title">Performace Title</p>
                    <TextInput
                      value={displayName || contentName || ""}
                      onChange={(value) => {
                        setDisplayName(value);
                      }}
                    />
                  </div>

                  <div className="ml-4 mt-6">
                    <p className="title">Internal Tracking Name</p>
                    <TextInput
                      value={contentName || ""}
                      onChange={(value) => {
                        setName(value);
                      }}
                    />
                  </div>

                  <div className="ml-4 mt-6">
                    <p className="title">Short description</p>
                    <TextInput
                      value={contentDescription || ""}
                      onChange={(value) => {
                        setDescription(value);
                      }}
                    />
                  </div>
                  <div className="ml-4 mt-6">
                    <p className="title">Long Description</p>
                    <TextInput
                      value={contentLongDescription || ""}
                      onChange={(value) => {
                        setLongDescription(value);
                      }}
                    />
                  </div>
                  <div className="ml-4">
                    <p className="title">Cast</p>
                    <TextInput
                      value={contentCast || ""}
                      onChange={(value) => {
                        setCast(value);
                      }}
                    />
                  </div>
                  <div className="ml-4">
                    <p className="title">Director</p>
                    <TextInput
                      value={contentDirector || ""}
                      onChange={(value) => {
                        setDirector(value);
                      }}
                    />
                  </div>
                </div>
                <div className="Content secondPanel">
                  <div className="ml-4 pt-2">
                    <p className="title">Reference ID</p>
                    {reference_id?.length ? (
                      <p className="description">
                        {reference_id}
                      </p>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="ml-4">
                  <p className="title">Tags</p>
                    <AutosaveTagList
                      className="AutoCompleteTags"
                      fieldId="locationControlsField"
                      label=""
                      value={contentTags}
                      // editing={inputState.editing || false}
                      saving={inputState.saving || false}
                      saved={inputState.saved || false}
                      onChange={(value) => {
                        //console.log("valueeee on change"+JSON.stringify(value))
                        // setTagsState({ saved: true });
                        setTags(value);
                      }}
                      onStartEditing={() => {
                        setInputState({ editing: true });
                        // setTags(value);
                      }}
                      // onCachedValueChanged={(value) => setError(validate(value))}

                      onSave={(value) => {
                        setInputState({ saving: true });
                        // In most implementations, the onSave function would here dispatch a Save action with
                        // onSuccess & onError callbacks. The onSuccess callback would update the following states:
                        // setTimeout(() => {
                        setInputState({ saved: true });
                        setTags(value);
                        // }, 1500);
                      }}
                    />
                  </div>
                  <div className="ml-4">
                    <p className="title">Genres</p>
                    {/* <p className="description">{custom_fields.genre}</p> */}
                    <TextInput
                      value={contentGenre || ""}
                      onChange={(value) => {
                        setGenre(value);
                      }}
                    />
                  </div>
                  <div className="ml-4 pt-3">
                    <p className="title">Studio</p>
                    <TextInput
                      value={contentStudio || ""}
                      onChange={(value) => {
                        setStudio(value);
                      }}
                    />
                  </div>
                  <div className="ml-4 pt-1">
                    <p className="title">Year</p>
                    <TextInput
                      value={contentYear|| ""}
                      onChange={(value) => {
                        setYear(value);
                      }}
                    />
                  </div>
                </div>
              </Flex>
            </>
          ) : (
            <Flex>
              <div className="Content panel">
                <div className="ml-4">
                  <p className="title">Performace Title</p>
                  <p className="description">{display_name || name}</p>
                </div>

                <div className="ml-4 mt-6">
                  <p className="title">Internal Tracking Name</p>
                  <p className="description">{name}</p>
                </div>

                <div className="ml-4 mt-6">
                  <p className="title">Short description</p>
                  <p className="description">
                    {description ? description : "-"}
                  </p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Long Description</p>
                  {/* {series?.name?.length ? (
                  <p className="link">
                    <Link to={`${ROUTES.CONTENT_MANAGEMENT}/edit/series/${series.id}`}>{series.name}</Link>
                  </p>
                ) : (
                  '-'
                )} */}
                  <p className="description">
                    {long_description ? long_description : "-"}
                  </p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Cast</p>
                  <p className="description">
                    {contentCast ? contentCast : "-"}
                  </p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Director</p>
                  <p className="description">
                    {contentDirector ? contentDirector : "-"}
                  </p>
                </div>
              </div>
              <div className="Content secondPanel">
                <div className="ml-4">
                  <p className="title">Reference ID</p>
                  {reference_id?.length ? (
                    <p className="description">
                      {reference_id}
                    </p>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="ml-4">
                <p className="title">Tags</p>
                  {tags?.length? <AutosaveTagList
                    className="AutoCompleteTags"
                    fieldId="locationControlsField"
                    label=""
                    value={tags}
                    readOnly
                    editing={false}
                    onSave={(value) => {}}
                  />: "-"}
                </div>
                <div className="ml-4">
                  <p className="title">Genres</p>
                  <p className="description">{genre ? genre : "-"}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Studio</p>
                  <p className="description">
                    {contentStudio ? contentStudio : "-"}
                  </p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Year</p>
                  <p className="description">
                    {contentYear ? contentYear : "-"}
                  </p>
                </div>
              </div>              
            </Flex>
          )}
        </>
      </Panel>
    </div>
  );
};

export default ContentEpisode;
