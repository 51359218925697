/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Flex } from '@brightcove/studio-components';

import EditPanel from '../../../components/Panel/EditPanel';
import TextInput from '../../../components/ControlledComponents/TextInput';

const PageInformation = ({ idPanel, data, disableEditing, onEnableEditing, onCancel, onSave }) => {
  const [pageInformation, setPageInformation] = useState(data);
  const pageType = pageInformation?.type?.toLowerCase();

  useEffect(() => {
    setPageInformation(data);
  }, [data]);

  const cancelChanges = () => {
    setPageInformation(data);
    onCancel();
  };

  const onChange = (value, property) => {
    setPageInformation({ ...pageInformation, [property]: value });
  };

  const getFieldsToSave = () => {
    const fieldsToSave = ['internal_name', 'slug'];
    if (pageInformation.type === "OriginalPage") {
      fieldsToSave.push('asset_id')
      fieldsToSave.push('adConfigId')
    }

    // if (pageType === 'custompage' || pageType === 'standardpage') {
    // fieldsToSave.push('slug');
    // }

    return fieldsToSave;
  };

  return (
    <EditPanel
      id={idPanel}
      title="Page Information"
      disableEditing={disableEditing}
      // onEnableEditing={onEnableEditing}
      onCancel={cancelChanges}
      save={() => onSave(_.pick(pageInformation, getFieldsToSave()))}
    >
      {(isEditing) => (
        <Flex className="Page-detail-information px-4 pt-4 pb-6" flexDirection="column">
          <Flex alignItems="center" justifyContent="space-between">
            <div className="panel-group mr-7">
              <p className="panel-label">Internal Page Name</p>
              {isEditing ? (
                <TextInput
                  className="panel-text-input"
                  property="internal_name"
                  value={pageInformation?.internal_name || ''}
                  onChange={onChange}
                />
              ) : (
                <p className="panel-text">{pageInformation?.internal_name}</p>
              )}
            </div>
            <div className="panel-group">
              <p className="panel-label">ID</p>
              <p className="panel-text">{pageInformation?.id}</p>
            </div>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            {pageInformation.type === "OriginalPage" &&
              (<div className="panel-group mr-7">
                <p className='panel-label'>Asset ID</p>
                {isEditing ? (
                  <TextInput
                    className="panel-text-input"
                    property="asset_id"
                    value={pageInformation?.asset_id || ''}
                    onChange={onChange} />
                ) : (<p className="panel-text">{pageInformation?.asset_id || '-'}</p>)
                }
              </div>)
            }
            {pageInformation.type === "OriginalPage" &&
              (<div className="panel-group">
                <p className='panel-label'>Ad Config Id</p>
                {isEditing ? (
                  <TextInput
                    className="panel-text-input"
                    property="adConfigId"
                    value={pageInformation?.adConfigId || ''}
                    onChange={onChange} />
                ) : (<p className="panel-text">{pageInformation?.adConfigId || '-'}</p>)
                }
              </div>)
            }
          </Flex>
          <div className="panel-group">
            <p className="panel-label">Description</p>
            {isEditing ? (
              <TextInput
                className="panel-text-input"
                property="slug"
                value={pageInformation?.slug || ''}
                onChange={onChange}
              />
            ) : (
              <p className="panel-text">{pageInformation?.slug || '-'}</p>
            )}
          </div>
        </Flex>
      )}
    </EditPanel>
  );
};

export default PageInformation;
