/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Flex, Icon, Thumbnail } from "@brightcove/studio-components";

import * as ROUTES from "../routes";
import {
  ModuleName,
  Toaster,
  formatDate,
  getModulePermissions,
  getPageCount,
} from "../../utils";
import { getUrlWithOptions } from "../../utils";
// import COLORS from '../../styles';

import { useApi } from "../../hooks/useApi";
import TitleDescription from "../../components/TitleDescription/TitleDescription";
import Table from "../../components/Table/Table";
import SubHeader from "../../components/SubHeader/SubHeader";
import SideNavigationItem from "../../components/SideNavigation/SideNavigationItem";
import SideNavigation from "../../components/SideNavigation/SideNavigation";
import Loading from "../../components/Loading/Loading";
import InputSearch from "../../components/ControlledComponents/InputSearch";
import Button from "../../components/Button/Button";
import "./ContentManagement.scss";
import { withModal } from "../../components/Modals";
import AddItemModal from "../../components/Modals/AddItemModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { contentFilter } from "../../store/slices/userSlice";
import { SVGImage } from "../../assets/images";


const AddCollectionItemButton = withModal(Button, AddItemModal);
const ALL_CONTENT = "allContent";
const contentFilters = [
  {
    image: SVGImage.Content,
    name: "All Content",
    id: '',
  },
  {
    iconName: "FILM",
    name: "Videos",
    id: "EpisodeContent",
  },
];

const collectionFilters = [
  {
    image: SVGImage.Content,
    name: "Collections",
    id: ALL_CONTENT,
  },
];

const sectionTitle = (id: string) => {
  const selected = contentFilters.find((item) => item.id === id)?.name;

  return selected?.length ? selected : "All Content";
};

const resource = "/ops/content";
const SORT_DEFAULT = "updated_at:dsc";
const FILTER_DEFAULT = "";
const VALID_SORT_FIELDS = {
  Name: "Name/Title",
  content_type: "content_type",
  status: "status",
  Created: "created_at",
  Updated: "updated_at",
  published: "published",
};

const VALID_CONTENT_SORT_FIELDS = {
  Name: "name",
  id: "id",
  Created: "created_at",
  Type: "collection_type",
  Updated: "updated_at",
  published: "published",
};

interface ContentManagementProps {
  embedded?: boolean;
  excludeFilters?: any[];
  singleSelection?: boolean;
  onRowSelect?: (param: any[]) => void;
}

const contentItemsColumns = [
  {
    Header: "Collection Title",
    sortable: true,
    accessor: "name",
    Cell: ({ value, row }) => (
      <div style={{width:"200px", textOverflow:"ellipsis", overflowWrap: "break-word"}}>
      <TitleDescription
        title={value}
        description={row.original.id}
        id={row.original.id}
        onClick={(id) => {}}
        type={"noClickable"}
      />
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "status", // accessor is the "key" in the data
    Cell: ({ value }) => {
      return (
        <Flex justifyContent="center">
          <Icon name={"check2"} color="#3886F7" />
        </Flex>
      );
    },
    sortable: true,
    className: "Content-management-published",
  },
  {
    Header: "Created",
    accessor: "created_at",
    sortable: true,
    Cell: ({ value, row }) => (
      <>
        <div>{formatDate(value)}</div>
        <div className="subtitle">{row.original.createdBy}</div>
      </>
    ),
    className: "date",
  },
  {
    Header: "Updated",
    accessor: "updated_at",
    Cell: ({ value, row }) => (
      <>
        <div>{formatDate(value)}</div>
        <div className="subtitle">{row.original.updatedBy}</div>
      </>
    ),
    sortable: true,
    className: "date",
  },
];

const ContentManagement: FC<ContentManagementProps> = ({
  embedded,
  excludeFilters,
  singleSelection,
  onRowSelect,
}) => {
  const [currentFilter, setCurrentFilter] = useState(FILTER_DEFAULT);
  const [currentSearch, setCurrentSearch] = useState<string>("");
  const [contentSearch, setContentSearch] = useState<string>("");
  const [items, setItems] = useState<any[]>([]);
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [currentSort, setCurrentSort] = useState<string>(SORT_DEFAULT);
  const [loading, setLoading] = useState(true);
  const { apiGet, apiPatch } = useApi();
  const navigate = useNavigate();
  const [, getSelectedRowCount] = useState(0);
  const [selectedRows, getSelecetdRows] = useState([]);
  const [selectedCollectionRows, getSelecetdCollectionRows] = useState([]);
  const [addToCollectionButtonEnable, setAddToCollectionButton] =
    useState(true);
  const [contentItemsData, setContentItemsData] = useState<any>({});
  const [contentTotalCount, setContentTotalCount] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [contentSort, setContentSort] = useState<string>(SORT_DEFAULT);
  // const [collectionSort, setCollectionSort] = useState<string>(sortOptions[0].value);
  // const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [contentPageSize, setContentPageSize] = useState(20);
  const [contentPageIndex, setContentPageIndex] = useState(0);
  const [collectionItems, setCollectionItems] = useState<any[]>([]);
  const [modalView, setModalView] = useState(false);
  const [startKey, setStartKey] = useState("");
  const [startKeyData, setStartKeyData] = useState("");
  const permissions = useSelector((state: any) => state.permissions);
  const contentFilterValue = useSelector((state: any) => state.contentFilter);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(
    ModuleName.CONTENT_MANAGEMENT_SCREEN,
    permissions
  );
  const dispatch = useDispatch();

  const getData = async () => {
    const { data } = await apiGet(
      getUrlWithOptions(
        resource,
        currentSearch,
        currentSort,
        currentFilter,
        currentPageIndex,
        currentPageSize,
        startKey
      )
    );
    
    // if (data?.results?.items?.length) {
      if (data?.results?.totalCount <= currentPageIndex * currentPageSize  && currentSearch) {
        setTotalCount(data?.results?.totalCount);
        setCurrentPageIndex(0)
      } else if (data?.results?.totalCount > (currentPageIndex * currentPageSize) &&currentSearch){
        setTotalCount(data?.results?.totalCount);
        let pageCount: number = Math.ceil(data?.results?.totalCount / currentPageSize);
        pageCount<= currentPageIndex?   setCurrentPageIndex(pageCount) : setCurrentPageIndex(currentPageIndex);
      }
      else {
        setTotalCount(data?.results?.totalCount);
      }
      // setString(dynamicStringsData?.results?.items);
    // }
    setItems(data?.results?.items?.length ? data?.results?.items : []);
    setTotalCount(data?.results?.totalCount || 0);
    if (data?.lastEvaluatedKey) {
      setStartKeyData(data?.lastEvaluatedKey);
    }
    setLoading(false);
  };

  const onContentPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setContentPageIndex(pageIndex);
    setContentPageSize(pageSize);
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await apiGet(
        getUrlWithOptions(
          "/ops/collections",
          contentSearch,
          contentSort,
          selectedFilter,
          contentPageIndex,
          contentPageSize
        )
      );
      setContentItemsData(data?.results);
      setContentTotalCount(data?.results?.totalCount);
      if (data?.results?.totalCount <= contentPageIndex * contentPageSize  && contentSearch) {
        setContentTotalCount(data?.results?.totalCount);
        setContentPageIndex(0)
      } else if (data?.results?.totalCount > (contentPageIndex * contentPageSize) && contentSearch){
        setContentTotalCount(data?.results?.totalCount);
        let pageCount: number = Math.ceil(data?.results?.totalCount / contentPageSize);
        pageCount<= contentPageIndex?   setContentPageIndex(pageCount) : setContentPageIndex(contentPageIndex);
      }
      else {
        setContentTotalCount(data?.results?.totalCount);
      }
    })();
  }, [
    contentSearch,
    contentSort,
    selectedFilter,
    contentPageIndex,
    contentPageSize,
    modalView,
  ]);

  const onAddCollectionItem = async () => {
    const newCollectionItems = collectionItems.concat(selectedCollectionRows);
    selectedRows.map(async (item: any) => {
      const { data } = await apiGet( 
        getUrlWithOptions(`/ops/content/${item.id}`) 
      ); 
      let bodyString :any = [];
      data?.contained_in_collections?.map((item: any) => {
        bodyString.push({
          name: item.display_name || item.name,
          item_id: item.id,
        });
      });
        selectedCollectionRows.map((item: any) => {
          bodyString.push({
            name: item.display_name || item.name,
            item_id: item.id,
          });
        });
        const body = {
          contained_in_collections: bodyString,
        };
  
        const response = await apiPatch(
          getUrlWithOptions(`${resource}/${item.id}`),
          {
            body,
          }
        );
  
        if (response?.data) {
          setStartKey("");
          getData();
          
        }
        setCollectionItems(newCollectionItems);
      
    })
    Toaster.success({
      message: "Content Items have been added to Collection! Any Content Items already added to the collection were omitted.",
    });
    
  };

  const toExternal = (url: string, newWindow?: boolean) => () => {
    if (newWindow) window.open(url, "_blank");
    else window.location.href = url;
  };

  const getAllContentFilter = () => {
    let allContentFilter = "";

    if (excludeFilters) {
      allContentFilter = contentFilters
        .filter(
          (filter) =>
            !excludeFilters.includes(filter.id) && filter.id !== ALL_CONTENT
        )
        .map((contentFilter) => contentFilter.id)
        .join(",");
    }

    return allContentFilter;
  };

  useEffect(() => {
    const contentFilters = getAllContentFilter();
    setCurrentFilter(contentFilterValue);
    if (location.pathname.includes("/content-management")) {
      setCurrentFilter(contentFilterValue);
      // window.location.replace(ROUTES.HOME);
    } else {
      setCurrentFilter(contentFilters);
      dispatch(contentFilter(""));
    }
    // dispatch(contentFilter(contentFilters));
  }, []);

  useEffect(() => {
    getData();
  }, [
    currentPageIndex,
    currentPageSize,
    currentSearch,
    currentFilter,
    currentSort,
    startKey,
  ]);

  const onChangeFilter = (id: string) => {
    const filter = id.search(ALL_CONTENT) >= 0 ? getAllContentFilter() : id;
    setCurrentPageIndex(0);
    setCurrentFilter(filter);
    dispatch(contentFilter(filter));
  };

  const onSearch = (val: string) => setCurrentSearch(val);
  const onContentSearch = (val: string) => setContentSearch(val);

  const onCancel = () => {
    setSelectedFilter("");
    setContentSearch("");
    setContentPageIndex(0);
    setModalView(false);
  };

  const onClickSort = (field, order) => {
    
    if(field === 'Name/Title') {
      setCurrentSort(
        `name:${order.toLocaleLowerCase()}`
      );
    } else if (field && order) {
        setCurrentSort(`${VALID_SORT_FIELDS[field]}:${order.toLocaleLowerCase()}`);
    }
  };

  const onClickContentSort = (field, order) => {
    if (field && order) {
      setContentSort(
        // `${VALID_CONTENT_SORT_FIELDS[field]}:${order.toLocaleLowerCase()}`
        (`${field}:${order}`)
      );
    }
  };

  const onPaginationChange = ({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
    setStartKey(pageIndex === 0 ? "" : startKeyData);
  };


  const columns = [
    {
      Header: "Content",
      accessor: "image",
      Cell: ({ value }) => (
        <Thumbnail className="Content-management-thumbnail" src={value} />
      ),
    },
    {
      Header: "Name/Title",
      accessor: (row) => [row.display_name || row.name, row.id], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [name, id] = value;

        return (
          <div style={{width:"300px", textOverflow:"ellipsis", overflowWrap: "break-word"}}>
          <TitleDescription
            title={name}
            description={id}
            id={id}
            onClick={(itemId) =>
              navigate(`../${ROUTES.CONTENT_MANAGEMENT}/edit/${itemId}`)
            }
          />
          </div>
        );
      },
      sortable: true,
    },
    {
      Header: "Type",
      accessor: "content_type", // accessor is the "key" in the data
      Cell: ({ value, row }) => (
        <>
          {/* <div>{formatDate(value)}</div> */}
          <div>{row.original.content_type}</div>
        </>
      ),
      sortable: true,
      className: "",
    },
    {
      Header: "Status",
      accessor: "status", // accessor is the "key" in the data
      Cell: ({ value }) => {
        return (
          <Flex justifyContent="center">
            <Icon name={"check2"} color="#3886F7" />
          </Flex>
        );
      },
      sortable: true,
      className: "",
    },
    {
      Header: "Created",
      sortable: true,
      accessor: (row) => [row.created_at], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [createdAt] = value;

        return (
          <TitleDescription
            classNameTitle="Content-management-column-updated-item-title"
            classNameDescription="Content-management-column-updated-item-description"
            title={formatDate(createdAt)}
            // description={email}
          />
        );
      },
    },
    {
      Header: "Updated",
      sortable: true,
      accessor: (row) => [row.updated_at], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [updated] = value;

        return (
          <TitleDescription
            classNameTitle="Content-management-column-updated-item-title"
            classNameDescription="Content-management-column-updated-item-description"
            title={formatDate(updated)}
            // description={email}
          />
        );
      },
    },
  ];

  
  // const embeddedModalColumns = columns.filter((column) => column.accessor !== 'extId');
  
  const onChangeSelection = useCallback((selectionRows) => {
    getSelectedRowCount(selectionRows.length);
    getSelecetdRows(selectionRows);
    selectionRows.length > 0
      ? setAddToCollectionButton(false)
      : setAddToCollectionButton(true);
  }, []);

  const onChangeSelectionCollection = useCallback((selectionRows) => {
    // getSelectedRowCount(selectionRows.length);
    getSelecetdCollectionRows(selectionRows);
    // selectionRows.length > 0 ? setAddToCollectionButton(false) : setAddToCollectionButton(true);
  }, []);

  const updateModalView = () => {
    setModalView(true);
  };
  return (
    <Flex>
      {(hasReadAccess || hasWriteAccess) && (
        <>
          <SideNavigation
            className={`Content-management-navigation`}
            onChange={onChangeFilter}
            item={SideNavigationItem}
            filters={
              !excludeFilters
                ? contentFilters
                : contentFilters.filter(
                    (filter) => !excludeFilters.includes(filter.id)
                  )
            }
            defaultValue={
              contentFilters.length >= 1 ? contentFilterValue : ALL_CONTENT
            }
          />
          <Flex flexDirection="column" className="Content-management-page">
            <SubHeader
              icon={currentFilter=== "EpisodeContent"? <Icon name="FILM" /> : <img src={SVGImage.Content} alt={"video"} height="26px" width="26px"/>}
              title={sectionTitle(currentFilter)}
              subtitle={""}
              quantitySubtitle={`${currentTotalCount} Items`}
              actions={
                <InputSearch
                  placeholder="Search"
                  onSearch={onSearch}
                />
              }
            />
            {loading ? (
              <Loading />
            ) : (
              <>
                <Flex
                  justifyContent="flex-start"
                  className="Content-management-button"
                >
                  {currentFilter === "EpisodeContent" && !embedded ? (
                    <>
                      <Button
                        variant="primary"
                        onClick={toExternal(
                          "https://studio.brightcove.com",
                          true
                        )}
                        className="mt-3 mr-3"
                        disabled={!hasWriteAccess}
                      >
                        Upload Videos
                      </Button>
                      <AddCollectionItemButton
                        className="mt-3 mr-5"
                        variant="primary"
                        disabled={
                          addToCollectionButtonEnable || !hasWriteAccess
                        }
                        modalProps={{
                          title: "Add to a Collection",
                          addButtonText: "Add to collection",
                          searchPlaceholder: "Search",
                          filters: collectionFilters,
                          data: contentItemsData?.items || [],
                          columns: contentItemsColumns,
                          pageCount: getPageCount(
                            contentTotalCount,
                            contentPageSize
                          ),
                          pageIndex: contentPageIndex,
                          pageSize: contentPageSize,
                          hasPagination: true,
                          onChangeFilter,
                          onClickSort: onClickContentSort,
                          onSearch: onContentSearch,
                          onChangeSelection: onChangeSelectionCollection,
                          onPaginationChange: onContentPaginationChange,
                          onAddItem: onAddCollectionItem,
                          onCancel,
                          totalCount: contentTotalCount
                          // show:modalView
                        }}
                        onClick={() => updateModalView}
                      >
                        Add to Collection
                      </AddCollectionItemButton>
                    </>
                  ) : null}
                </Flex>

                <Table
                  className={`${
                    (!currentFilter.length ||
                      currentFilter === "EpisodeContent") &&
                    !embedded
                      ? "mt-4"
                      : "mt-0"
                  }`}
                  data={items}
                  columns={columns}
                  hasPagination
                  pageCount={Math.ceil(currentTotalCount / currentPageSize)}
                  onPaginationChange={onPaginationChange}
                  // defaultSortingValues={[{ id: 'updated_at', desc: true }]}
                  pageIndex={currentPageIndex}
                  pageSize={currentPageSize}
                  onClickSort={onClickSort}
                  hasSelection={true}
                  onChangeSelection={onChangeSelection}
                  totalCount={currentTotalCount}
                />
              </>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default ContentManagement;
