import { createSlice } from '@reduxjs/toolkit'

export interface UserState {
  name : string;
  permissions: any;
  user_id : string;
  email: string,
  accessToken: string,
  contentFilter: string
}

const initialState : UserState = {
  
    "name": "",
    "permissions" : {},
    "user_id": "",
    "email": "",
    "accessToken" : "",
    "contentFilter": ""
  
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      // console.log('in login reducer with action', action);
      // below is an alternative way for this statement -> "state = action.payload" which is supported by Immer (used under the hood by reduxjs/toolkit) for mutating objects
      return action.payload;
    },
    logout: (state) => {
      // console.log('in logout reducer');
      state.name = "";
      state.permissions = {};
      state.user_id = "";
      state.email = "";
      state.accessToken = "";
      state.contentFilter = "";
      // auth.logout
    },
    contentFilter: (state, action) => {
      state.contentFilter =  action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { login, logout, contentFilter } = userSlice.actions

export default userSlice.reducer;
