import React, { FC, useState } from "react";
import { Flex, PlainRadioGroup, RadioOption } from "@brightcove/studio-components";

import TextInput from "../ControlledComponents/TextInput";
import SimpleSelect from "../ControlledComponents/SimpleSelect";
import Button from "../Button/Button";
import { pageSections } from "../../utils";

import GenericModal, { GenericModalProps } from "./GenericModal";
import AddContentItemModal from "./AddContentItemModal";
import AddCollectionModal from "./AddCollectionModal";
import "./AddSectionModal.scss";

const pageSectionTypes = pageSections.custom_sections.map((type) => {
  return {
    label: type,
    value: type,
  };
});

interface AddSectionDetailsProps extends GenericModalProps {
  confirmBtnLabel?: string;
  nextStep?: (param1: any, param2?: string) => void;
  onCancel?: () => void;
}

const AddSectionDetails: FC<AddSectionDetailsProps> = ({
  confirmBtnLabel,
  show,
  onClose,
  nextStep,
  onCancel,
}) => {
  const [internal_name, setInternalName] = useState("");
  const [type, setType] = useState(pageSectionTypes[0].value);
  const [showAd, setShowAds] = useState("false");
  const handleCloseModal = () => {
    setInternalName("");
    setType(pageSectionTypes[0].value);
    onCancel?.();
    onClose?.();
  };

  const onContinue = (modalType) => {
    const showAds = showAd === "true" ? true : false;
    nextStep?.({ internal_name, type, showAds }, modalType);

    if (type === "Custom") {
      handleCloseModal();
    }
  };

  return (
    <GenericModal
      className="Add-section-modal"
      show={show}
      title="Add a Page Block"
      onClose={handleCloseModal}
      buttons={
        <Flex alignItems="center" justifyContent="center">
          <Button
            variant="secondary mr-3"
            text="Cancel"
            onClick={handleCloseModal}
          />
          <Button
            variant="primary"
            disabled={!internal_name || !type}
            text={type === "Custom" ? confirmBtnLabel : "Continue"}
            onClick={() =>{
              onContinue(type !== "Custom" ? "collection" : undefined)
              
            }
            }
          />
        </Flex>
      }
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        className="Add-section-modal-content mt-6"
      >
        <Flex flexDirection="column" className="mr-8">
          <p className="label mb-2">Page Block Name *</p>
          <TextInput
            className="modal-text-input  mt-0"
            property="name"
            value={internal_name}
            onChange={(value) => setInternalName(value)}
          />
        </Flex>
        <Flex flexDirection="column" className="mr-8">
          <p className="label mb-2">Page Block Type *</p>
          <SimpleSelect
            className="modal-select-input select-input-lg mt-0"
            property="type"
            value={type}
            options={pageSectionTypes}
            onChange={(value) => setType(value)}
          />
        </Flex>
        <Flex flexDirection="column">
          <p className="label-ads mb-2">Show Ads Below *</p>
          <PlainRadioGroup
            name="names"
            value={showAd}
            onChange={setShowAds}
            className="Radio-group-date-picker"
          >
            <RadioOption label={"Yes"} value={"true"} />
            <RadioOption label={"No"} value={"false"} />
          </PlainRadioGroup>
        </Flex>
      </Flex>
    </GenericModal>
  );
};

const AddSectionModal = (props: any) => {
  const [step, setStep] = useState("details");
  const [sectionInfo, setSectionInfo] = useState<any>({});

  const nextStep = (stepData: any, modalType: any) => {
    const updatedSectionInfo = { ...sectionInfo, ...stepData };
    setSectionInfo(updatedSectionInfo);

    switch (updatedSectionInfo.type) {
      case "Carousel":
      case "Hero":
      case "Grid":
      case "Player":
      case "VideoDetail":
      case "Search":
      case "SearchBar":
        if (step === "collection" || step === "content-item") {
          props.onAddSection(updatedSectionInfo);
          resetModal();
        }
        
        setStep((prevStep) =>
          prevStep === "details" && modalType ? modalType : "details"
        );
        break;
    }
  };

  const resetModal = () => {
    setStep("details");
    setSectionInfo({});
  };

  const onAddContent = (items: any[]) => {
    const content = {
      type: step === "collection" ? "Collection" : "Item",
      id: items?.[0].id,
      collection_type: items?.[0]?.collection_type,      
      image: items?.[0]?.image
    };

    nextStep({ content }, undefined);
  };
  return (
    <>
      {step === "details" && (
        <AddSectionDetails {...props} nextStep={nextStep} />
      )}
      {step === "collection" && (
        
        <AddCollectionModal
          {...props}
          filter={""}
          onAddCollection={onAddContent}
          onCancel={resetModal}
        />
      )}
      {step === "content-item" && (
        <AddContentItemModal
          {...props}
          onAddItems={onAddContent}
          onCancel={resetModal}
        />
      )}
    </>
  );
};

export default AddSectionModal;
