import React, { FC, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import { ContentData } from '../../../interfaces/Content';
import Panel from '../../../components/Panel/Panel';
import Button from '../../../components/Button/Button';
import { useApi } from '../../../hooks/useApi';

interface ContentMoviesProps {
  idPanel: string;
  data: ContentData;
  isSeries?: boolean;
}

const ContentMovie: FC<ContentMoviesProps> = ({ idPanel, data }) => {
  const { id, reference_id, source, publicUrl } = data;
  const [editing, setEditing] = useState<boolean>(false);
  const { isLoading } = useApi();


  // const restoreData = () => {
  //   setCurrentAccessControls(
  //     defaultData?.accessControls?.length ? getArrayOfNames(defaultData.accessControls) : []
  //   );
  //   setCurrentLocations(defaultData?.locations?.length ? getArrayOfNames(defaultData.locations) : []);
  //   setCurrentStartDateString(defaultData?.startDate?.length ? defaultData.startDate : '');
  //   setCurrentEndDateString(defaultData?.endDate?.length ? defaultData.endDate : '');
  // };

  return (
    <div id={idPanel} className="Content">
      <Panel title="Content" actions={<>
        {editing ? (
              <>
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    setEditing(false);
                    // restoreData();
                  }}
                />
                <Button variant="primary" text="Save" onClick={()=>{}} disabled={isLoading} />
              </>
            ) : (
              <Button
                variant="primary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                }}
                disabled={false}
              />
            )}
      </>}>
        <>
          <Flex>
            <div>
              <div className="ml-4">
                <p className="title">ID</p>
                <p className="description">{id}</p>
              </div>
              <div className="ml-4 mt-6">
                <p className="title">Source</p>
                <p className="description">{source ? source : '-'}</p>
              </div>
              <div className="ml-4 mt-6">
                <p className="title ">Reference ID</p>
                {reference_id?.length ? (
                  <p className="link">
                    <a href={reference_id}>{reference_id}</a>
                  </p>
                ) : (
                  '-'
                )}
              </div>
              <div className="ml-4 mt-6">
                <p className="title">Public URL</p>
                {publicUrl?.length ? (
                  <p className="link">
                    <a href={publicUrl} target="_blank" rel="noreferrer">
                      {publicUrl}
                    </a>
                  </p>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </Flex>
        </>
      </Panel>
    </div>
  );
};

export default ContentMovie;
