import One31Logo from "./icons/logo_full.svg";
import Pencil from "./icons/pencil.svg";
import StatusActive from "./icons/statusCheck.svg";
import List from "./icons/list.svg";
import StatusInActive from "./icons/status-inactive.svg";
import warningIcon from "./icons/warning.svg";
import trashcan from "./icons/trashcan.svg";
import dragIcon from "./icons/dragIcon.svg";
import Cross from "./icons/Cross.svg"
import Content from "./icons/icn-videos.svg"
import Collection from "./icons/collection.svg"
import Ellipse from "./icons/Ellipse14.svg"

const SVGImage = {
  One31Logo,
  StatusActive,
  StatusInActive,
  Pencil,
  List,
  warningIcon,
  trashcan,
  dragIcon,
  Cross,
  Content,
  Collection,
  Ellipse
};

export { SVGImage };
