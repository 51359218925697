/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { flowRight as compose } from "lodash";
import { Flex, Icon } from "@brightcove/studio-components";

import * as ROUTES from "../routes";
import { Toaster, getUrlWithOptions } from "../../utils";
import { useApi } from "../../hooks/useApi";
import { withRouter } from "../../components/withRouter/withRouter";
import SubHeader from "../../components/SubHeader/SubHeader";
import Panel from "../../components/Panel/Panel";
import "./ChannelCreate.scss";
import TextInput from "../../components/ControlledComponents/TextInput";
import Button from "../../components/Button/Button";
import _ from "lodash";
import { useNavigate } from "react-router";

const ChannelCreate = () => {
  const { apiPost } = useApi();
  const navigate = useNavigate();
  const [channelName, setChannelName] = useState<string>("");
  const [channelURL, setChannelURL] = useState<string>("");
  const [channelAsset, setChannelAsset] = useState<string>("");
  const [epgURL, setEpgURL] = useState<string>("");
  const [nielsonName, setnielsonName] = useState<string>("");
  const [adConfigId, setAdConfigId] = useState<string>("");
  const [disableEditing, setDisableEditing] = useState(false);

  const onCreate = async () => {
      if (channelName  && channelAsset) {
        const  response  = await apiPost(getUrlWithOptions("/ops/channel"), {
          body: {
            name: channelName,
            image: channelURL,
            asset_id: channelAsset,
            epg_url: epgURL,
            nielson_name: nielsonName,
            ad_config_id: adConfigId
          },
        });
        if (response?.data?.id?.length) {
          navigate(`../${ROUTES.CHANNELS}/edit/${response?.data?.id}`);
        }
        if (response?.error) {
          Toaster.error({
            message: "Resource does not exist.",
          });
        }
      }
  }
  const goBack = () => navigate(`../${ROUTES.CHANNELS}`);

  return (
    <div className="Channel-create">
      <SubHeader
        title="New Channel"
        subtitle="Back to Channels"
        className="pl-5 pr-4"
        detailMode={true}
        onBack={goBack}
        icon={<Icon name="ARROW_RIGHT" rotate="180" />}
      />

      <div className="main-content">
        <Panel title="Channel Info" actions={<></>}>
          <div className="content ml-4 mb-12">
            <Flex>
              <div className="mr-5 channel-name-wrapper">
                <div className="label required">Channel Name *</div>
                <TextInput
                  className="mt-2"
                  value={channelName}
                  onChange={(updatedName) => setChannelName(updatedName)}
                />
              </div>
              <div className="mr-5 channel-name-wrapper">
                <div className="label required">Channel Asset *</div>
                <TextInput
                  className="mt-2"
                  value={channelAsset}
                  onChange={(updatedAsset) => setChannelAsset(updatedAsset)}
                />
              </div>
            </Flex>
            <Flex>
            <div className="mr-5 channel-name-wrapper">
              <div className="label required">URL</div>
              <TextInput
                className="mt-2"
                value={channelURL}
                onChange={(updatedURL) => setChannelURL(updatedURL)}
              />
            </div>
            <div className="mr-5 channel-name-wrapper">
                <div className="label required">EPG URL</div>
                <TextInput
                  className="mt-2"
                  value={epgURL}
                  onChange={(updateEpgURL) => setEpgURL(updateEpgURL)}
                />
              </div>
              </Flex>
              <Flex>
              <div className="mr-5 channel-name-wrapper">
                <div className="label required">Nielsen Name</div>
                <TextInput
                  className="mt-2"
                  value={nielsonName}
                  onChange={(updatednielsonName) => setnielsonName(updatednielsonName)}
                />
              </div>
              <div className="mr-5 channel-name-wrapper">
                <div className="label required">Ad Config Id</div>
                <TextInput
                  className="mt-2"
                  value={adConfigId}
                  onChange={(updateAdConfigId) => setAdConfigId(updateAdConfigId)}
                />
              </div>

              </Flex>
            <div>
              <Flex
                flexDirection="row"
                alignContent="flex-end"
                className="buttonGroup"
              >
                <Button
                  className="mr-1"
                  variant="secondary"
                  text="Cancel"
                  onClick={goBack}
                />
                <Button
                  variant="primary"
                  text="Create"
                  onClick={onCreate}
                  disabled={!channelName || !channelAsset}
                />
              </Flex>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default compose(withRouter)(ChannelCreate);
