/* eslint-disable @typescript-eslint/no-unused-vars */
import { Toaster as BrightcoveStudioToaster } from "@brightcove/studio-components";
import moment from "moment";
import config from "../config";
import { ContentLocalesData } from '../interfaces/Content';
import _ from "lodash";
import { MessageData } from "../interfaces/ScheduledTextPanel";


export enum PermissionLevel {
  READ = "READ",
  WRITE = "WRITE",
};

export const PermissionLevelOptions = [
  { label: "Read", value: PermissionLevel.READ},
  { label: "Write", value: PermissionLevel.WRITE},
];

export const ImageRatios = {
  BANNER: undefined,
  GENERAL: undefined,
  TILE: { width: 16, height: 9 },
  MOBILETILE: { width: 16, height: 9 },
  SLIDE: { width: 16, height: 9 },
  KEYART: { width: 16, height: 9 },
};

export const CONTENT_SERIES = 'SeriesContent';
export const CONTENT_SEASONS = 'SeasonContent';
export const CONTENT_EPISODE = 'EpisodeContent';
export const CONTENT_MOVIE = 'MovieContent';
export const CONTENT_TRAILER = 'TrailerContent';
export const CONTENT_AD = 'AdContent';
export const CONTENT_VIDEO = 'EpisodeContent';
export const DEFAULT_LANGUAGE = 'en-us';

export const getLocaleByLanguage = (locales: any[], currentLanguage: string): ContentLocalesData => {
  return locales.find((locale) => locale.language === currentLanguage)!;
};

export const getNativeLanguage = (locales: any[]) => {
  return locales.find((locale) => locale.isNativeLanguage)?.language;
};

export enum SectionScreenName {
  CONTENT_MANAGEMENT_SCREEN = "CONTENT_MANAGEMENT_SCREEN",
  COLLECTIONS_SCREEN = "COLLECTIONS_SCREEN",
  CHANNELS_SCREEN = "CHANNELS_SCREEN",
  PAGES_SCREEN = "PAGES_SCREEN",
  ACTIVITY_HISTORY_SCREEN = "ACTIVITY_HISTORY_SCREEN",
  DYNAMIC_STRINGS_SCREEN = "DYNAMIC_STRINGS_SCREEN",
  SERVICE_MESSAGES_SCREEN = "SERVICE_MESSAGES_SCREEN",
  MAINTENANCE_MODE_SCREEN = "MAINTENANCE_MODE_SCREEN",
  USER_MANAGEMENT_SCREEN = "USER_MANAGEMENT_SCREEN",
  ALL_CONTENT_SECTION = "ALL_CONTENT_SECTION",
  VIDEO_SECTION = "VIDEO_SECTION",
  IMAGES_SECTION = "IMAGES_SECTION",
  SUBSCRIPTION_SCREEN = "SUBSCRIPTION_SCREEN"
}

export enum ModuleName {
  CONTENT_MANAGEMENT_SCREEN = "CONTENT",
  COLLECTIONS_SCREEN = "COLLECTIONS",
  CHANNELS_SCREEN = "CHANNELS",
  PAGES_SCREEN = "PAGE",
  ACTIVITY_HISTORY_SCREEN = "ACTIVITY",
  DYNAMIC_STRINGS_SCREEN = "STRINGS",
  SERVICE_MESSAGES_SCREEN = "SERVICE_MESSAGES",
  MAINTENANCE_MODE_SCREEN = "MAINTENANCE",
  USER_MANAGEMENT_SCREEN = "USER",
  SUBSCRIPTION_SCREEN = 'SUBSCRIPTION'
}

export const ModuleAccessOptions = [
  { label: "User Management", value: "user", componentName: ModuleName.USER_MANAGEMENT_SCREEN},
  { label: "Content Management", value: "content", componentName: ModuleName.CONTENT_MANAGEMENT_SCREEN},
  { label: "Channels", value: "channels", componentName: ModuleName.CHANNELS_SCREEN},
  { label: "Pages", value: "page", componentName: ModuleName.PAGES_SCREEN},
  // { label: "Collections", value: "collections", componentName: ModuleName.COLLECTIONS_SCREEN},
  { label: "Activity", value: "activity", componentName: ModuleName.ACTIVITY_HISTORY_SCREEN},
  { label: "Maintenance", value: "maintenance", componentName: ModuleName.MAINTENANCE_MODE_SCREEN},
  { label: "Strings", value: "strings", componentName: ModuleName.DYNAMIC_STRINGS_SCREEN},
  { label: "Subscription", value: "subscription", componentName: ModuleName.SUBSCRIPTION_SCREEN},
];

export const ModuleWriteAccessOptions = [
  { label: "User Management", value: "user", componentName: ModuleName.USER_MANAGEMENT_SCREEN},
  { label: "Content Management", value: "content", componentName: ModuleName.CONTENT_MANAGEMENT_SCREEN},
  { label: "Channels", value: "channels", componentName: ModuleName.CHANNELS_SCREEN},
  { label: "Pages", value: "page", componentName: ModuleName.PAGES_SCREEN},
  // { label: "Collections", value: "collections", componentName: ModuleName.COLLECTIONS_SCREEN},
  // { label: "Activity", value: "activity", componentName: ModuleName.ACTIVITY_HISTORY_SCREEN},
  { label: "Maintenance", value: "maintenance", componentName: ModuleName.MAINTENANCE_MODE_SCREEN},
  { label: "Strings", value: "strings", componentName: ModuleName.DYNAMIC_STRINGS_SCREEN},
  { label: "Subscription", value: "subscription", componentName: ModuleName.SUBSCRIPTION_SCREEN},
];

export const AccessOptions = [
  { permission: "user", value: "userPermission"  },
  { permission: "content", value: "contentPermission"  },
  { permission: "channels", value: "channelsPermission"  },
  { permission: "pages", value: "pagePermission"  },
  // { permission: "collections", value: "collectionsPermission"  },
  // { permission: "videos", value: "videosPermission"  },
  { permission: "activity", value: "activityPermission"  },
  { permission: "maintenance", value: "maintenancePermission"  },
  { permission: "strings", value: "stringsPermission"  },
  { permission: "subscription", value: "subscriptionPermission"  },
];

const getScreenWeight = (screen) => {
  const weights = {
    CONTENT_MANAGEMENT_SCREEN: 4,
    COLLECTIONS_SCREEN: 3,
    CHANNELS_SCREEN: 2,
    PAGES_SCREEN: 1,
  };

  return weights[screen];
}

const getContentManagementScreenSectionWeight = (section) => {
  const weights = {
    ALL_CONTENT_SECTION: 7,
    VIDEO_SECTION: 6,
    AUDIO_PERFORMANCES_SECTION: 5,
    SHORT_FORM_PERFORMANCES_SECTION: 4,
    PEOPLE_SECTION: 3,
    IMAGES_SECTION: 2,
    FILES_SECTION: 1,
  };

  return weights[section];
}

export const ScreenPathMap = {
  CONTENT_MANAGEMENT_SCREEN: "/",
  COLLECTIONS_SCREEN: "/collections",
  CHANNELS_SCREEN: "/channels",
  PAGES_SCREEN: "/pages",
  ALL_CONTENT_SECTION: "/",
  VIDEO_SECTION: "/video",
  IMAGES_SECTION: "/images",
}


export const getLandingScreen = (permissionArray) => {
  let maxWeight = 0;
  let maxWeightedScreen;
  if (permissionArray.indexOf(SectionScreenName.CONTENT_MANAGEMENT_SCREEN) > -1) {
    for (const permissionEntry of permissionArray) {
      const currentSectionWeight = getContentManagementScreenSectionWeight(permissionEntry);
      if (currentSectionWeight && maxWeight < currentSectionWeight) {
        maxWeight = currentSectionWeight;
        maxWeightedScreen = permissionEntry;
      }
    }
  } else {
    for (const permissionEntry of permissionArray) {
      const currentScreenWeight = getScreenWeight(permissionEntry);
      if (currentScreenWeight && maxWeight < currentScreenWeight) {
        maxWeight = currentScreenWeight;
        maxWeightedScreen = permissionEntry;
      }
    }
  }

  return maxWeightedScreen;
}

export const getLandingScreenUrl = (permissionArray) => {
  return ScreenPathMap[getLandingScreen(permissionArray)];
}

export const isValidImage = (width, height, type) => {
  const target = ImageRatios[type];
  if (!target) {
    return { result: true };
  }

  const error = { message: `Incorrect image aspect ratio. Please choose another (${target.width}x${target.height} required)` };

  if (!(width || height)) {
    return { result: false, error };
  }

  const targetRatio = target.width / target.height;
  const ratio = width / height;

  if (Math.abs(ratio - targetRatio) < 0.1) {
    return { result: true };
  }

  return { result: false, error };
}

export const getModulePermissions = (moduleName, userPermissions) => {
  // const modulePermissions = userPermissions.filter((permission) => permission.componentName === moduleName);
  return {
    hasReadAccess: !!(userPermissions[moduleName] === PermissionLevel.READ),
    hasWriteAccess: !!(userPermissions[moduleName] === PermissionLevel.WRITE),
  };
};

export const isModuleDisplayed = (moduleName, user) => {
  const { permissions } = user;

  // if (accessType === UserType.SUPER_ADMIN) {
  //   return true;
  // }

  const { hasReadAccess } = getModulePermissions(moduleName, permissions);
  return hasReadAccess;
};

export const capitalize = (str) => {
  return _.startCase(_.toLower(str))
};

export const getDetailPageUrl = (type, id) => {
  return `/${type}/${id}`;
};

export const formatFetchData = (state, currentOrderBy) => {
  let orderBy = currentOrderBy;

  if(state.sorted.length) {
    let id = state.sorted[0].id,
      field = id === "__typename" ? "TYPE" : _.toUpper(_.snakeCase(state.sorted[0].id)),
      direction = state.sorted[0].desc ? "ASC" : "DESC";

    orderBy = {
      field: field,
      direction: direction
    }
  }

  return {
    first: state.pageSize,
    after: state.pageSize * state.page,
    orderBy
  }
};

export const Toaster: any = (() => {
  const TOASTER_AUTOHIDE_TIME = 5; // time in seconds

  return {
    error: (options) =>
      BrightcoveStudioToaster.error({
        hideAfter: TOASTER_AUTOHIDE_TIME,
        ...options,
      }),
    success: (options) =>
      BrightcoveStudioToaster.success({
        hideAfter: TOASTER_AUTOHIDE_TIME,
        ...options,
      }),
    warn: (options) =>
      BrightcoveStudioToaster.warn({
        hideAfter: TOASTER_AUTOHIDE_TIME,
        ...options,
      }),
    info: (options) =>
      BrightcoveStudioToaster.info({
        hideAfter: TOASTER_AUTOHIDE_TIME,
        ...options,
      }),
    dismiss: (options) =>
      BrightcoveStudioToaster.dismiss({
        hideAfter: TOASTER_AUTOHIDE_TIME,
        ...options,
      }),
  };
})();

export const getUrlWithOptions = (
  path,
  query = "",
  sort = "",
  filter = "",
  pageNumber: any = undefined,
  pageSize: any = undefined,
  startKey: any = undefined
) => {
  const baseUrl = `${config.base_mw_url}${path}`;
  if (query || sort || filter || pageNumber || pageSize) {
    const urlQuery = query ? `q=${query}` : "";
    const urlSort = sort ? `sort=${sort}` : "";
    const urlFilter = filter ? `filter=${filter}` : "";
    const urlPageNumber = pageNumber ? `page=${pageNumber}` : "";
    const urlPageSize = pageSize ? `page_size=${pageSize}` : "";
    const urlStartKey = startKey !== undefined && !!startKey ? `startKey=${encodeURIComponent(JSON.stringify(startKey))}` : "";
    return [urlQuery, urlSort, urlFilter, urlPageNumber, urlPageSize, urlStartKey].reduce(
      (fullUrl, item) => {
        if (item) {
          if (fullUrl.endsWith("?")) {
            fullUrl = fullUrl = fullUrl.concat(item);
          } else {
            fullUrl = fullUrl.concat(`&${item}`);
          }
        }
        return fullUrl;
      },
      `${baseUrl}?`,
    );
  }
  return baseUrl;
};

export const formatDate = (date, withTime = true, customFormat = "") => {
  const format = withTime ? "YYYY-MM-DD h:mm A" : "YYYY-MM-DD"
  return date ? moment(date).format(customFormat || format) : "-"
};

export const showDates = (startTime: string, endTime: string) => {
  let dates = '';

  dates += startTime.length ? `From ${startTime} ` : '';
  dates += endTime.length ? ` Until ${endTime}` : '';

  if (!!startTime.length && !!endTime.length) {
    dates = `${startTime} to ${endTime}`;
  }

  return dates;
};

export const formatTimeStamp = (date) => {
  let FormattedDate = date ? moment.unix(Date.parse(date.toLocaleString())/1000).format("YYYY-MM-DDTHH:mm:ss"): '';
  // let formattedTime = time ? time.toLocaleTimeString(): '';
  // const now = new Date(time);
  // const formattedTime = padTo2Digits(now.getHours()) + ':' + padTo2Digits(now.getMinutes())+ ':' + padTo2Digits(now.getSeconds());

  if (FormattedDate) {
    return FormattedDate;
  } else {
    return null;
  }

}

function padTo2Digits(num) {
  return String(num).padStart(2, '0');
}

export const callWithApiKey = (callMethod, url, apiKey, onSuccess, onFail) => {
  fetch(url, {
    method: callMethod,
    headers: {
      "x-api-key": apiKey,
    },
  }).then ((res) => {
    if (res.ok) {
      res.json().then((responseJson) => {
        console.log('calling onSuccess with resp', responseJson);
        onSuccess(responseJson);
      });
      return ;
    } else {
      onFail(res);
    }
  },
  );
}

export const hasValue = (messages: MessageData[]) => {
  return messages.some((message: MessageData) => !!message.value?.length);
};


export const UPDATE = 'update';
export const ADD = 'add';
export const DELETE = 'delete';

export const updateItems = (allItems, newItems, operation, prop = 'id') => {
  let filteredItems;
  const idsToDelete = newItems.map((newItem) => newItem[prop]);

  switch (operation) {
    case UPDATE:
      filteredItems = allItems.map((item) => {
        const updatedItem = newItems.find((newItem) => newItem[prop] === item[prop]);

        return updatedItem ? updatedItem : item;
      });
      break;
    case ADD:
      filteredItems = [...allItems, ...newItems];
      break;
    case DELETE:
      filteredItems = allItems.filter((item) => !idsToDelete.includes(item[prop]));
      break;
    default:
      break;
  }

  return filteredItems;
};

export const fillMissingLanguages = (messages, languages) => {
  const allLang = languages.map((language) => {
    return { language: language.value, value: '' };
  });

  return [...allLang, ...messages].reduce((acc, curr) => {
    const langIndex = acc.findIndex((obj) => obj.language === curr.language);

    if (langIndex === -1) {
      acc.push(curr);
    } else if (curr.value !== '') {
      acc[langIndex].value = curr.value;
    }

    return acc;
  }, []);
};

export const secondsToTimeString = (seconds) => {
  const format = seconds < 60 * 60 ? 'mm:ss' : 'HH:mm:ss';

  return moment.utc(moment.duration(seconds, 'seconds').as('milliseconds')).format(format);
};

export const getSecondFromTime = (hh, mm, ss) => {
  return hh * 3600 + mm * 60 + ss;
};

export const getPageCount = (totalCount, pageSize) => {
  return Math.ceil(totalCount / pageSize);
};

export const getArrayOfNames = (allItems) => {
  return allItems.map(({ name }) => name);
};
export const getItemsByNames = (currentItems, allItems) => {
  return currentItems.map((name) => {
    return getItemsIdByName(name, allItems);
  });
};
export const getItemsIdByName = (name, allItems) => {
  return allItems.find((items) => items.name === name)!;
};
export const isValidDate = (date) => {
  return new Date(date).toString() !== 'Invalid Date';
};
export const getDuration = (startDate, endDate) => {
  return moment.duration(moment(endDate).diff(startDate)).asSeconds();
};
export const getEndTimecode = (startDate, duration) => {
  return moment(startDate).add(duration, 'seconds').toISOString();
};

export const pageFilters = [
  {
    name: 'All Pages',
    id: '',
    image: 'SVGImage.PagesHeaderIcon',
  },
  {
    name: 'Home Pages',
    id: 'HomePage',
  },
  {
    name: 'Detailed Video Pages',
    id: 'DetailPage',
  },
  {
    name: 'Channel Pages',
    id: 'ChannelPage',
  },
  {
    name: 'Search Pages',
    id: 'SearchPage',
  },
  {
    name: 'Error 404 Pages',
    id: 'Error404Page',
  },
  {
    name: 'Custom Pages',
    id: 'CustomPage',
  },
  {
    name: 'Standard Pages',
    id: 'StandardPage',
  },
  {
   name: 'Original Pages',
   id: 'OriginalPage' 
  }
];

export const pageSections = {
  default_sections: ['Series Detail', 'Event Hero'],
  custom_sections: ['Carousel', 'Grid', 'Hero', 'Player', 'VideoDetail', 'Search', 'SearchBar'],
};

export const getOptionValue = (value, options) =>  {
  for (let i = 0; i < options.length; i++)
  {
      let item = options[i];
      if (item.value === value)
      {
          return item;
      }
  }
  return
}

export const ModuleMap = {
  CONTENT: "/",
  CHANNELS: "/channels",
  PAGE: "/pages",
  STRINGS: "/dynamic-strings",
  MAINTENANCE : "/maintenance-mode",
  ACTIVITY: "/activity-history",
  USER: "/user-management",
  SUBSCRIPTION: "/subscription"
}
