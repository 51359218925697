import React, { FC } from "react";
import { Flex } from "@brightcove/studio-components";

import { formatDate } from "../../utils";
import { ScheduledTextPanelData } from "../../interfaces/ScheduledTextPanel";
import Panel from "../../components/Panel/Panel";
import Button from "../../components/Button/Button";

interface MaintenanceModeViewProps extends ScheduledTextPanelData {
  showDeleteModal: (val: boolean, id: string) => void;
  setEditingId: (val: string) => void;
  disabled?: boolean;
}

const MaintenanceModeView: FC<MaintenanceModeViewProps> = ({
  id,
  status,
  start_time,
  end_time,
  message,
  setEditingId,
  showDeleteModal,
  disabled,
  scope,
}) => {
  return (
    <>
      <Panel
        title="Maintenance Mode"
        actions={
          <>
            <Button
              variant="primary"
              text="Delete"
              onClick={() => {
                showDeleteModal(true, id?.length ? id : "");
              }}
              disabled={disabled}
              
            />
            <Button
              variant="secondary"
              text="Edit"              
              onClick={() => {
                setEditingId(id?.length ? id : "");
              }}
              disabled={disabled}
            />
          </>
        }
      >
        <>
          <Flex>
            <div>
              <Flex>
                <div className="Maintenance-mode-view">
                  <p className="Maintenance-mode-view-title">Maintenance Mode</p>
                  <p className="Maintenance-mode-view-content-message">
                    {status === "Inactive" ? "OFF" : "ON"}
                  </p>
                </div>
                <div className="Maintenance-mode-view">
                  <p className="Maintenance-mode-view-title">Platform</p>
                  <p className="Maintenance-mode-view-content">
                    {scope}
                  </p>
                </div>
              </Flex>

              <div className="Maintenance-mode-view">
                <p className="Maintenance-mode-view-title">Message</p>
                <p className="Maintenance-mode-view-content-message">{message}</p>
              </div>
            </div>
            <div>
              {start_time && (
                <Flex>
                  <div className="Maintenance-mode-view">
                    <p className="Maintenance-mode-view-title">Start Date</p>
                    <p className="Maintenance-mode-view-content">
                      {formatDate(start_time, false)}
                    </p>
                  </div>
                  <div className="Maintenance-mode-view">
                    <p className="Maintenance-mode-view-title">Start Time</p>
                    <p className="Maintenance-mode-view-content">
                      {formatDate(start_time, false, "h:mm:s A")}
                    </p>
                  </div>
                </Flex>
              )}
              {end_time && (
                <Flex>
                  <div className="Maintenance-mode-view">
                    <p className="Maintenance-mode-view-title">End Date</p>
                    <p className="Maintenance-mode-view-content">
                      {formatDate(end_time, false)}
                    </p>
                  </div>
                  <div className="Maintenance-mode-view">
                    <p className="Maintenance-mode-view-title">End Time</p>
                    <p className="Maintenance-mode-view-content">
                      {formatDate(end_time, false, "h:mm:s A")}
                    </p>
                  </div>
                </Flex>
              )}
            </div>
          </Flex>
        </>
      </Panel>
    </>
  );
};
export default MaintenanceModeView;
