import React, { FC } from "react";
import classnames from "classnames";
import "./TitleDescription.scss";

interface TitleDescriptionProps {
  title: string;
  description?: string;
  className?: string;
  classNameTitle?: string;
  classNameDescription?: string;
  onClick?: (val: string | number | null | undefined) => void;
  id?: string | number;
  type?: string;
}
const TitleDescription: FC<TitleDescriptionProps> = ({
  title,
  description,
  className,
  classNameTitle,
  classNameDescription,
  onClick,
  id,
  type,
}) => {
  const isClickableStyle = onClick ? { cursor: " pointer" } : {};

  return (
    <div
      className={classnames("TitleDescription", className)}
      style={isClickableStyle}
      onClick={() => {
        if (onClick) {
          onClick(id ? id : null);
        }
      }}
    >
      {type && type === "noClickable" ? (
        <p className={classnames("TitleDescription-noLink")}>{title}</p>
      ) : (
        <p className={classnames("TitleDescription-title", classNameTitle)}>
          {title}
        </p>
      )}
      <p
        className={classnames(
          "TitleDescription-description",
          classNameDescription
        )}
      >
        {description}
      </p>
    </div>
  );
};

export default TitleDescription;
