/* eslint-disable import/no-anonymous-default-export */
const ERROR_MESSAGES = {
  INVALID_EMAIL : "Username not found.",
  INVALID_PASSWORD : "Incorrect username or password.",
  INVALID_CODE : "Invalid verification code provided, please try again",
  PASSWORD_CRITERIA_FAIL : "Your password must be at least 8 characters long, contain at least one number, contain at least one special character and have a mixture of uppercase and lowercase letters.",
  CONFIRM_PASSWORD_MISMATCH : "New password and confirm new password do not match.",
}

const DYNAMIC_STRINGS = {
  SCOPES: ["Global","web","ios","android"],
  LANGS: ["Global","en","th"]
};

const PERMISSIONS = {
    "USER" : "userPermission",
    "ACTIVITY" : "activityPermission",
    "MAINTENANCE" : "maintenancePermission",
    "STRINGS" : "stringsPermission",
    "CONTENT" : "contentPermission",
    "COLLECTIONS" : "collectionsPermission",
    "CHANNELS" : "channelsPermission",
    "PAGE" : "pagePermission",
    "SUBSCRIPTION": "subscriptionPermission"
}

export default {
  DYNAMIC_STRINGS,
  ERROR_MESSAGES,
  PERMISSIONS,
};
