/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Flex, Icon } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import './ActivityLog.scss';
import { formatDate, getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import SimpleSelect from '../../components/ControlledComponents/SimpleSelect';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Loading from '../../components/Loading/Loading';

const INITIAL_KEY = '';
const ActivityLog = () => {
  const navigate = useNavigate();
  const { apiGet } = useApi();
  const [activityFilters, setActivityFilters] = useState<any[]>([{}]);
  const [activityFilter, setActivityFilter] = useState<string>('');
  const [resourceIdFilter, setResourceIdFilter] = useState<string>('');
  const [userFilter, setUserFilter] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [activities, setActivities] = useState([]);
  const [fetchUrl, setFetchUrl] = useState<string>(
    getUrlWithOptions('/ops/activity_logs', undefined, undefined, undefined, currentPageIndex, currentPageSize)
  );
  const [sort, setSort] = useState<string>('changed_at:dsc');
  const [startKey, setStartKey] = useState(INITIAL_KEY);
  const [startKeyData, setStartKeyData] = useState('');
  const prevCountRef = useRef<string>(INITIAL_KEY);
  const [paginationKeyArr, setPaginationKeyArr] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const statusFilters = [
    { label: 'All', value: '' },
    { label: 'Success', value: true },
    { label: 'Fail', value: false },
  ];

  useEffect(() => {
    prevCountRef.current = startKey;
    paginationKeyArr[currentPageIndex+1]= startKeyData;
  }, [startKeyData]);

  useEffect(() => {
    const filtersStr = [activityFilter, statusFilter].filter((filter) => filter).join();

    const queriesStr = [resourceIdFilter, userFilter].filter((filter) => filter).join();
   
    setFetchUrl(
      getUrlWithOptions(
        '/ops/activity_logs',
        queriesStr,
        sort,
        filtersStr,
        currentPageIndex,
        currentPageSize,
        startKey
      )
    );
  }, [activityFilter, resourceIdFilter, userFilter, statusFilter, currentPageIndex, currentPageSize, sort, startKey]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const [{ data: activityTypes }, { data: activitiesData }] = await Promise.all([
        apiGet(getUrlWithOptions('/ops/activity_types')),
        apiGet(fetchUrl),
      ]);

      if (activityTypes) {
        const updatedActivityFilters :any = [];
        Object.entries(activityTypes).forEach(([key, value]) => {
          updatedActivityFilters.push({
            label: key,
            value: key,
          })
          console.log(`${key}: ${value}`);
        });
        // activityTypes.forEach((activity) =>
        //   updatedActivityFilters.push({
        //     label: activity,
        //     value: activity,
        //   })
        // );
        setActivityFilters(updatedActivityFilters);
      }

      // if (activitiesData?.results?.totalCount) {
      //   setTotalCount(activitiesData?.results?.totalCount);
      //   setActivities(activitiesData?.results?.items);
      //   setStartKeyData(activitiesData?.lastEvaluatedKey);

      // }
      // if (activitiesData?.results?.items) {
        if (activitiesData?.results?.totalCount <= (currentPageIndex * currentPageSize)  && (userFilter || resourceIdFilter || activityFilter || statusFilter)) {
          setTotalCount(activitiesData?.results?.totalCount);
          setCurrentPageIndex(0)
        } else if (activitiesData?.results?.totalCount > ((currentPageIndex * currentPageSize)) && (userFilter || resourceIdFilter || activityFilter || statusFilter)){
          setTotalCount(activitiesData?.results?.totalCount);
          let pageCount: number = Math.ceil(activitiesData?.results?.totalCount / currentPageSize);
          pageCount<= currentPageIndex?   setCurrentPageIndex(pageCount) : setCurrentPageIndex(currentPageIndex);
        } else {
          setTotalCount(activitiesData?.results?.totalCount);
        }
        setActivities(activitiesData?.results?.items);
        setLoading(false);
      // }
      if (activitiesData?.lastEvaluatedKey) {
        setStartKeyData(activitiesData?.lastEvaluatedKey);
      } 
    })();
  }, [fetchUrl]);

  const columns = [
    {
      Header: 'Activity',
      accessor: 'activity_type',
      sortable: true,
      Cell: ({ value, row: { original } }) => (
        <><TitleDescription
          title={value}
          id={original.id}
          onClick={(id) => navigate(`../${ROUTES.ACTIVITY}/${id}`)} />
          <div className="subtitle">{original.id}</div></>
      ),
      filter: 'exactText',
      className: 'activity-type',
    },
    {
      Header: 'Content IDs',
      accessor: 'resource_id',
      sortable: true,
      Cell: ({ value }) => <>{value}</>,
      className: 'resource',
    },
    {
      Header: 'User',
      id: 'email',
      sortable: true,
      accessor: (row) => row.changed_by.email,
      Cell: ({ value, row }) => (
        <>
          <div>{value}</div>
          <div className="subtitle">{row.original.changed_by.user_id}</div>
        </>
      ),
    },
    {
      Header: 'Status',
      accessor: 'success',
      sortable: true,
      Cell: ({ value }) => (
        <Icon name={value === "true" ? "check2" : "warning"} color={value === "true" ? '#3886F7' : 'burgundy'} />
        // <Icon name={"check2"} color={'#3886F7'} />

      ),
      filter: 'equals',
      className: 'status',
    },
    {
      Header: 'Created',
      accessor: 'changed_at',
      sortable: true,
      Cell: ({ value }) => <>{formatDate(value)}</>,
      className: 'date',
    },
  ];

  const onPaginationChange = ({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
    setStartKey(pageIndex === 0? '' : paginationKeyArr[pageIndex]);
  };

  const onClickSort = (field, order) => {
    if (field && order) {
      if (field === 'email') {
        setSort(`changed_by.email:${order}`);
      } else {
        setSort(`${field}:${order}`);
      }
    }
  };

  return (
    <>
    {loading && <Loading />}
    <div className="Activity-log">
      <SubHeader
        title="Activity History"
        subtitle=""
        icon={<Icon name="clock" />}
        quantitySubtitle={`${currentTotalCount || 0} Activity Log${currentTotalCount === 1 ? '' : 's'}`}
      />
      <Flex alignItems="flex-end" className="my-4 ml-10">
        <div className="filters-header">Filters</div>
        <div className="ml-8">
          <div className="filter-label">Activities</div>
          <SimpleSelect
            className="select-input-lg mt-1"
            value={activityFilters[0]?.value}
            onChange={(filter) => setActivityFilter(filter !== 'ALL' ? `activity_type:${filter}` : '')}
            options={activityFilters}
          />
        </div>
        <div className="ml-3">
          <div className="filter-label mb-1">Content ID/User</div>
          <InputSearch
            placeholder="Filter by Content ID/User"
            onSearch={(filter) => setResourceIdFilter(filter ? `${filter}` : '')}
          />
        </div>
        {/* <div className="ml-3">
          <div className="filter-label mb-1">User</div>
          <InputSearch
            placeholder="Filter by User"
            onSearch={(filter) => setUserFilter(filter ? `${filter}` : '')}
          />
        </div> */}
        <div className="ml-3">
          <div className="filter-label">Status</div>
          <SimpleSelect
            className="select-input-lg mt-1"
            value={statusFilters[0]?.value}
            onChange={(filter) =>
              setStatusFilter(filter !== undefined && filter !== '' ? `success:${filter}` : '')
            }
            options={statusFilters}
          />
        </div>
      </Flex>
      
      <Table
        data={activities}
        columns={columns}
        hasPagination
        pageCount={Math.ceil(currentTotalCount / currentPageSize)}
        pageIndex={currentPageIndex}
        pageSize={currentPageSize}
        onPaginationChange={onPaginationChange}
        onClickSort={onClickSort}
        totalCount={currentTotalCount}
        defaultSortingValues={[{ id: 'changed_at', desc: true }]}
      />
    </div>
    </>
  );
};

export default ActivityLog;
