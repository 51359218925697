/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from "react";
import { flowRight as compose } from "lodash";
import { Flex, Icon, Thumbnail } from "@brightcove/studio-components";

import * as ROUTES from "../routes";
import { formatDate, getUrlWithOptions } from "../../utils";
import { useApi } from "../../hooks/useApi";
import { withRouter } from "../../components/withRouter/withRouter";
import SubHeader from "../../components/SubHeader/SubHeader";
import Panel from "../../components/Panel/Panel";
import "./CollectionsCreate.scss";
import TextInput from "../../components/ControlledComponents/TextInput";
import SimpleSelect from "../../components/ControlledComponents/SimpleSelect";
import Button from "../../components/Button/Button";
import { RadioGroup } from "../../components/ControlledComponents/RadioGroup";
import _ from "lodash";
import Counter from "../../components/Counter/Counter";
import TitleDescription from "../../components/TitleDescription/TitleDescription";

const typeOptions = [
  { label: "Manual", value:"Manual"},
  { label: "Dynamic", value:"Dynamic"},
  { label: "Series", value:"Series"},
  { label: "Season", value:"Season"},
  { label: "Featured Carousel", value:"Featured"}
];

const includeOptions = [{ label: "Tags", value: "tag" }];

const radioOptions = [
  { label: "Match Any", value: "contains" },
  { label: "Match All", value: "exact" },
];

const sortOptions = [
  { label: "Name (A-Z)", value: "AZ" },
  { label: "Name (Z-A)", value: "ZA" },
  { label: "Updated At (Oldest to Newest)", value: "updated_at:asc" },
  { label: "Updated At (Newest to Oldest)", value: "updated_at:dsc" },
  { label: "Created At (Oldest to Newest)", value: "created_at:asc" },
  { label: "Created At (Newest to Oldest)", value: "created_at:dsc" },
];

const CollectionsCreate = ({ navigate }) => {
  const { apiPost } = useApi();
  const [selectedType, setSelectedType] = useState(typeOptions[0]?.value || "");
  const [collectionName, setCollectionName] = useState<string>("");
  const [collectionURL, setCollectionURL] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [trailerId, setTrailerId] = useState<string>("");
  const [collectionRules, setCollectionRules] = useState<any[]>([
    { id: _.uniqueId(), type: "exact", value: "", key: "tag" },
  ]);
  const [, setValue] = useState("");
  const [maxNumItems, setMaxNumItems] = useState(50);
  const [collectionSort, setCollectionSort] = useState<string>(
    sortOptions[0].value
  );
  const [disableEditing, setDisableEditing] = useState(false);
  const [collectionItems, setCollectionItems] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [contentPageSize, setContentPageSize] = useState(50);
  const [contentPageIndex, setContentPageIndex] = useState(0);
  const [contentTotalCount, setContentTotalCount] = useState(0);
  const [collectionPageSize, setCollectionPageSize] = useState(50);
  const [collectionPageIndex, setCollectionPageIndex] = useState(0);
  const [collectionTotalCount, setCollectionTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [contentSort, setContentSort] = useState<string>("");
  const toggleDisableEditing = () => setDisableEditing(!disableEditing);
  const [contentItemsData, setContentItemsData] = useState<any>({});

  const onUpdateSelectedType = (role) => setSelectedType(role);

  const onCreate = async () => {
    let rules: any = [];
    if (isDynamicCollection()) {
      rules = collectionRules.map((rule) => {
        delete rule.id;
        return rule;
      });
      // rules.push({'maxNumItems': maxNumItems});
      // rules.push({'sort': collectionSort});console.log("rulesrulesrules"+JSON.stringify(rules))
      if (collectionName && selectedType && rules) {
        const { data } = await apiPost(getUrlWithOptions("/ops/collections"), {
          body: {
            name: collectionName,
            collection_type: selectedType,
            image: collectionURL,
            description: description,
            rules: rules,
            maxNumItems: maxNumItems,
            sort: collectionSort
          },
        });
        if (data) {
          navigate(`../${ROUTES.COLLECTIONS}/edit/${data.id}`);
        }
      }
    } else {
      if (collectionName && selectedType) {
        const { data } = await apiPost(getUrlWithOptions("/ops/collections"), {
          body: {
            name: collectionName,
            collection_type: selectedType,
            image: collectionURL,
            description: description,
            trailerId: trailerId
          },
        });
        if (data) {
          navigate(`../${ROUTES.COLLECTIONS}/edit/${data.id}`);
        }
      }
    }
  };

  const formatRules = (rules) => {
    return rules?.map((rule) => ({
      ...rule,
      id: _.uniqueId(),
    }));
  };

  const goBack = () => navigate(`../${ROUTES.COLLECTIONS}`);

  const isDynamicCollection = () => {
    if (selectedType === "Dynamic") {
      return true;
    }
  };

  const isManualCollection = () => {
    if (selectedType === "Manual") {
      return true;
    }
  };

  const addNewRule = () => {
    setCollectionRules(
      collectionRules.concat({
        id: _.uniqueId(),
        value: "",
        type: radioOptions[0].value,
        key: includeOptions[0].value,
      })
    );
  };

  return (
    <div className="Collections-create">
      <SubHeader
        title="New Collection"
        subtitle="Back to Collections"
        className="pl-5 pr-4"
        detailMode={true}
        onBack={goBack}
        icon={<Icon name={"arrow_right"} flip="horizontal" />}
      />

      <div className="main-content">
        <Panel title="Collection Info" actions={<></>}>
          <div className="content ml-4 mb-12">
            <Flex>
              <div className="mr-5 collection-name-wrapper">
                <div className="label required">Collection Name *</div>
                <TextInput
                  className="mt-2"
                  value={collectionName}
                  onChange={(updatedName) => setCollectionName(updatedName)}
                />
              </div>
              <div className="mb-8">
                <div className="label required">Collection Type *</div>
                <SimpleSelect
                  className="select-input-lg mt-2"
                  value={selectedType}
                  options={typeOptions}
                  onChange={onUpdateSelectedType}
                />
              </div>
            </Flex>
            <Flex>
            <div className="mr-5 collection-name-wrapper">
              <div className="label required">URL</div>
              <TextInput
                className="mt-2"
                value={collectionURL}
                onChange={(updatedURL) => setCollectionURL(updatedURL)}
              />
            </div>
            <div className="mr-5 collection-name-wrapper">
                <div className="label required">Trailer ID </div>
                <TextInput
                  className="select-input mt-2"
                  value={trailerId}
                  onChange={(updatedTrailerID) => setTrailerId(updatedTrailerID)}
                />
              </div>
            </Flex>
            <Flex>
            <div className="mr-5 mt-8 collection-name-wrapper">
              <div className="label required">Description</div>
              <TextInput
                className="mt-2"
                value={description}
                onChange={(description) => setDescription(description)}
              />
            </div>
            </Flex>


            {selectedType === "Dynamic" && (
              <>
                <br />
                <div className="content mr-5">
                  {/* <div className="label required">Collection Rule</div> */}
                  {collectionRules.map(({ id, type, value, key }, index) => (
                    <div key={id} className="mt-4">
                      <Flex>
                        <div className="mr-7">
                          <div className="Panel-label mb-1">Include</div>
                          <SimpleSelect
                            className="select-input-lg"
                            value={key}
                            options={includeOptions}
                            onChange={(value) => {
                              const updatedRules = [...collectionRules];

                              updatedRules[index] = {
                                key: value,
                                value: "",
                                type: radioOptions[0].value,
                              };
                              setCollectionRules(updatedRules);
                            }}
                          />
                        </div>
                        <div className="mr-12">
                          <div className="Panel-label mb-1">Keywords</div>
                          <RadioGroup
                            value={type}
                            // options={isIncludeType(type) ? [radioOptions[0]] : radioOptions}
                            options={radioOptions}
                            onChange={(relationValue) => {
                              const updatedRules = [...collectionRules];

                              updatedRules[index].type = relationValue;
                              setCollectionRules(updatedRules);
                            }}
                          />
                        </div>
                        <div className="ml-6">
                          <div className="Panel-label mb-1">Value</div>
                          <TextInput
                            value={value}
                            onChange={(newValue) => {
                              const updatedRules = _.cloneDeep(collectionRules);
                              setValue(value);
                              updatedRules[index].value = newValue;
                              setCollectionRules(updatedRules);
                            }}
                          />
                        </div>
                      </Flex>
                      <Button
                        className="delete-rule-btn"
                        variant="secondary"
                        onClick={() =>
                          setCollectionRules(
                            collectionRules.filter((rule) => rule.id !== id)
                          )
                        }
                      >
                     <p>-&nbsp;&nbsp;&nbsp;&nbsp;Clear</p>
                      </Button>
                      {/* <hr /> */}
                    </div>
                  ))}
                  <Button variant="secondary" onClick={addNewRule} className="Panel-button">
                  <p>+&nbsp;&nbsp;&nbsp;&nbsp;And Include</p>
                  </Button>
                  <div className="mt-10">
                    <div className="Panel-label mb-1">Max # of items</div>
                    <Counter
                      initialCount={maxNumItems}
                      onChange={(count: number) => {
                        setMaxNumItems(count)}}
                    />
                  </div>
                  <div className="mt-5">
                    <div className="Panel-label mb-1">Default Sort</div>
                    <SimpleSelect
                      className="select-input-lg"
                      value={collectionSort}
                      options={sortOptions}
                      onChange={(optionValue) => setCollectionSort(optionValue)}
                    />
                  </div>
                </div>
              </>
            )}
            <div>
              <Flex
                flexDirection="row"
                alignContent="flex-end"
                className="buttonGroup"
              >
                <Button
                  className="mr-1"
                  variant="secondary"
                  text="Cancel"
                  onClick={goBack}
                />
                <Button
                  variant="primary-invert"
                  text="Continue"
                  onClick={onCreate}
                  disabled={!collectionName || !selectedType}
                />
              </Flex>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default compose(withRouter)(CollectionsCreate);
