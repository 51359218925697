/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { Flex} from '@brightcove/studio-components';

import Panel from '../Panel/Panel';
import Button from '../Button/Button';
import './Images.scss';
import { getUrlWithOptions } from '../../utils';
import { ImageData } from '../../interfaces';
import { useApi } from '../../hooks/useApi';
import { TextArea } from '../ControlledComponents';

interface ImagesProps {
  idPanel?: string;
  data: ImageData;
  endpointRootPath: string;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
  disableEditing?: boolean;
}

const DEFAULT_IMAGE = 'https://img.brightcove.com/UIKitLogo-Cube.svg';
const getImageByType = (images, type) => {
  const lowerType = type.toLowerCase();
  return images[lowerType] ? images[lowerType]['src'] : DEFAULT_IMAGE;
};

const Images: FC<ImagesProps> = ({ idPanel, data, endpointRootPath, onEditModeChange, disableEditing }) => {
  const { id } = data;
  const [images, setImages] = useState<any[]>(data?.images || []);
  const {  apiPatch } = useApi();
  const [editing, setEditing] = useState<boolean>(false);
  const [, setImagePoster] = useState<any>({
    image: getImageByType(images, 'Poster'),
    file: getImageByType(images, 'Poster'),
  });
  const [, setImageThumb] = useState<any>({
    image: getImageByType(images, 'Thumbnail'),
    file: getImageByType(images, 'Thumbnail'),
  });
  
  const[posterImage, setPoserImage] = useState('');
  const[thumbImage, setThumbImage] = useState('');
  const [savedData, setSavedData] = useState(false);
  const [saving, setSaving] = useState(false);
  const [, setOriginalImages] = useState<any[]>([]);

  useEffect(() => {
    setImages(data?.custom_fields || []);
    setOriginalImages(data?.custom_fields || []);
  }, [data]);

  useEffect(() => {
    setImagePoster({
      image: getImageByType(images, 'Poster'),
      file: getImageByType(images, 'Poster'),
    });
    setImageThumb({
      image: getImageByType(images, 'Thumbnail'),
      file: getImageByType(images, 'Thumbnail'),
    });
    setPoserImage(getImageByType(images, 'Poster'));
    setThumbImage(getImageByType(images, 'Thumbnail'));
  }, [images, editing]);

  useEffect(() => {
    onEditModeChange && onEditModeChange(editing, savedData);
  }, [editing]);

  const onSaveImages = async () => {
    setSaving(true);

    const [imagePosterUrl] = await Promise.all([
      await getUploadUrl(),
      // await getUploadUrl(thumbImage, 'Thumbnail'),
    ]);

    setSaving(false);

    if (imagePosterUrl) {
      setSavedData(true);
      setEditing(false);
    }
  };

  const getUploadUrl = async () => {
    const body = {
      images: {
        poster: {
          src: posterImage,
          sources: [
            {
            src: posterImage,
            height: 360,
            width: 640
            }
          ]
        },
        thumbnail: {
          src: thumbImage,
          sources: [
            {
            src: thumbImage,
            height: 90,
            width: 160
            }
          ]
        }
      }
    };
    const responseImage = await apiPatch(
      getUrlWithOptions(`${endpointRootPath}/${id}`),
      { body }
    );
    return responseImage?.data;
      
  };

  const onCancel = () => {
    setEditing(false);
  };

  return (
    <div id={idPanel} className="Images">
      <Panel
        title="Images"
        actions={
          <>
            {editing ? (
              <>
                <Button variant="secondary-invert" text="Cancel" disabled={saving} onClick={onCancel} />
                <Button
                  variant="primary"
                  text={saving ? 'Saving...' : 'Save'}
                  disabled={saving}
                  onClick={onSaveImages}
                />
              </>
            ) : (
              <Button
                variant="secondary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                }}
                disabled={disableEditing}
              />
            )}
          </>
        }
      >
        <>
          {editing ? (
            <Flex className="ml-4">
              <div>
                  <p className="title">Poster</p>
                  <div>
                <TextArea
                  className="image-upload"
                  value={posterImage}
                  onChange={(updatedURL) => {
                    setPoserImage(updatedURL)
                  }}
                />
                </div>                
              </div>
              <div className="ml-9">
                  <p className="title">Thumbnail</p>
                  <div>
                <TextArea
                  className="image-upload"
                  value={thumbImage}
                  onChange={(updatedURL) => {
                    setThumbImage(updatedURL)
                  }}
                />
                </div>
              </div>
            </Flex>
          ) : (
            <Flex className="ml-4">
              <div>
                <p className="title">Poster</p>
                  <p className="description">{posterImage}</p>
              </div>
              <div className="ml-9">
                <p className="title">Thumbnail</p>
                <div className="description">
                  {thumbImage}
                </div>
              </div>
            </Flex>
          )}
        </>
      </Panel>
    </div>
  );
};

export default Images;
