/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router';
import React, { FC, useEffect, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import { getUrlWithOptions } from '../../../utils';
import { LocaleInformationData } from '../../../interfaces/Content';
import { useApi } from '../../../hooks/useApi';
import Panel from '../../../components/Panel/Panel';
import TextInput from '../../../components/ControlledComponents/TextInput';
import TextArea from '../../../components/ControlledComponents/TextArea';
import Button from '../../../components/Button/Button';
import './LocaleInformation.scss';

interface TextProps {
  idPanel?: string;
  data: LocaleInformationData;
  disabledEditing?: boolean;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
}

const LocaleInformation: FC<TextProps> = ({ idPanel, data, disabledEditing, onEditModeChange }) => {
  const { id } = useParams();
  const { apiPut, isLoading } = useApi();
  const { name, description, cta, language } = data;
  const [editing, setEditing] = useState<boolean>(false);
  const [currentName, setCurrentName] = useState<string>('');
  const [currentDescription, setCurrentDescription] = useState<string>('');
  const [currentCtaTex, setCurrentCtaTex] = useState<string>('');
  const [currentCtaUrl, setCurrentCtaUrl] = useState<string>('');
  const [defaultData, setDefaultData] = useState<any>({});

  useEffect(() => {
    setDefaultData({
      cta,
    });
    setCurrentName(name?.length ? name : '');
    setCurrentDescription(description?.length ? description : '');
    setCurrentCtaTex(cta?.text?.length ? cta.text : '');
    setCurrentCtaUrl(cta?.url?.length ? cta.url : '');
  }, [data]);

  const restoreData = () => {
    setCurrentCtaTex(defaultData?.cta?.text?.length ? defaultData.cta.text : '');
    setCurrentCtaUrl(defaultData?.cta?.url?.length ? defaultData.cta.url : '');
  };

  const onSaveText = async () => {
    const body = {
      name: currentName,
      description: currentDescription,
      cta: { text: currentCtaTex, url: currentCtaUrl },
    };
    const response = await apiPut(getUrlWithOptions(`/content/${id}/locales/${language}`), {
      body,
    });

    if (response?.data) {
      setEditing(false);
      onEditModeChange?.(!disabledEditing, true);
    }
  };

  return (
    <div id={idPanel} className="LocaleInformation w-100">
      <Panel
        title="Locale Information"
        actions={
          <>
            {editing ? (
              <>
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    restoreData();
                    setEditing(false);
                    onEditModeChange?.(!disabledEditing);
                  }}
                />
                <Button
                  variant="primary"
                  text="Save"
                  onClick={onSaveText}
                  disabled={isLoading || !currentName}
                />
              </>
            ) : (
              <Button
                variant="primary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                  onEditModeChange?.(!disabledEditing);
                }}
                disabled={disabledEditing}
              />
            )}
          </>
        }
      >
        <>
          {editing ? (
            <>
              <div>
                <div className="ml-4 name">
                  <p className="title">Name</p>
                  <TextInput value={currentName} onChange={setCurrentName} />
                </div>
                <div className="ml-4 mt-6 short-description">
                  <p className="title">Description</p>
                  <TextArea value={currentDescription} onChange={setCurrentDescription} />
                </div>
                <Flex className="ml-4 mt-3">
                  <div>
                    <p className="title">CTA Text</p>
                    <TextInput value={currentCtaTex} onChange={setCurrentCtaTex} />
                  </div>
                  <div className="ml-4">
                    <p className="title">CTA Url</p>
                    <TextInput value={currentCtaUrl} onChange={setCurrentCtaUrl} />
                  </div>
                </Flex>
              </div>
            </>
          ) : (
            <div>
              <div className="ml-4 name">
                <p className="title">Name</p>
                <p className="description">{currentName?.length ? currentName : '-'}</p>
              </div>
              <div className="ml-4 mt-6 short-description">
                <p className="title">Description</p>
                <p className="description">{currentDescription.length ? currentDescription : '-'}</p>
              </div>
              <Flex className="ml-4 mt-3">
                <div>
                  <p className="title">CTA Text</p>
                  <p className="description">{currentCtaTex ? currentCtaTex : '-'}</p>
                </div>
                <div className="ml-4">
                  <p className="title">CTA Url</p>
                  <p className="description">{currentCtaUrl ? currentCtaUrl : '-'}</p>
                </div>
              </Flex>
            </div>
          )}
        </>
      </Panel>
    </div>
  );
};

export default LocaleInformation;
