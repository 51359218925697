import React, { FC } from 'react';
import { Flex } from '@brightcove/studio-components';

import Panel from '../../../components/Panel/Panel';

interface ContentProps {
  idPanel: string;
  data: any;
}

const ContentAdTrailer: FC<ContentProps> = ({ idPanel, data }) => {
  const { id, extId, source, name } = data;

  return (
    <div id={idPanel} className="Content">
      <Panel title="Content" actions={<></>}>
        <>
          <Flex>
            <div>
              <div className="ml-4">
                <p className="title">ID</p>
                <p className="description">{id}</p>
              </div>
              <div className="ml-4 mt-6">
                <p className="title">Source</p>
                <p className="description">{source ? source : '-'}</p>
              </div>
              <div className="ml-4 mt-6">
                <p className="title">Name</p>
                <p className="description">{name ? name : '-'}</p>
              </div>
              <div className="ml-4 mt-6">
                <p className="title ">Reference ID</p>
                {extId?.length ? (
                  <p className="link">
                    <a href={extId}>{extId}</a>
                  </p>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </Flex>
        </>
      </Panel>
    </div>
  );
};

export default ContentAdTrailer;
