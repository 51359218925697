/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate, useParams } from "react-router";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Flex, Icon, ICONS } from "@brightcove/studio-components";

import * as ROUTES from "../routes";
import { ModuleName, getModulePermissions, getUrlWithOptions } from "../../utils";
import useGoBack from "../../hooks/useGoBack";
import { useApi } from "../../hooks/useApi";
import TableOfContents from "../../components/TableOfContents/TableOfContents";
import SubHeader from "../../components/SubHeader/SubHeader";
import { DeleteConfirmationModal, withModal } from "../../components/Modals";
import Loading from "../../components/Loading/Loading";
import Button from "../../components/Button/Button";
import PageInformation from "./Panels/PageInformation";

import "./PageDetail.scss";
import AvailabilityDate from "../../components/Panel/AvailibilityDate";
import EditPanel from "../../components/Panel/EditPanel";
import Sections from "./Panels/Sections";
import { useSelector } from "react-redux";

const DeleteButton = withModal(Button, DeleteConfirmationModal);

const PageDetail = () => {
  const { id } = useParams() as { id: string };
  const { goBackLabel, goBack } = useGoBack(ROUTES.PAGES, "Back to Pages");
  const { apiGet, apiPost, apiPatch, apiDelete, refetchGet } = useApi();
  const [disableEditing, setDisableEditing] = useState(false);
  const [pageInfo, setPageInfo] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const PAGES_ENDPOINT = "/ops/pages";
  const pageUrl = getUrlWithOptions(`${PAGES_ENDPOINT}/${id}`);
  const pageType = pageInfo?.type?.toLowerCase();
  const status = pageInfo?.status?.toLowerCase();
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  // const pagesUrl = getUrlWithOptions('/ops/pages');
  const permissions = useSelector((state:any) => state.permissions);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.PAGES_SCREEN, permissions);

  const initialContent = [
    { title: "Page Information", id: "sectionContent", offset: -250 },
    { title: "Availability", id: "availibility", offset: -300 },
    { title: "Page Content", id: "pagesContents", offset: -500 },
  ];

  useEffect(() => {
    (async () => {
      const { data } = await apiGet(pageUrl);
      if (data) {
        setPageInfo(data);
        setIsActive(data?.status === 'active' ? true : false)
      }

      setLoading(false);
    })();
  }, [refetch]);

  const toggleDisableEditing = () => setDisableEditing(!disableEditing);

  const getPagePublishingStatus = async () => {
    return apiGet(`${pageUrl}/status`);
  };

  const updateLocales = (updatedLocale, updatedLocales) => {};

  const updatePageInfo = async (updatedPageInfo): Promise<any> => {
    const { data, error } = await apiPatch(pageUrl, { body: updatedPageInfo });

    if (error) {
      return { error };
    }

    if (data) {
      setPageInfo(data);
    }

    // toggleDisableEditing();
    // navigate(pageUrl);
    // setDisableEditing(false)
    return {};
  };

  const updatePageSectionInfo = async (updatedPageInfo): Promise<any> => {
    let bodyString : any = [];
    updatedPageInfo?.sections?.map((info) =>
      bodyString.push({
        internal_name: info?.internal_name,
        type: info?.type,
        showAds: info?.showAds,
        content: info?.content,
      })
    );
    const { data, error } = await apiPatch(pageUrl, {
      body: { sections: bodyString },
    });

    if (error) {
      return { error };
    }

    if (data) {
      setPageInfo(data);
    }

    // toggleDisableEditing();
    // navigate(pageUrl);
    // setDisableEditing(false)
    return {};
  };

  const onDeletePage = async () => {
    const { error } = await apiDelete(
      getUrlWithOptions(`${PAGES_ENDPOINT}?ids=${pageInfo.id}`)
    );

    if (!error) {
      goBack();
    }
  };

  const onEditModeChange = async (editing, isSavedData) => {
    setDisableEditing(editing);

    if (isSavedData) {
      setRefetch((prevRefetch) => !prevRefetch);
    }
  };

  const defaultPanelProps = {
    onCancel: ()=>{},
    onSave: updatePageInfo,
    disableEditing,
    onEnableEditing: toggleDisableEditing,
  };

  const onDuplicatePages = async () => {
    setIsDuplicating(true);

    const { error, data } = await apiPost(`${pageUrl}/duplicate`);

    if (!error) {

      if (data) {
        setPageInfo(data);
        setIsDuplicating(false);
        navigate(`../${ROUTES.PAGES}/edit/${data?.id}`)
      }
    }

    // setIsDuplicating(false);
    return {};
  };

  const onSaveNewBlock = async () => {
    // setIsDuplicating(true);

    // const { error } = await apiPost(`${pageUrl}/duplicate`);

    // if (!error) {
    //   const { data } = await refetchGet();

    //   if (data) {
    //     setPageInfo(data);
    //   }
    // }

    // setIsDuplicating(false);
    return {};
  };

  const onActivateDeactivate = async () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }

    const body = {
      status: isActive ? "inactive" : "active"
    }
    const { data, error } = await apiPatch(pageUrl, { body: body });

    if (error) {
      return { error };
    }

    if (data) {
      setPageInfo(data);
    }
  };

  return (
    <>
      {/* <AddBodyClasses classes={['disabled-overflow']} /> */}
      {loading ? (
        <Loading />
      ) : (
        <div className="Page-detail">
          <SubHeader
            title={pageInfo?.internal_name}
            subtitle={goBackLabel}
            className="pl-5 pr-4"
            detailMode={true}
            onBack={goBack}
            icon={<Icon name={"arrow_right"} flip="horizontal" />}
            actions={
              <>
                <>
                  <Button
                    className="mr-1"
                    variant="secondary-invert"
                    text={isDuplicating ? "Duplicating" : "Duplicate Page"}
                    onClick={onDuplicatePages}
                    disabled={disableEditing || !hasWriteAccess}
                  />
                  <Button
                    className="mr-1"
                    variant="primary"
                    text={isActive === true ? "Deactivate" : "Activate"}
                    onClick={onActivateDeactivate}
                    disabled={disableEditing || !hasWriteAccess}
                  />
                  {!pageInfo?.internal && (
                    <DeleteButton
                      className="ml-1 mr-1"
                      variant="secondary"
                      disabled={disableEditing || !hasWriteAccess}
                      modalProps={{
                        title: "Delete",
                        children: (
                          <>
                            Permanently delete 1 page from the system? This cannot be undone.
                          </>
                        ),
                      }}
                      onModalConfirm={onDeletePage}
                    >
                      Delete
                    </DeleteButton>
                  )}
                </>
              </>
            }
          />
          <Flex>
            <div style={{ flex: 1 }} className="main-content py-8 px-11">
              <PageInformation
                {...defaultPanelProps}
                idPanel="sectionContent"
                data={pageInfo}
                disableEditing={!hasWriteAccess}
              />
              <AvailabilityDate
                idPanel="availibility"
                data={pageInfo}
                endpointRootPath={PAGES_ENDPOINT}
                // onEditModeChange={toggleDisableEditing}
                disableEditing={!hasWriteAccess}
              />
              <Sections
                // {...defaultPanelProps}
                noThumbnails
                onCancel={ ()=>{}}
                onSave={updatePageSectionInfo}
                onEnableEditing= {toggleDisableEditing}
                panelId="pagesContents"
                title="Page Content"
                sections={pageInfo?.sections}
                sectionType="sections"
                disableEditing={!hasWriteAccess}
              />
            </div>

            {/* <div className="ml-8 mr-8"> */}
            <TableOfContents
              className="pt-12 mr-11"
              data={initialContent}
              isFixed={false}
              isSticky
              hasBorder
            />
            {/* </div>  */}
          </Flex>
        </div>
      )}
    </>
  );
};

export default PageDetail;
