import { SearchInput as StudioSearchInput } from "@brightcove/studio-components";
import classnames from "classnames";
import _ from "lodash";
import React from "react";

export default class SearchInput extends React.Component<any, any> {
  static defaultProps = {
    minCharacters: 3,
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || "",
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({value: this.props.value});
    }
  }

  onChange = (value) => {
    const { minCharacters, onChange, property } = this.props;
    this.setState({value});

    if (onChange && (value.length >= minCharacters || !value.length)) {
      const debouncedOnChange = _.debounce(onChange, 750);
      debouncedOnChange(value, property);
    }
  };

  render() {
    const { minCharacters, onChange, className, ...props } = this.props;
    const { value } = this.state;
    return <StudioSearchInput className={classnames(["search-input", className])}  {...props} value={value} onChange={this.onChange} />
  }
}
