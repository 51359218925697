/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { SVGImage } from "../../assets/images";
import React, { useEffect, useState } from "react";
import { Tabs, TabList, Tab, DropdownMenu, DropdownMenuItem } from "@brightcove/studio-components";
import { useNavigate , NavLink, useLocation } from "react-router-dom";
import "./Header.scss";
import { ModuleMap, ModuleName, getModulePermissions, getUrlWithOptions } from "../../utils";
import classnames from "classnames";
import RestrictedHeaderItem from "./RestrictedHeaderItem";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slices/userSlice";
import { STRINGS, SUBSCRIPTION } from "../../routes/routes";
import { useApi } from "../../hooks/useApi";
import * as ROUTES from "../../routes/routes";


const Header: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const permissions = useSelector((state:any) => state.permissions);
  const email= useSelector((state:any) => state.email);
  const { apiGet } = useApi();
  useEffect(() => {
    console.log('user is', email, permissions);
    getTab();
  }, []);
  
  const validateToken = async () => {
    const  response  = await apiGet(getUrlWithOptions(`/ops/user/token`));
    if (response.status !== 204) {
      dispatch(logout());
      navigate(ROUTES.LOGIN);
    }
  }

  useEffect(() => {
    validateToken();
    setSelectedTab(location.pathname);
  }, [location.pathname]);

  const getTab = () => {
    const tabIds = ["/", "/collections", "/channels", "/pages"];
    const path = location.pathname;
    const pathStart = `/${path.split("/")[1]}`;
    let selectedTab = "";

    for (const id of tabIds) {
      if (id.includes(pathStart)) {
        selectedTab = id;
      }

      if (path === "/") break;
    }
    // navigate(selectedTab);
    setSelectedTab(selectedTab);
    setIsSelected(false);
  };

  const onSelect = (tabId: any) => {
    setSelectedTab(tabId);
    navigate(tabId);    
    setIsSelected(false);
  };

  const toAdmin = (id: string) => () => {
    navigate(id);
    setSelectedTab('')
    setIsSelected(true);
  };

  const toExternal = (url: string, newWindow?: boolean) => () => {
    if (newWindow) window.open(url, "_blank");
    else window.location.href = url;
  };

  const handleLogout = (event) => {
    event.preventDefault();
    dispatch(logout());
    // setSelectedTab('')
    navigate('/login');
  };

  return location.pathname.includes("/login") ? (
    <></>
  ) : (
    <div className="header">
      <div className="header-logo">
        <NavLink to="/">
          <img
            className="Header-logo"
            src={SVGImage.One31Logo}
            alt="One31 Logo"
          />
        </NavLink>
      </div>
      {permissions ? (
        <>
          <Tabs
            selectedId={selectedTab}
            onSelect={onSelect}
            className={"header-tabs"}
          >
            <TabList>
              <RestrictedHeaderItem
                tabId="/"
                className={`header-tab${selectedTab === "/" || selectedTab.includes('content') ? "-selected" : " "}`}
                moduleName={ModuleName.CONTENT_MANAGEMENT_SCREEN}
                component={Tab}
              >
                Content Management
              </RestrictedHeaderItem>
              <RestrictedHeaderItem
                tabId="/collections"
                className={`header-tab${selectedTab.includes("/collections") ? "-selected" : " "}`}
                moduleName={ModuleName.CONTENT_MANAGEMENT_SCREEN}
                component={Tab}
              >
                Collections
              </RestrictedHeaderItem>
              <RestrictedHeaderItem
                tabId="/channels"
                className={`header-tab${selectedTab.includes("/channels") ? "-selected" : " "}`}
                moduleName={ModuleName.CHANNELS_SCREEN}
                component={Tab}
              >
                Channels
              </RestrictedHeaderItem>
              <RestrictedHeaderItem
                tabId="/pages"
                className={`header-tab${selectedTab.includes("/pages") ? "-selected" : " "}`}
                moduleName={ModuleName.PAGES_SCREEN}
                component={Tab}
              >
                Pages
              </RestrictedHeaderItem>
            </TabList>
          </Tabs>

          <div className="header-menus">
              <DropdownMenu
                className="header-admin-menu"
                label="ADMIN"
                buttonProps={{ className: "header-menu-button" }}
              >
                <div className="dropdown-content">
                  <RestrictedHeaderItem onClick={toAdmin("/activity-history")}
                    moduleName={ModuleName.ACTIVITY_HISTORY_SCREEN}
                    component={DropdownMenuItem}>
                    Activity History
                  </RestrictedHeaderItem>
                  <RestrictedHeaderItem onClick={toAdmin(STRINGS)}
                    moduleName={ModuleName.DYNAMIC_STRINGS_SCREEN}
                    component={DropdownMenuItem}>
                    Dynamic Strings
                  </RestrictedHeaderItem>
                  <RestrictedHeaderItem onClick={toAdmin("/maintenance-mode")}
                    moduleName={ModuleName.MAINTENANCE_MODE_SCREEN}
                    component={DropdownMenuItem}>
                    Maintenance Mode
                  </RestrictedHeaderItem>
                  <RestrictedHeaderItem
                    onClick={toAdmin("/user-management")}
                    moduleName={ModuleName.USER_MANAGEMENT_SCREEN}
                    component={DropdownMenuItem}
                  >
                    User Management
                  </RestrictedHeaderItem>
                  <RestrictedHeaderItem
                    onClick={toAdmin(SUBSCRIPTION)} 
                    component={DropdownMenuItem} 
                    moduleName={ModuleName.SUBSCRIPTION_SCREEN}>
                    Subscription
                  </RestrictedHeaderItem>
                  <DropdownMenuItem
                    onClick={toExternal("https://studio.brightcove.com", true)}
                  >
                    Brightcove Video Cloud
                  </DropdownMenuItem>
                </div>
              </DropdownMenu>
            <DropdownMenu
              className="header-user-menu"
              label={email || ""}
              buttonProps={{ className: "header-menu-button" }}
            >
              <div className="dropdown-content-profile">
                <DropdownMenuItem onClick={toAdmin("/profile")}>
                  My Profile
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleLogout}>
                  Sign Out
                </DropdownMenuItem>
              </div>
            </DropdownMenu>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Header;
