/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';

interface AddBodyClassesProps {
  classes: string[];
}

const AddBodyClasses: FC<AddBodyClassesProps> = ({ classes }) => {
  useEffect(() => {
    const body = document.querySelector('body');

    // this is necessary to set the width of the Add Item Modal on this specific page,
    // as the modal is not a child element of this component
    body?.classList.add(...classes);

    return () => {
      body?.classList.remove(...classes);
    };
  }, []);

  return null;
};

export default AddBodyClasses;
