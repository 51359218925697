/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router';
import React, { FC, useEffect, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import SimpleSelect from '../ControlledComponents/SimpleSelect';
import Button from '../Button/Button';
import { getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import './LanguageDropdown.scss';

interface LanguageDropdownProps {
  title: string;
  currentLanguage: string;
  nativeLanguage: string;
  allowNativeLanguageUpdate?: boolean;
  onLanguageChange: (val: string) => void;
  onNativeLanguageChange?: (val: any) => void;
  endpointRootPath: string;
}

const LanguageDropdown: FC<LanguageDropdownProps> = ({
  title,
  currentLanguage,
  nativeLanguage,
  allowNativeLanguageUpdate = true,
  onLanguageChange,
  onNativeLanguageChange,
  endpointRootPath,
}) => {
  const { id } = useParams();
  const { apiGet, apiPut } = useApi();
  const [languages, setAllLanguages] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await apiGet(getUrlWithOptions(`/languages`));

      setAllLanguages(data);
    })();
  }, []);

  const getLanguages = () => {
    return languages.map(({ label, value }) => ({
      label: `${nativeLanguage === value ? 'Native Language: ' : ''}${label}`,
      value,
    }));
  };

  const setAsNativeLanguage = async () => {
    const body = {
      isNativeLanguage: true,
    };
    const response = await apiPut(getUrlWithOptions(`${endpointRootPath}/${id}/locales/${currentLanguage}`), {
      body,
    });

    if (response?.data) {
      onNativeLanguageChange?.(response.data);
    }
  };

  return (
    <Flex className="Language-dropdown" flexDirection="column">
      {languages.length > 0 && (
        <>
          <p className="title">{title}</p>
          <SimpleSelect
            className="select-input-lg mr-2 mt-2"
            value={currentLanguage}
            options={getLanguages()}
            onChange={(value) => {
              onLanguageChange(value);
            }}
          />
          {allowNativeLanguageUpdate && (
            <Button
              className="mt-3"
              variant="primary"
              text="Set as Native Language"
              onClick={setAsNativeLanguage}
              disabled={nativeLanguage === currentLanguage}
            />
          )}
        </>
      )}
    </Flex>
  );
};

export default LanguageDropdown;
