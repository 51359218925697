import { PlainTagListInputProps, TagListInput as StudioTagListInput } from "@brightcove/studio-components";
import classnames from "classnames";
import _ from "lodash";
import React from "react";
import ControlledProps from "./ControlledProps";

interface TagListInputProps {
  maxLength?: number
  normalize?: boolean;
}

export default class TagListInput extends React.Component<PlainTagListInputProps & ControlledProps & TagListInputProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      values: props.value || "",
    }
  }

  componentDidMount() {
    const { onChange, property } = this.props;
    const { values } = this.state;
    if (onChange) {
      onChange(values, property);
    }
  }

  onChange = (values) => {
    const { onChange, property, normalize } = this.props;
    if (normalize) { values = values.map((value) => _.capitalize(value)); }

    this.setState({values})
    if (onChange) {
      onChange(values, property);
    }
  }

  render() {
    const { property, className: customClassName, maxLength, ...props } = this.props;
    const { values } = this.state;

    const className = classnames(["tag-list-input", { "max-length": maxLength && values.length >= maxLength }, customClassName])
    return (
      <StudioTagListInput className={className} {...props} value={values} onChange={this.onChange} />
      )
  }
}
