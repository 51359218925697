/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-throw-literal */
import { getApp, getApps, initializeApp } from "firebase/app";
import {
  confirmPasswordReset,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  getIdToken
} from "firebase/auth";
import config from "../config";
import { callWithApiKey, getUrlWithOptions } from "../utils";

export default class FirebaseAuthenticator {
  options;
  userSession;
  userPool;
  requiresLoginScreen = true;
  userAttributes;
  cognitoUser;
  auth;

  constructor() {
    // this.options = config
    this.userSession = null
    this.userAttributes = null
    this.auth = this.createApp();
  }

  createApp() {
    // const app = initializeApp(this.firebaseConfig);
    // console.log(app.name);
    // // console.log(config.env.firebase.project_id);
    // return getAuth(app);
    console.log('createApp called');
    const firebaseAppExists = !!(getApps().length > 0);
    if (firebaseAppExists) {
      console.log("firebaseAppExists is true");
      return getAuth(getApp());
    } else {
      console.log("firebaseAppExists is false so fetching config");
      const configUrl = getUrlWithOptions(`/ops/config/${config.firebase_client}`)
      console.log("configurl is configUrl", configUrl);
      console.log("config.firebase_config_key", config.firebase_config_key);

      callWithApiKey("GET", configUrl, config.firebase_config_key, (configResponse) => {
        console.log("in success");
        const fetchedConfig = configResponse.payload;
        try {
          if (fetchedConfig) {
            console.log("initialising with config", fetchedConfig);
            const firebaseApp = initializeApp(fetchedConfig);
            console.log(firebaseApp.name);
            return getAuth(firebaseApp);
          } else {
              throw {error: "Configuration missing"};
            }
          } catch (error) {
              console.error(error);
            }
          }, (error) => {
              console.error(error.message);
            })
          }

  }

  isAuthenticated() {
    return new Promise<boolean>((resolve) => {
      const user = this.getCurrentUser()
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            console.log(err);
            return resolve(false);
          }
          this.userSession = session;
          resolve(true);
        })
      } else {
        resolve(false);
      }
    });
  }



  async authenticate(username, password, successHandler, errorHandler) {
    console.log("authenticate called with", username, password);
    if (username && password) {
      try {
        const user = await signInWithEmailAndPassword(
          this.auth,
          username,
          password,
        );
        console.log(user);
        if (successHandler) {
          user.user.getIdTokenResult()
          .then((idTokenResult) => {
            console.log('idTokenResult', idTokenResult);
            console.log('idTokenResult claims is', idTokenResult.claims);
            const {userPermission, activityPermission, maintenancePermission, stringsPermission, contentPermission, collectionsPermission, channelsPermission, pagePermission, 
              subscriptionPermission, email, name} = idTokenResult.claims;
            const permissions = {
              userPermission,
              activityPermission,
              maintenancePermission,
              stringsPermission,
              contentPermission,
              collectionsPermission,
              channelsPermission,
              pagePermission,
              subscriptionPermission
            }
            let newPermissions = {};

            Object.keys(permissions)?.map(keyy => {
              let split = permissions[keyy]?.split('_');
                console.log('split',permissions[keyy], split);
                split ? newPermissions[`${split[1]}`] = split[0]: '';
                console.log('np', newPermissions);
            });
            const accessToken = idTokenResult.token;
            const user = {
              name,
              email,
              permissions: newPermissions,
              accessToken
            };
            // result = {
            //   ...claims,
            //   accessToken : accessToken
            // };
            console.log('returning user', user);
            successHandler(user);
          })
          .catch((error) => {
            console.log(error);
          });
        }
      } catch (error) {
        console.log('err caught in firebase auth', error);
        if (errorHandler) {
          // Dev Note: errorHandler supports error codes as specified here - https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error
          errorHandler(error);
        }
      }
    }

  }

  async logout(successHandler, errorHandler) {
    signOut(this.auth).then(() => {
      // Sign-out successful.
        if (successHandler) { successHandler(); }
      }).catch((error) => {
        // An error happened.
        if (errorHandler) { errorHandler(); }
      });
  }

  async getIdToken() {
  const { currentUser } = this.auth;
    return await getIdToken(currentUser, true)
  }

  userInfo() {
    return new Promise((resolve) => {
      const user = this.getCurrentUser()
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            console.log(err);
            return resolve({});
          }
          this.userSession = session;
          user.getUserAttributes((error, result) => {
            if (error) {
              console.log(error);
              return resolve({});
            }
            const info = {};
            for (const field of result) {
              info[field.getName()] = field.getValue();
            }
            resolve(info);
          });
        });
      } else {
        resolve({});
      }
    })
  }

  hasExpired() {
    if (!this.userSession) {
      return true;
    }
    return !this.userSession.isValid();
  }

  getCurrentUser() {
    return this.userPool.getCurrentUser();
  }

  handleFirstLogin(newPassword, done) {
    this.cognitoUser.completeNewPasswordChallenge(newPassword, this.userAttributes, {
      onSuccess: (result) => {
        this.userSession = result;
        done(result);
      },
      onFailure: (err) => {
        done(false, err);
      },
    });
  }

  async initiateResetPassword(username, successHandler, errrorHandler) {
    console.log("sending reset link");
    const actionCodeSettings = {
      handleCodeInApp: true,
      url: `${config.redirect_url}`
    };
    // await sendPasswordResetEmail(this.auth, username, actionCodeSettings);
    sendPasswordResetEmail(this.auth, username, actionCodeSettings).then(function() {
      // Password reset email sent.
      if (successHandler) {
        successHandler();
      }
    })
    .catch(function(error) {
      if (errrorHandler) {
        errrorHandler(error);
      }
    });
  }

  async confirmNewPassword(verificationCode, newPassword, successHandler, errrorHandler) {
    // Obtain code from user.
    await confirmPasswordReset(this.auth, verificationCode, newPassword).then(function(result) {
      // Password has been reset
      console.log("Password has been reset");
      if (successHandler) {
        successHandler(result);
      }
    })
    .catch(function(error) {
      if (errrorHandler) {
        errrorHandler(error);
      }
    });
  }

  updateNewPassword(newPassword, done, failed) {
    console.log("updateNewPassword called with newPassword, done, error as ", newPassword, done, failed);
    const auth = getAuth();

    const user = auth.currentUser;

    if (user) {
      updatePassword(user, newPassword).then(() => {
        // Update successful.
        console.log("updated successfully");
        done();
      }).catch((error) => {
        // An error ocurred
        console.log("error when updating password");
        failed(error);
      });
    }

  }
};
