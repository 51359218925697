/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate, useParams } from "react-router";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import classNames from "classnames";

import * as ROUTES from "../../routes";
import { formatDate, pageSections } from "../../../utils";
import TitleDescription from "../../../components/TitleDescription/TitleDescription";
import Table from "../../../components/Table/Table";
import EditPanel from "../../../components/Panel/EditPanel";
import AddSectionModal from "../../../components/Modals/AddSectionModal";
import { withModal } from "../../../components/Modals";
import Button from "../../../components/Button/Button";
import "./Sections.scss";
import { Icon, Thumbnail } from "@brightcove/studio-components";

const allSections = _.values(pageSections).flat();
const defaultSections = pageSections.default_sections.map((section) =>
  section.replace(/ /g, "").toLowerCase()
);
const AddSectionButton = withModal(Button, AddSectionModal);

const Sections = ({
  panelId,
  sections,
  sectionType,
  title,
  disableEditing,
  onEnableEditing,
  onCancel,
  onSave,
  noThumbnails
}) => {
  const navigate = useNavigate();
  const { id: pageId } = useParams();
  const [sectionsInfo, setSectionsInfo] = useState<any[]>([]);
  useEffect(() => {
    setSectionsInfo(sections);
  }, [sections]);

  const cancelChanges = () => {
    setSectionsInfo(sections);
    onCancel();
  };

  const onClickDeleteRow = (id: string) => {
    setSectionsInfo((prev) => prev.filter((item: any) => item.id !== id));
  };

  const onDragRow = (reorderedRows: any[]) => setSectionsInfo(reorderedRows);

  const isDefaultSection = (type) => {
    return defaultSections.indexOf(type.toLowerCase()) > -1;
  };

  const getSectionType = (type) => {
    return allSections.filter(
      (sectionType) =>
        sectionType.replace(/ /g, "").toLowerCase() === type.toLowerCase()
    );
  };

  const parseSections = (sections) => {
    return sections.map((section) => ({
      ...section,
      disableDragging: isDefaultSection(section.type),
      disableDeletion: isDefaultSection(section.type),
    }));
  };

  const addSection = (data) => {
    setSectionsInfo(() => {
      return [...sectionsInfo, { ...data, id: `temp_id_${_.uniqueId()}` }];
    });
  };

  const saveChanges = () => {
    const updatedSectionInfo = sectionsInfo.map((section) =>
      section.id.indexOf("temp_id") > -1 ? _.omit(section, ["id"]) : section
    );

    return onSave({ [sectionType]: updatedSectionInfo });
  };

  let columns = [
    {
      accessor: (row) => ({ thumbnail:  row.image, id: row.id }),
      id: "images",
      colWidth: "15%",
      Cell: ({ value }) => (
        <Thumbnail
          className="Collections-detail-thumbnail"
          src={
            value.thumbnail
          }
        />
      ),
      className: "thumbnail-cell",
    },
    {
      Header: "Sections",
      colWidth: "30%",
      accessor: (row) => [row.internal_name, row.id],
      Cell: ({ value }) => {
        const [internal_name, sectionId] = value;

        return (
          <>
            {sectionId.indexOf("temp_id") > -1 ? (
              internal_name
            ) : (
              <TitleDescription
              className="titleClass"
                title={internal_name}
                description={sectionId}
                id={sectionId}
                onClick={() =>{}
                  // navigate(
                  //   `../${ROUTES.PAGES}/edit/${pageId}/sections/${sectionId}`
                  // )
                }
                type={"noClickable"}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Type",
      colWidth: "15%",
      accessor: "type",
      Cell: ({ value }) => getSectionType(value),
    },
    {
      Header: "Collection ID",
      colWidth: "25%",
      accessor: (row) => row?.content?.id || "*",
      Cell: ({ value }) => value,
    },
    {
      Header: "Show Ads",
      colWidth: "20%",
      accessor: (row) => row?.showAds,
      Cell: ({ value }) => {
        return value === true ? "true" : "false";
      },
    },
    {
      Header: "Status",
      colWidth: "10%",
      accessor: "status",
      Cell: ({ value }) => {
        return (
          <Icon
        name={value === "active" ? "check2" : "warning"}
        color={value === "active" ? "blue" : "burgundy"}
      />
        );
      },
    },
    {
      Header: "Start Date",
      colWidth: "20%",
      accessor: (row) => row?.start_date,
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "End Date",
      colWidth: "30%",
      accessor: (row) => row?.end_date,
      Cell: ({ value }) => formatDate(value),
    },
  ];

  if (noThumbnails) {
    columns = columns.slice(1);
  }

  return (
    <EditPanel
      id={panelId}
      title={title}
      disableEditing={disableEditing}
      // onEnableEditing={onEnableEditing}
      onCancel={cancelChanges}
      save={saveChanges}
    >
      {(isEditing) => (
        <div className="Page-detail-sections">
          {sectionsInfo && (
            <Table
              className={classNames({ editing: isEditing })}
              data={parseSections(sectionsInfo) || []}
              columns={columns}
              onClickDeleteRow={onClickDeleteRow}
              onDragRow={onDragRow}
              hasDeletion
              hasDragging
            />
          )}
          {isEditing && (
            <AddSectionButton
              className="mb-5 mt-5 ml-6 add-menu-item-button"
              variant="link"
              modalProps={{
                confirmBtnLabel: "Add Section",
                singleSelection: true,
                onAddSection: addSection,
              }}
            >
              + Add a Page Block
            </AddSectionButton>
          )}
        </div>
      )}
    </EditPanel>
  );
};

export default Sections;
