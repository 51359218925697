/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Flex } from "@brightcove/studio-components";

import "./ContentManagementDetail.scss";

import {
  getNativeLanguage,
  getLocaleByLanguage,
  DEFAULT_LANGUAGE,
  CONTENT_EPISODE,
  CONTENT_VIDEO,
  getModulePermissions,
  ModuleName,
} from "../../utils";
import {
  ContentDetailData,
  ContentLocalesData,
  CuePointData,
} from "../../interfaces/Content";
import config from "../../config";
import TableOfContents from "../../components/TableOfContents/TableOfContents";
import ReactPlayer from "../../components/ReactPlayer/ReactPlayer";
import Images from "../../components/Panel/Images";
import Availability from "../../components/Panel/Availability";
import CuePointsBar from "../../components/CuePointsBar/CuePointsBar";
import Button from "../../components/Button/Button";

import Information from "./Panels/Information";
import CuePoints from "./Panels/CuePoints";
import ContentMovie from "./Panels/ContentMovie";
import ContentEpisode from "./Panels/ContentEpisode";
import CollectionsContained from "./Panels/CollectionsContained";
import RelatedResources from "./Panels/RelatedResources";
import { useSelector } from "react-redux";

const initialContent = [
  { title: "Cue Points", id: "sectionCuepoints", offset: -200 },
  { title: "Video Info", id: "sectionContent", offset: -100 },
  { title: "Availability", id: "sectionAvailability", offset: -100 },
  { title: "Images", id: "sectionImages", offset: -100 },
  {
    title: "Collections",
    id: "sectionCollections",
    offset: -150,
  },
  // {
  //   title: "Ad Tag",
  //   id: "sectionInformation",
  //   offset: -150,
  // },
  { title: "Related Content", id: "sectionRelatedResources", offset: -300 },
];
const CONTENT = "/ops/content";
let player;
const ContentManagementDetailSeries = ({
  data,
  localesData,
  id,
  onSavedData,
}) => {
  const [currentData, setCurrentData] = useState<ContentDetailData>();
  const [currentLocalesData, setCurrentLocalesData] = useState<
    ContentLocalesData[]
  >([]);
  const [canShowPlayer, setCanShowPayer] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const [currentId, setCurrentId] = useState(id ? id : "");
  const [, setCurrentLocaleInformation] = useState<any>();
  const [currentImageData, setCurrentImages] = useState<any>();
  const [currentInformation, setCurrentInformation] = useState<any>();
  // Cue points:
  const [duration, setDuration] = useState(0);
  // Set the time in CuePointsBar and Player
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [addNewCuePoint, setAddNewCuePoint] = useState<boolean>(false);
  // Open the Cuepoints Panel with the selected cuepoint
  const [selectedCuePoint, setSelectedCuePoint] = useState<CuePointData | null>(
    null
  );
  // There are the cuepoints after the creation of one of them.
  const [editedCuePoints, setEditedCuePoints] = useState<CuePointData[]>();

  const [isEditingPanel, setIsEditingPanel] = useState(true);
  const permissions = useSelector((state:any) => state.permissions);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.CONTENT_MANAGEMENT_SCREEN, permissions);


  const onSuccess = (success) => {
    player = success.ref;
    player.on("durationchange", () => {
      setDuration(player.duration());
    });
  };

  useEffect(() => {
    if (player) {
      player.currentTime(currentTime);
    }
  }, [currentTime]);

  useEffect(() => {
    if (!!currentLanguage && !!currentLocalesData?.length && !!currentData) {
      setCurrentLocaleInformation(
        getDataLocaleInformation(
          getLocaleByLanguage(currentLocalesData, currentLanguage),
          currentLanguage
        )
      );
      setCurrentImages(
        getImageData(
          getLocaleByLanguage(currentLocalesData, currentLanguage)?.images,
          currentId,
          currentLanguage,
          data.images
        )
      );
      setCurrentInformation(
        getDataInformation(
          currentData,
          getLocaleByLanguage(currentLocalesData, currentLanguage),
          currentId
        )
      );
    }
  }, [currentLanguage]);

  useEffect(() => {
    if (!!data && !!id.length) {
      const nativeLanguage = getNativeLanguage(localesData) || DEFAULT_LANGUAGE;
      setCurrentData(data);
      setCurrentLocalesData(localesData);
      setCurrentId(id);
      setCurrentLanguage(nativeLanguage);

      if (!config.reactplayer_accountid?.length) {
        // eslint-disable-next-line no-console
        console.error(
          "Content Management - The player needs a REACT_APP_BC_ACCOUNT_ID"
        );
      } else {
        setCanShowPayer(true);
      }

      setCurrentInformation(
        getDataInformation(
          data,
          getLocaleByLanguage(localesData, currentLanguage),
          id
        )
      );
      setCurrentLocaleInformation(
        getDataLocaleInformation(
          getLocaleByLanguage(localesData, currentLanguage),
          currentLanguage
        )
      );
      setCurrentImages(
        getImageData(
          getLocaleByLanguage(localesData, currentLanguage)?.images,
          id,
          currentLanguage,
          data.images
        )
      );
    }
  }, [data, localesData, id]);

  const getDataAvailability = (contentData: ContentDetailData) => {
    const { start_date, end_date, geo, locations, accessControls } = contentData;
    
    return {
      start_date,
      end_date,
      locations,
      accessControls,
      geo
    };
  };

  const getDataCuepoints = (
    ContentData: ContentDetailData,
    selectedId: string
  ) => {
    const { cue_points } = ContentData;
    const tempCuepoints = editedCuePoints?.length
      ? editedCuePoints
      : cue_points;

    return {
      id: selectedId,
      cue_points: tempCuepoints?.sort((a, b) => a.time - b.time),
    };
  };

  const getDataContent = (
    ContentData: ContentDetailData,
    selectedId: string
  ) => {
    const {
      reference_id,
      source,
      publicUrl,
      series,
      season,
      name,
      display_name,
      tags,
      custom_fields,
      description,
      long_description,
      related_content,
      genre,
      cast,
      director,
      year,
      studio
    } = ContentData;

    return {
      id: selectedId,
      reference_id,
      source,
      publicUrl,
      series,
      season,
      name,
      display_name,
      tags,
      custom_fields,
      description,
      long_description,
      related_content,
      genre,
      cast,
      director,
      year,
      studio
    };
  };

  const getImageData = (
    imagesData: any[],
    selectedId: string,
    language: string,
    custom_fields: any
  ) => {
    return {
      images: imagesData,
      id: selectedId,
      language,
      custom_fields,
    };
  };

  const getDataInformation = (
    contentData: ContentDetailData,
    dataLocales: ContentLocalesData,
    selectedId: string
  ) => {
    const {
      syndicationDescription,
      campaignCode,
      translationStatus,
      genre,
      reference_id,
      industrySector,
      youtubePrivacy,
      tags,
      ticket,
      episodes,
      type,
      ad_keys,
    } = contentData || {};

    const { product, industry, topic } = dataLocales || {};

    return {
      product,
      topic,
      industry,
      translationStatus,
      genre,
      reference_id,
      industrySector,
      syndicationDescription,
      campaignCode,
      youtubePrivacy,
      tags,
      ticket,
      episodes,
      type,
      id: selectedId,
      ad_keys,
    };
  };

  const getDataLocaleInformation = (
    dataLocales: ContentLocalesData,
    language: string
  ) => {
    const {
      name,
      description,
      cta,
      keywords,
      industry,
      product,
      service,
      sessionTechLevel,
      sessionTheme,
      sessionType,
      topic,
    } = dataLocales || {};

    return {
      name,
      description,
      cta,
      language,
      keywords,
      industry,
      product,
      service,
      sessionTechLevel,
      sessionTheme,
      sessionType,
      topic,
    };
  };

  return (
    <>
      {currentData ? (
        <>
          <Flex className="ReactVideoPlayer-container">
            {canShowPlayer && currentData.video_id && (
              <ReactPlayer
                accountId={config.reactplayer_accountid}
                videoId={currentData?.video_id || ""}
                onSuccess={onSuccess}
              />
            )}
          </Flex>
          {currentData ? (
            <CuePointsBar
              cuePoints={
                editedCuePoints?.length
                  ? editedCuePoints
                  : currentData?.cue_points
              }
              duration={duration}
              selectedTime={currentTime}
              className="mt-6"
              onCuePointSelected={setSelectedCuePoint}
              onChangeTime={setCurrentTime}
            />
          ) : null}
          <span className="ml-11 mb-8">
            <Flex className="mt-4">
              <Flex flexWrap="wrap" style={{ flex: "auto" }}>
                <div className="cuePoints">
                {!addNewCuePoint && !selectedCuePoint &&  <Button
                    variant="primary"
                    text="Add a Cue Point"
                    onClick={() => {
                      setAddNewCuePoint(true);
                      setSelectedCuePoint(null)
                    }}
                    className="mb-4"
                    disabled={!hasWriteAccess}
                  />}
                </div>
                {addNewCuePoint || selectedCuePoint ? (
                  <CuePoints
                    idPanel="sectionCuepoints"
                    data={getDataCuepoints(currentData, currentId)}
                    addNew={addNewCuePoint}
                    duration={duration}
                    onCancel={() => {
                      setAddNewCuePoint(false);
                      setSelectedCuePoint(null);
                      setCurrentTime(0);
                      // onSavedData();
                    }}
                    selectedCuePoint={selectedCuePoint}
                    onChangeTime={setCurrentTime}
                    onSaveCuePoint={(cuePoints) => {
                      setEditedCuePoints(cuePoints);
                      onSavedData();
                    }}
                    onCuePointSelected={setSelectedCuePoint}
                  />
                ) : null}
                {currentData.content_type === CONTENT_EPISODE ||
                currentData.content_type === CONTENT_VIDEO ? (
                  <ContentEpisode
                    idPanel="sectionContent"
                    data={getDataContent(currentData, currentId)}
                    onEditModeChange={(editing) => {
                      // setIsEditingPanel(editing);
                      onSavedData();
                    }}
                    disabledEditing={!hasWriteAccess}
                  />
                ) : (
                  <ContentMovie
                    idPanel="sectionContent"
                    data={getDataContent(currentData, currentId)}
                  />
                )}
                <Availability
                  idPanel="sectionAvailability"
                  data={getDataAvailability(currentData)}
                  endpointRootPath={CONTENT}
                  onEditModeChange={(editing, isSavedData) => {
                    setIsEditingPanel(editing);
                    
                  }}
                  disableEditing={!hasWriteAccess}
                  onSavedData={onSavedData}
                />
                <Images
                  idPanel="sectionImages"
                  data={currentImageData}
                  endpointRootPath={CONTENT}
                  onEditModeChange={(editing, isSavedData) => {
                    // setIsEditingPanel(editing);

                    if (isSavedData) {
                      onSavedData();
                    }
                  }}
                  disableEditing={!hasWriteAccess}
                />
                <CollectionsContained
                  idPanel="sectionCollections"
                  data={currentData.contained_in_collections}
                  contentId={currentId}
                  onSavedData={onSavedData}
                  onEditModeChange={(editing, isSavedData) => {
                    // setIsEditingPanel(editing);

                    // if (isSavedData) {
                    //   onSavedData();
                    // }
                  }}
                  disabledEditing={!hasWriteAccess}
                />
                {/* <Information
                  idPanel="sectionInformation"
                  data={currentInformation ? currentInformation : {}}
                  onEditModeChange={(editing) => {
                    // setIsEditingPanel(editing);
                    onSavedData();
                  }}
                  disabledEditing={!hasWriteAccess}
                /> */}
                <RelatedResources
                  idPanel="sectionRelatedResources"
                  data={currentData.related_content}
                  contentId={currentId}
                  onSavedData={onSavedData}
                  onEditModeChange={(editing, isSavedData) => {
                    // setIsEditingPanel(editing);

                    // if (isSavedData) {
                    //   onSavedData();
                    // }
                  }}
                  disabledEditing={!hasWriteAccess}
                  // disabledEditing={isEditingPanel}
                />
              </Flex>
              <div className="ml-8 mr-8">
                <TableOfContents
                  className="TableOfContents"
                  data={initialContent}
                  isFixed={false}
                  isSticky
                  hasBorder
                />
              </div>
            </Flex>
          </span>
        </>
      ) : null}
    </>
  );
};

export default ContentManagementDetailSeries;
