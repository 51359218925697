import { useNavigate, useParams } from 'react-router';
import React, { FC, useEffect, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import * as ROUTES from '../../routes';
import { getUrlWithOptions } from '../../../utils';
// import COLORS from '../../../styles/_colors.export.scss';
import { Season } from '../../../interfaces/Content';
import { useApi } from '../../../hooks/useApi';
import TitleDescription from '../../../components/TitleDescription/TitleDescription';
import Table from '../../../components/Table/Table';
import Panel from '../../../components/Panel/Panel';
import GenericModal from '../../../components/Modals/GenericModal';
import TextInput from '../../../components/ControlledComponents/TextInput';
import Button from '../../../components/Button/Button';
import './Seasons.scss';
import './CollectionsContained.scss';

interface SeasonsProps {
  idPanel: string;
  data: Season[];
  disabledEditing?: boolean;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
}

const getTotalEpisodes = (data: any[]) => {
  return data.reduce((acc, current) => {
    acc += current?.numEpisodes ? current.numEpisodes : 0;

    return acc;
  }, 0);
};
const Seasons: FC<SeasonsProps> = ({ idPanel, data, disabledEditing, onEditModeChange }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { apiPut, isLoading } = useApi();
  const [newSeasonName, setNewSeasonName] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentData, setCurrentData] = useState<Season[]>(data);
  const [defaultData, setDefaultData] = useState<Season[]>(data);
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    setDefaultData(data);
    setCurrentData(data);
  }, [data]);

  const columns = [
    {
      Header: 'Name',
      accessor: (row) => [row.name, row.id], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [name, collectionId] = value;

        return (
          <TitleDescription
            title={name}
            description={collectionId}
            id={collectionId}
            onClick={(itemId) => {
              navigate(`../${ROUTES.CONTENT_MANAGEMENT}/edit/${itemId}`);
            }}
          />
        );
      },
    },
    {
      Header: 'Episodes',
      accessor: (row) => [row?.numEpisodes],
      Cell: ({ value }) => {
        return value[0] > 0 ? value[0] : '-';
      },
      className: 'episodes',
    },
    {
      Header: 'Status',
      accessor: 'published',
      Cell: ({ value }) => (
        <div></div>
        // <Icon name={value ? ICONS.CHECK2 : ICONS.WARNING} color={value ? COLORS.green : COLORS.burgundy} />
      ),
      className: 'status',
    },
    {
      Header: 'Start Date',
      accessor: (row) => [row?.startDate],
    },
    {
      Header: 'End Date',
      accessor: (row) => [row?.endDate],
    },
  ];

  const onSaveInformation = async () => {
    const orderedSeasons = currentData.map((season: Season, index) => {
      season.number = index;

      return season;
    });

    const response = await apiPut(getUrlWithOptions(`/content/${id}/seasons`), {
      body: orderedSeasons,
    });

    if (response?.data) {
      setShowAddModal(false);
      setCurrentData(response.data);
      setEditing(false);
      onEditModeChange?.(!disabledEditing, true);
    }
  };
  const addSeason = () => {
    setCurrentData([...currentData, { name: newSeasonName }]);
    setShowAddModal(false);
  };

  const onDragRow = (reorderedRows: any[]) => setCurrentData(reorderedRows);
  const onClickDeleteRow = (id: string, currentItem: any) => {
    setCurrentData((prev) => {
      let remainingItems;

      if (id?.length) {
        remainingItems = prev.filter((item: any) => item.id !== id);
      } else {
        remainingItems = prev.filter((item: any) => item.name !== currentItem.name);
      }

      return remainingItems;
    });
  };

  const restoreData = () => {
    setCurrentData(defaultData);
  };

  return (
    <div className="Seasons w-100">
      <GenericModal show={showAddModal} className="Seasons-confirmation-modal w-50">
        <>
          <Panel title="Add season" actions={<></>} className="">
            <div className="ml-4 mt-8 mr-4">
              <p className="title">Season name *</p>
              <TextInput value={newSeasonName} onChange={setNewSeasonName} className="w-100 mb-12" />
              <Flex justifyContent="center" className="mt-12 mb-4">
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    setShowAddModal(false);
                    setNewSeasonName('');
                  }}
                />
                <Button
                  variant="primary"
                  text="Save"
                  onClick={() => {
                    addSeason();
                    setNewSeasonName('');
                  }}
                  disabled={isLoading || newSeasonName.length < 3 ? true : false}
                />
              </Flex>
            </div>
          </Panel>
        </>
      </GenericModal>
      <div id={idPanel}>
        <Panel
          title="Seasons"
          actions={
            <>
              {editing ? (
                <>
                  <Button
                    variant="secondary"
                    text="Cancel"
                    onClick={() => {
                      restoreData();
                      setEditing(false);
                      onEditModeChange?.(!disabledEditing);
                    }}
                  />
                  <Button variant="primary" text="Save" onClick={onSaveInformation} disabled={isLoading} />
                </>
              ) : (
                <Button
                  variant="primary"
                  text="Edit"
                  onClick={() => {
                    setEditing(!editing);
                    onEditModeChange?.(!disabledEditing);
                  }}
                  disabled={disabledEditing}
                />
              )}
            </>
          }
        >
          <>
            <div className="ml-4">
              <p className="title">Total episodes</p>
              <div>{currentData?.length ? getTotalEpisodes(currentData) : 0}</div>
            </div>
            <div className="ml-4">
              <p className="title">Total seasons</p>
              <div>{currentData?.length ? currentData?.length : 0}</div>
            </div>
            {
              <Table
                data={currentData}
                columns={columns}
                className="mt-4"
                onClickDeleteRow={onClickDeleteRow}
                onDragRow={onDragRow}
                hasDeletion={editing}
                hasDragging={editing}
              />
            }
            {editing ? (
              <Button
                variant="primary"
                text="+ Add season"
                className="mt-6"
                onClick={() => {
                  setShowAddModal(true);
                }}
              />
            ) : null}
          </>
        </Panel>
      </div>
    </div>
  );
};

export default Seasons;
