/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./DynamicStringDetails.scss";
import SubHeader from "../../components/SubHeader/SubHeader";
import { Flex, Icon } from "@brightcove/studio-components";
import { useNavigate, useParams } from "react-router";
import * as ROUTES from "../routes";
import Panel from "../../components/Panel/Panel";
import SimpleSelect from "../../components/ControlledComponents/SimpleSelect";
import TextInput from "../../components/ControlledComponents/TextInput";
import { useApi } from "../../hooks/useApi";
import Button from "../../components/Button/Button";
import { ModuleName, getModulePermissions, getUrlWithOptions } from "../../utils";
import { Toaster } from "../../utils";
import CONSTANTS from "../../constants";
import { DeleteConfirmationModal, withModal } from "../../components/Modals";
import { useSelector } from "react-redux";

const DEFAULT_SCOPE = CONSTANTS.DYNAMIC_STRINGS.SCOPES[0];
const DEFAULT_LANG = CONSTANTS.DYNAMIC_STRINGS.LANGS[0];

const DeleteButton = withModal(Button, DeleteConfirmationModal);

const DynamicStringDetails: React.FunctionComponent<any> = ({ moduleName }) => {
  const { id } = useParams();
  const resource = "/ops/dynamic_strings";
  const navigate = useNavigate();
  const { apiGet, apiPut, apiPost, apiDelete } = useApi();
  const [currentKeyTitle, setCurrentKeyTitle] = useState("");
  const [currentKey, setCurrentKey] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [currentId, setCurrentId] = useState<string>(id ? id : "");
  const [scope] = useState(CONSTANTS.DYNAMIC_STRINGS.SCOPES);
  const [currentScope, setScopeValue] = useState("");
  const [lang] = useState(CONSTANTS.DYNAMIC_STRINGS.LANGS);
  const [currentLang, setLangValue] = useState("");
  const [editing, setEditing] = useState(!id);
  const [stringData, setStringData] = useState<any>({});
  const permissions = useSelector((state:any) => state.permissions);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.DYNAMIC_STRINGS_SCREEN, permissions);

  const scopeOptions = CONSTANTS.DYNAMIC_STRINGS.SCOPES.map((value) => {
    return {
      label: value,
      value: value,
    };
  });

  const langOptions = CONSTANTS.DYNAMIC_STRINGS.LANGS.map((value) => {
    return {
      label: value,
      value: value,
    };
  });
  useEffect(() => {
    if (currentId?.length) {
      getData();
    }
  }, []);

  const getData = async () => {
    const { data } = await apiGet(
      getUrlWithOptions(`${resource}/${currentId}`)
    );
    // console.log("data" + JSON.stringify(data));
    setCurrentKeyTitle(data?.label);
    setCurrentKey(data?.label);
    setCurrentValue(data?.value);
    setScopeValue(data?.scope);
    setLangValue(data?.lang);
    setStringData(data);
  };

  const onSubmit = async () => {
    let body;

    if (id?.length) {
      body = {
        label: currentKey,
        value: currentValue,
        scope: currentScope || DEFAULT_SCOPE,
        lang: currentLang || DEFAULT_LANG
      };

      console.log('body', body);

      const response = await apiPut(getUrlWithOptions(`${resource}/${id}`), {
        body,
      });

      if (response?.data?.label?.length) {
        Toaster.success({
          message: "Updated string saved",
        });
        setEditing(false);
        setCurrentKeyTitle(response?.data?.label);
        setCurrentKey(response?.data?.label);
        setCurrentValue(response?.data?.value);
        setScopeValue(response?.data?.scope);
        setLangValue(response?.data?.lang);
        setStringData(response?.data);
      }
    } else {
      body = {
        label: currentKey,
        value: currentValue,
        scope: currentScope || DEFAULT_SCOPE,
        lang: currentLang || DEFAULT_LANG
      };

      const response = await apiPost(getUrlWithOptions(`${resource}`), {
        body,
      });
      const createId = response?.data?.id;

      if (createId?.length) {
        setCurrentId(createId);
        navigate(`../${ROUTES.STRINGS}`);
      }
    }
  };

  const onDelete = async () => {
    const { error } = await apiDelete(
      getUrlWithOptions(`/ops/dynamic_strings/${currentId}`)
    );

    if (!error) {
      navigate(`../${ROUTES.STRINGS}`);
      // goBack();
    }
  };

  return (
    <div className="main">
      <SubHeader
        icon={<Icon name="ARROW_RIGHT" rotate="180" />}
        title={
          !!currentKeyTitle?.length && !!currentId?.length
            ? currentKeyTitle
            : "New Strings"
        }
        subtitle={"Back to Dynamic Strings"}
        detailMode
        onBack={() => navigate(`../${ROUTES.STRINGS}`)}
        className="pl-5"
        actions={
          <DeleteButton
            variant="secondary"
            disabled={!hasWriteAccess}
            modalProps={{
              title: "Delete",
              children: (
                <>
                  Permanently delete 1 Dynamic String from the system? This
                  cannot be undone.
                </>
              ),
            }}
            onModalConfirm={onDelete}
          >
            Delete
          </DeleteButton>
        }
      />
      <div className="stringDetails">
        <Panel
          className="mt-12"
          title={"String Details"}
          actions={
            <>
              {!editing && !!currentId?.length ? (
                <Button
                  variant="secondary"
                  text={"Edit"}
                  onClick={() => {
                    setEditing(true);
                  }}
                  disabled={!hasWriteAccess}
                />
              ) : (
                <>
                  <Button
                    variant="secondary-invert"
                    text="Cancel"
                    onClick={() => {
                      if (editing && !!currentId) {
                        setEditing(false);
                        setCurrentKey(stringData?.label);
                        setCurrentValue(stringData?.value);
                        setScopeValue(stringData?.scope);
                        setLangValue(stringData?.lang);
                      } else {
                        navigate(`../${ROUTES.STRINGS}`);
                      }
                    }}
                  />
                  <Button
                    variant="primary"
                    text={currentId ? "Save" : "Create"}
                    onClick={onSubmit}
                    disabled={!currentKey.length}
                  />
                </>
              )}
            </>
          }
        >
          <>
            <Flex>
              <Flex {...{ flexDirection: "column" }}>
                <Flex {...{ flexDirection: "row" }}>
                  <div className="stringDetails w-100">
                    <p className="stringDetails-title">Key *</p>
                    {editing ? (
                      <TextInput
                        value={currentKey}
                        onChange={setCurrentKey}
                        className="stringDetails-box"
                        disabled={false}
                      />
                    ) : (
                      <div className="stringDetails-content">{currentKey}</div>
                    )}
                  </div>
                  <div className="stringDetails w-100">
                    <p className="stringDetails-title">Scope *</p>
                    {editing ? (
                      <SimpleSelect
                        className="stringDetails-select"
                        value={currentScope}
                        options={scopeOptions as any}
                        onChange={(value) => {
                          setScopeValue(value);
                        }}
                      />
                    ) : (
                      <div className="stringDetails-content">
                        {currentScope}
                      </div>
                    )}
                  </div>
                </Flex>
                <Flex className="pt-5">
                  <div className="stringDetails w-100">
                    <p className="stringDetails-title">Value *</p>
                    {editing ? (
                      <textarea
                        className="stringDetails-body"
                        value={currentValue}
                        onChange={(e) => {
                          setCurrentValue(e.target.value);
                        }}
                      ></textarea>
                    ) : (
                      <div className="stringDetails-textArea">
                        {currentValue}
                      </div>
                    )}
                  </div>
                  <div className="stringDetails w-100">
                    <p className="stringDetails-title">Language *</p>
                    {editing ? (
                      <SimpleSelect
                        className="stringDetails-select"
                        value={currentLang}
                        options={langOptions as any}
                        onChange={(value) => {
                          setLangValue(value);
                        }}
                      />
                    ) : (
                      <div className="stringDetails-content">
                        {currentLang}
                      </div>
                    )}
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </>
        </Panel>
      </div>
    </div>
  );
};

export default DynamicStringDetails;
