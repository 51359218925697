/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { Flex } from "@brightcove/studio-components";

import { ModuleName, getModulePermissions, getSecondFromTime, secondsToTimeString } from "../../../utils";
import { getUrlWithOptions } from "../../../utils";
// import COLORS from '../../../styles/_colors.export.scss';
import { CuePointData } from "../../../interfaces/Content";
import { useApi } from "../../../hooks/useApi";
import SimplePager from "../../../components/SimplePager/SimplePager";
import Panel from "../../../components/Panel/Panel";
import "./Cuepoints.scss";
import DeleteConfirmationModal from "../../../components/Modals/DeleteConfirmationModal";
import TextInput from "../../../components/ControlledComponents/TextInput";
import SimpleSelect from "../../../components/ControlledComponents/SimpleSelect";
import Button from "../../../components/Button/Button";
import { useSelector } from "react-redux";

interface CuePointsProps {
  idPanel?: string;
  data: any;
  addNew: boolean;
  selectedCuePoint: any;
  duration: number;
  onCancel: () => void;
  onChangeTime: (val: number) => void;
  onSaveCuePoint: (val: CuePointData[]) => void;
  onCuePointSelected: (val: any) => void;
}

const CuePoints: FC<CuePointsProps> = ({
  idPanel,
  data,
  addNew,
  duration,
  selectedCuePoint,
  onCancel,
  onChangeTime,
  onSaveCuePoint,
  onCuePointSelected
}) => {
  const { apiPatch } = useApi();
  const { id } = data;
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [repeatedCheck, setRepeatedCheck] = useState<boolean>(true);
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentHours, setCurrentHours] = useState(0);
  const [currentMinutes, setCurrentMinutes] = useState(0);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const [currentName, setCurrentName] = useState<string>("");
  const [currentMetadata, setCurrentMetadata] = useState<string>("");
  const [currentUrl, setCurrentUrl] = useState<string>("");
  const [currentType, setCurrentType] = useState<string>("AD");
  const [index, setIndex] = useState(0);
  const [cuePoints, setCuePoints] = useState<any[]>([]);
  const [selectedTime, setSelectedTime] = useState(selectedCuePoint?.time)
  const permissions = useSelector((state:any) => state.permissions);

  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.CONTENT_MANAGEMENT_SCREEN, permissions);

  useEffect(() => {
    if (cuePoints?.length) {
      if (cuePoints[index]?.time > 60 * 60) {
        const [hour, minutes, seconds] = secondsToTimeString(
          cuePoints[index].time
        ).split(":");

        setCurrentHours(parseInt(hour, 10));
        setCurrentMinutes(parseInt(minutes, 10));
        setCurrentSeconds(parseInt(seconds, 10));
      } else {
        const [minutes, seconds] = secondsToTimeString(
          cuePoints[index]?.time
        ).split(":");

        setCurrentMinutes(parseInt(minutes, 10));
        setCurrentSeconds(parseInt(seconds, 10));
      }

      setCurrentName(cuePoints[index]?.name);
      setCurrentMetadata(cuePoints[index]?.metadata);
      // setCurrentUrl(cuePoints[index]?.url);
      setCurrentType(cuePoints[index]?.type ? cuePoints[index]?.type : "AD");
    }
  }, [index, cuePoints]);
  // add new cuepoint
  useEffect(() => {
    if (addNew) {
      if (data.cue_points?.length) {
        setCuePoints([...data.cue_points, {}]);
        setIndex(data.cue_points.length);
      } else {
        setCuePoints([]);
        setIndex(0);
      }
    }
  }, [addNew]);
  // show after select a cuepoint from CuePointBar
  useEffect(() => {
    if (selectedCuePoint && !!data.cue_points?.length) {
      const currentIndex = data.cue_points.findIndex(
        (cue_point) => cue_point.time === selectedCuePoint.time
      );

      setCuePoints(data.cue_points);
      setIndex(currentIndex);
    }
  }, [selectedCuePoint, data.cue_points]);
  useEffect(() => {
    if (currentHours || currentMinutes || currentSeconds) {
      onChangeTime(
        getSecondFromTime(currentHours, currentMinutes, currentSeconds)
      );
    }

    const time = getSecondFromTime(
      currentHours,
      currentMinutes,
      currentSeconds
    );

    repeatedCuepoint(time);
    requiredFields();
    isValidCuepoint(time);
  }, [
    currentHours,
    currentMinutes,
    currentSeconds,
    currentName,
    currentMetadata,
    currentUrl,
  ]);

  const requiredFields = () => {
    const currentTime = getSecondFromTime(
      currentHours,
      currentMinutes,
      currentSeconds
    );

    const shouldDisableSave = [
      cuePoints[index]?.name === currentName,
      // cuePoints[index]?.metadata === currentMetadata,
      // cuePoints[index]?.url === currentUrl,
      cuePoints[index]?.type === currentType,
    ];

    if (cuePoints[index]?.time > 60 * 60) {
      const [hour, minutes, seconds] = secondsToTimeString(
        cuePoints[index].time
      ).split(":");

      shouldDisableSave.push(
        parseInt(hour, 10) === currentHours,
        parseInt(minutes, 10) === currentMinutes,
        parseInt(seconds, 10) === currentSeconds
      );
    } else {
      const [minutes, seconds] = secondsToTimeString(
        cuePoints[index]?.time
      ).split(":");

      shouldDisableSave.push(
        parseInt(minutes, 10) === currentMinutes,
        parseInt(seconds, 10) === currentSeconds
      );
    }

    if (
      currentTime &&
      currentName?.length
      // !!currentMetadata?.length &&
      // !!currentUrl?.length &&
      // !!shouldDisableSave.every((item) => item)
    ) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
      // setShowMessage(false);
      setErrorMessage("Data missing in required fields.");
    }
  };

  const repeatedCuepoint = (time: number) => {
    if (time !== selectedTime) {
    if (cuePoints.find((cp) => cp.time === time) ) {
      // setShowMessage(true);
      setErrorMessage("There is already a cue point at the timecode. Please select a different timecode.");
      setRepeatedCheck(true);
      setShowDeleteButton(true);
    } else {
      setShowMessage(false);
      setErrorMessage("");
      setShowDeleteButton(false);
      setRepeatedCheck(false);
    }
  }
  };

  const isValidCuepoint = (time: number) => {
    if (time > duration) {
      setShowMessage(true);
      setErrorMessage("Cue point timecode should be less than video duration");
      setDisabledSave(true);
    }
  };

  const onSaveInformation = async () => {
    // setShowMessage(false);
    // the unique property of each cuepoint is the time, so we filter out the edited cuepoint
    const filteredCuepoints = cuePoints.filter(
      (cp) => selectedCuePoint?.time !== cp.time
    );

    // replace the edited cuepoint with the updated version of it
    const editedCuepoints = [
      ...filteredCuepoints,
      {
        metadata: currentMetadata,
        name: currentName,
        type: currentType,
        time: getSecondFromTime(currentHours, currentMinutes, currentSeconds),
        //url: currentUrl,
      },
    ];
    if (disabledSave === false && repeatedCheck === false) {

      const body = {
        cue_points: editedCuepoints,
      };
      const response = await apiPatch(getUrlWithOptions(`/ops/content/${id}`), {
        body,
      });

      if (response?.data) {
        setCuePoints(editedCuepoints);
        setIndex(editedCuepoints.length - 1);
        onSaveCuePoint(editedCuepoints);
      }

      onCancel();
    } else {
      setShowMessage(true);
      // setErrorMessage(errorMessage);
    }
  };

  const onDeleteCuePoint = async () => {
    const allCuePoints = [...cuePoints];
    const filteredCuepoints = allCuePoints.filter(
      (cp) => cp.time !== cuePoints[index].time
    );

    const body = {
      cue_points: filteredCuepoints,
    };
    const response = await apiPatch(getUrlWithOptions(`/ops/content/${id}`), {
      body,
    });

    if (response?.data) {
      setCuePoints(filteredCuepoints);
      setIndex(filteredCuepoints.length - 1);
      onSaveCuePoint(filteredCuepoints);
      setCurrentSeconds(0);
    }

    onCancel();
    setShowDeleteModal(false);
  };

  const onPagerChange = (index) => {
    setIndex(index);
    setSelectedTime(cuePoints[index].time)
    onCuePointSelected(cuePoints[index]);
  }

  return (
    <>
      <DeleteConfirmationModal
        title="Delete Cue Point"
        show={showDeleteModal}
        onDelete={onDeleteCuePoint}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
      >
        <>
          Are you sure you want to delete this cue point? This action cannot be
          undone.
        </>
      </DeleteConfirmationModal>

      <div id={idPanel} className="CuePoints w-100">
        <Panel
          title="Cue Point"
          actions={
            <>
              {selectedCuePoint && (
                <Button
                  variant="secondary"
                  text="Delete"
                  onClick={() => setShowDeleteModal(true)}
                  disabled={!hasWriteAccess}
                />
              )}
              <Button
                variant="secondary-invert"
                text="Cancel"
                onClick={() => {
                  const tmpCuepoint = cuePoints.slice(0, cuePoints.length);

                  setIndex(cuePoints.length - 1);
                  setCuePoints(tmpCuepoint);
                  onCancel();
                }}
                disabled={!hasWriteAccess}
              />
              <Button
                variant="primary"
                text="Save"
                onClick={onSaveInformation}
                disabled={!hasWriteAccess}
              />
            </>
          }
        >
          <>
            <>
              <Flex>
                <div className="ml-4">
                  <p className="title">Timecode (HH:MM:SS) *</p>
                  <Flex flexDirection="row" alignContent="center">
                    <TextInput
                      value={currentHours}
                      onChange={(val) => {
                        const tmpVal = val?.length ? val : "0";

                        if (parseInt(tmpVal, 10) <= 60) {
                          setCurrentHours(parseInt(tmpVal, 10));
                        }
                        setShowMessage(false);
                      }}
                      className="timecode"
                      type="text"
                    />

                    <TextInput
                      value={currentMinutes}
                      onChange={(val) => {
                        const tmpVal = val?.length ? val : "0";

                        if (parseInt(tmpVal, 10) <= 60) {
                          setCurrentMinutes(parseInt(tmpVal, 10));
                        }
                        setShowMessage(false);
                      }}
                      className="ml-2 timecode"
                      type="text"
                    />
                    <TextInput
                      value={currentSeconds}
                      onChange={(val) => {
                        const tmpVal = val?.length ? val : "0";

                        if (parseInt(tmpVal, 10) <= 60) {
                          setCurrentSeconds(parseInt(tmpVal, 10));
                        }
                        setShowMessage(false);
                      }}
                      className="ml-2 timecode"
                      type="text"
                    />
                  </Flex>
                </div>
                <div className="ml-6 ">
                  <p className="title">Type</p>
                  <SimpleSelect
                    className="select-input-sm"
                    value={currentType}
                    onChange={setCurrentType}
                    options={[
                      { label: "Ad", value: "AD" },
                      { label: "Code", value: "CODE" },
                    ]}
                  />
                </div>
                <div className="ml-8">
                  <p className="title">Name *</p>
                  <TextInput
                    value={currentName?.length ? currentName : ""}
                    onChange={(udatedName)=>{
                      setCurrentName(udatedName);
                      setShowMessage(false);}}
                  />
                </div>
                {showDeleteButton ? (
                  <div
                    className="ml-8 mt-8"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    {/* <Icon name={ICONS.TRASHCAN} color={COLORS.green} className="button-trash" /> */}
                  </div>
                ) : null}
              </Flex>
              <Flex>
                <div className="ml-4 mt-4">
                  <p className="title">Metadata</p>
                  <TextInput
                    value={currentMetadata?.length ? currentMetadata : ""}
                    onChange={setCurrentMetadata}
                    className="metadata"
                  />
                </div>
              </Flex>
            </>
            {showMessage ? (
              <div className="mt-4 ml-4 error-message">{errorMessage}</div>
            ) : null}
            {selectedCuePoint && (
              <SimplePager
                length={cuePoints?.length ? cuePoints.length : 0}
                onChange={onPagerChange}
                index={index}
                nextText="Next >"
                previousText="< Previous"
              />
            )}
          </>
        </Panel>
      </div>
    </>
  );
};

export default CuePoints;
