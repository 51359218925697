/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import _, { flowRight as compose } from "lodash";
import { Flex, Icon, Thumbnail } from "@brightcove/studio-components";

import * as ROUTES from "../routes";
import { ModuleName, Toaster, formatDate, getModulePermissions } from "../../utils";
import { getPageCount, getUrlWithOptions } from "../../utils";
// import COLORS from '../../styles/_colors.export.scss';
import useGoBack from "../../hooks/useGoBack";
import { useApi } from "../../hooks/useApi";
import AddBodyClasses from "../../helpers/AddBodyClasses";
import { withRouter } from "../../components/withRouter/withRouter";
import TitleDescription from "../../components/TitleDescription/TitleDescription";
import TableOfContents from "../../components/TableOfContents/TableOfContents";
import Table from "../../components/Table/Table";
import SubHeader from "../../components/SubHeader/SubHeader";
import PublicationStateButtons from "../../components/PublicationStateButtons/PublicationStateButtons";
import EditPanel from "../../components/Panel/EditPanel";
import AddItemModal from "../../components/Modals/AddItemModal";
import { DeleteConfirmationModal, withModal } from "../../components/Modals";
import Loading from "../../components/Loading/Loading";
// import Counter from '../../components/Counter/Counter';
import TextInput from "../../components/ControlledComponents/TextInput";
import SimpleSelect from "../../components/ControlledComponents/SimpleSelect";
import { RadioGroup } from "../../components/ControlledComponents/RadioGroup";
import Button from "../../components/Button/Button";
import "./CollectionsDetail.scss";
import Counter from "../../components/Counter/Counter";
import AvailabilityDate from "../../components/Panel/AvailibilityDate";
import { TextArea } from "../../components/ControlledComponents";
import { saveAs } from 'file-saver'
import { useSelector } from "react-redux";
import { SVGImage } from "../../assets/images";

const typeOptions = [
  { label: "Manual", value:"Manual"},
  { label: "Dynamic", value:"Dynamic"},
  { label: "Series", value:"Series"},
  { label: "Season", value:"Season"},
  { label: "Featured Carousel", value:"Featured"}
];

const DeleteButton = withModal(Button, DeleteConfirmationModal);
const AddCollectionItemButton = withModal(Button, AddItemModal);
const commonColumns = [
  {
    accessor: (row) => ({ thumbnail: row.image || row.images?.thumbnail?.src, id: row.id }),
    id: "images",
    Cell: ({ value }) => (
      // <div>{JSON.stringify(value.image?.thumbnail?.src)}</div>,
      <Thumbnail
        className="Collections-detail-thumbnail"
        src={
          value.thumbnail
        }
      />
    ),
    className: "thumbnail-cell",
  },
  {
    Header: "Collection Item",
    id: "name",
    accessor: (row) => ({ name: row.display_name || row.name, id: row.id }),
    Cell: ({ value }) => (
      <div style={{width:"200px", textOverflow:"ellipsis", overflowWrap: "break-word"}}>
      <TitleDescription
        className="pb-3"
        title={value.name}
        description={value.id}
        type={"noClickable"}
      />
      </div>
    ),
  },
  {
    Header: "Type",
    accessor: (row) => ({ type: row.collection_type || row.content_type }),
    Cell: ({ value }) => (
      <div>{value.type}</div>
    ),
    className: "type",
  },
  {
    Header: "Status",
    accessor: 'state',
    Cell: ({ value }) => (
      <Icon
        name={ "check2" }
        color={ "#3886F7" }
      />
    ),
    className: "status",
  },
];
const collectionItemsColumns = [
  {
    Header: "Start Date",
    accessor: "start_date",
    Cell: ({ value }) => <>{formatDate(value)}</>,
    className: "date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
    Cell: ({ value }) => <>{formatDate(value)}</>,
    className: "date",
  },
];

const contentItemsColumns = [
  {
    Header: "Content",
    accessor: (row) => ({ thumbnail: row.image, id: row.id }),
    id: "image",
    Cell: ({ value }) => (
      // <div>{JSON.stringify(value)}</div>
      <Thumbnail
        className="Collections-detail-thumbnail"
        src={
          value.thumbnail
        }
      />
    ),
    className: "thumbnail-cell",
  },
  {
    Header: "Name/Title",
    id: "name",
    accessor: (row) => ({ name: row.display_name || row.name, id: row.id }),
    Cell: ({ value }) => (
      <TitleDescription
        className="pb-3"
        title={value.name}
        description={value.id}
        type={"noClickable"}
      />
      // <div>{value}</div>
    ),
    sortable: true,
  },
  {
    Header: "Type",
    accessor: (row) => ({ type: row.collection_type || row.content_type }),
    Cell: ({ value }) => (
      <div>{value.type}</div>
    ),
    className: "type",
    sortable: true,
  },
  {
    Header: "Status",
    accessor: 'status',
    Cell: ({ value }) => (
      <Icon
        name={ "check2"}
        color={"blue" }
      />
    ),
    className: "status",
    sortable: true,
  },
    {
      Header: "Created",
      accessor: "created_at",
      Cell: (obj: any) => (
        <>
          <div>{formatDate(obj.value)}</div>
          {/* <div className="subtitle">{obj.row.original.created_at}</div> */}
        </>
      ),
      sortable: true,
      className: "date",
    },
    {
      Header: "Updated",
      accessor: "updated_at",
      Cell: (obj: any) => (
        <>
          <div>{formatDate(obj.value)}</div>
          {/* <div className="subtitle">{obj.row.original.updated_at}</div> */}
        </>
      ),
      sortable: true,
      className: "date",
    },
  ];

const CollectionsDetail = ({ params: { id: collectionId } }) => {
  const { goBackLabel, goBack } = useGoBack(
    ROUTES.COLLECTIONS,
    "Back to Collections"
  );
  const contentFilters = [
    { name: "All Content", id: "", image: SVGImage.Content },
    { name: "Videos", id: "content_type:EpisodeContent", iconName: "FILM" },
    { name: "Series", id: "collection_type:Series", iconName: "folder" },
    { name: "Seasons", id: "collection_type:Season", iconName: "folder" },
  ];
  const sortOptions = [
    { label: "Name (A-Z)", value: "AZ" },
    { label: "Name (Z-A)", value: "ZA" },
    { label: "Updated At (Oldest to Newest)", value: "updated_at:asc" },
    { label: "Updated At (Newest to Oldest)", value: "updated_at:dsc" },
    { label: "Created At (Oldest to Newest)", value: "created_at:asc" },
    { label: "Created At (Newest to Oldest)", value: "created_at:dsc" },
  ];
  // const fieldOptions = [
  //   { label: 'Series', values: 'SeriesContent' },
  //   { label: 'Season', values: 'SeasonContent' },
  //   { label: 'Episode', values: 'EpisodeContent' },
  //   { label: 'Movie', values: 'MovieContent' },
  // ];
  const ALL_CONTENT = "allContent";
  const FILTER_DEFAULT = "";
  const includeOptions = [{ label: "Tags", value: "tag" }];
  const radioOptions = [
    { label: "Match Any", value: "contains" },
    { label: "Match All", value: "exact" },
  ];
  const { apiGet, apiPatch, apiDelete, apiPut } = useApi();
  const [collection, setCollection] = useState<any>({});
  const [collectionItemsData, setCollectionItemsData] = useState<any>({});
  const [collectionName, setCollectionName] = useState<string>("");
  const [trailerId, setTrailerId] = useState<string>("");
  const [collectionURL, setCollectionURL] = useState<string>("");
  const [description, setDecription] = useState<string>("");
  const [collectionItems, setCollectionItems] = useState<any[]>([]);
  const [collectionRules, setCollectionRules] = useState<any[]>([]);
  const [disableEditing, setDisableEditing] = useState(true);
  const [contentItemsData, setContentItemsData] = useState<any>({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [contentSort, setContentSort] = useState<string>("");
  const [collectionSort, setCollectionSort] = useState<string>(
    sortOptions[0].value
  );
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [maxNumItems, setMaxNumItems] = useState(50);
  const [contentPageSize, setContentPageSize] = useState(20);
  const [contentPageIndex, setContentPageIndex] = useState(0);
  const [contentTotalCount, setContentTotalCount] = useState(0);
  const [collectionPageSize, setCollectionPageSize] = useState(20);
  const [collectionPageIndex, setCollectionPageIndex] = useState(0);
  const [collectionTotalCount, setCollectionTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [refetch, ] = useState(false);
  const [, setValue] = useState("");
  const COLLECTIONS_ENDPOINT = "/ops/collections";
  const collectionUrl = getUrlWithOptions(
    `${COLLECTIONS_ENDPOINT}/${collectionId}`
  );
  const permissions = useSelector((state:any) => state.permissions);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.CONTENT_MANAGEMENT_SCREEN, permissions);
  const [selectedType, setSelectedType] = useState("");
  const [currentFilter, setCurrentFilter] = useState(FILTER_DEFAULT);

  useEffect(() => {
    (async () => {
      const [collectionResponse] = await Promise.all([
        fetchCollection(),
      ]);
      if (collectionResponse?.data) {
        const { name, rules, image, trailerId, collection_type, description } = collectionResponse.data;

        setCollection(collectionResponse.data);
        setCollectionName(name);
        setCollectionURL(image);
        setTrailerId(trailerId);
        setSelectedType(collection_type);
        setCollectionRules(formatRules(rules));
        setDecription(description)
      }


      const { data: itemsData } = await fetchCollectionItems();

        if (itemsData) {
          const { items, totalCount } = itemsData;

          setCollectionItemsData(itemsData);
          setCollectionItems(itemsData);
          setCollectionTotalCount(itemsData?.length);
        }

      setLoading(false);
    })();
  }, [refetch, loading]);

  useEffect(() => {
   getData()

  }, [
    searchQuery,
    contentSort,
    currentFilter,
    contentPageIndex,
    contentPageSize
  ]);

  const getData = async () => {
    const { data } = await apiGet(
      getUrlWithOptions(
        "/ops/all",
        searchQuery,
        contentSort,
        currentFilter,
        contentPageIndex,
        contentPageSize
      )
    );

    setContentItemsData(data?.results);
      if (data?.totalCount <= contentPageIndex * contentPageSize  && searchQuery) {
        setContentTotalCount(data?.totalCount);
        setContentPageIndex(0)
      } else if (data?.totalCount > (contentPageIndex * contentPageSize) && searchQuery){
        setContentTotalCount(data?.totalCount);
        let pageCount: number = Math.ceil(data?.totalCount / contentPageSize);
        pageCount<= contentPageIndex?   setContentPageIndex(pageCount) : setContentPageIndex(contentPageIndex);
      }
      else {
        setContentTotalCount(data?.totalCount);
      }
  }
  const fetchCollection = async () => {
    return apiGet(collectionUrl);
  };

  const fetchCollectionItems = async () => {
    return apiGet(`${collectionUrl}/items`);
  };

  const updateCollection = async (requestBody): Promise<any> => {
    return apiPatch(collectionUrl, { body: requestBody });
  };

  const getCollectionPublishingStatus = async () => {
    return apiGet(`${collectionUrl}`);
  };

  const onUpdateCollectionInformation = async (): Promise<any> => {
    if (collectionName) {
      const { data, error } = await updateCollection({ name: collectionName, image: collectionURL, trailerId: trailerId, collection_type: selectedType, description: description });

      if (error) {
        return { error };
      }

      if (data) {
        setCollection(data);
      }

      toggleDisableEditing();
    }

    return {};
  };

  const onUpdateCollectionItems = async (): Promise<any> => {
    if (isDynamicCollection()) {
      const { data, error } = await updateCollection({
        rules: collectionRules.map((rule) => {
          delete rule.id;

          return rule;
        }),
        maxNumItems,
        sort: collectionSort,
      });

      if (error) {
        return { error };
      }

      if (data) {
        setCollection(data);
        setCollectionRules(formatRules(data?.rules))

        // once collection rules are updated, get new list of collection items
        const { data: itemsData } = await fetchCollectionItems();

        if (itemsData) {
          const { items, totalCount } = itemsData;
          setCollectionItemsData(itemsData);
          setCollectionItems(itemsData);
          setCollectionTotalCount(itemsData?.length);
        }
      }
    } else {

      let bodyString : any = [];
      collectionItems.map((item) => {
        if (item?.collection_type === "Dynamic") {
          Toaster.error({
            message:"Dynamic collections cannot be added to other collection types"
          });
        }
          // return
        // } else {
          bodyString.push({
            item_type: item?.collection_type ? "Collection" : 'Content',
            item_id: item?.video_id || item?.id
          })
      // }
      })

      const { data, error } = await apiPut(`${collectionUrl}/items`, {
        body: bodyString,
      });

      if (error) {console.log("error")
        return { error };
      }

      if (data) {
        setCollectionItemsData(data?.items);
        setCollectionItems(data?.items);
        setCollectionTotalCount(data?.items?.length);
      }
    }

    const { data } = await getCollectionPublishingStatus();

    if (data) {
      setCollection({ ...collection, ...data });
    }

    toggleDisableEditing();

    return {};
  };

  const onDeleteCollection = async () => {
    const { error } = await apiDelete(
      getUrlWithOptions(`${COLLECTIONS_ENDPOINT}?ids=${collectionId}`)
    );

    if (!error) {
      goBack();
    }
  };

  const onClickDeleteRow = (id) => {
    setCollectionItems((prev) => prev.filter((item: any) => item.id !== id));
  };

  const onDragRow = (reorderedRows) => setCollectionItems(reorderedRows);

  const onSearch = (query) => setSearchQuery(query);

  const onChangeFilter = (id: string) => {
    const filter = id.search(ALL_CONTENT) >= 0 ? '' : id;
    // setCurrentPageIndex(0);
    setCurrentFilter(filter);
    // dispatch(contentFilter(filter));
  };

  const onClickSort = (field, order) => {
    if (field === 'Type') {
      setContentSort(`collection_type:${order}`);
    } else if (field && order) {
      setContentSort(`${field}:${order}`);
    }
  };

  const onChangeSelection = (items) => {

    setSelectedItems(items);}

  const onContentPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setContentPageIndex(pageIndex);
    setContentPageSize(pageSize);
  }, []);

  const onCollectionPaginationChange = useCallback(
    ({ pageSize, pageIndex }) => {
      setCollectionPageIndex(pageIndex);
      setCollectionPageSize(pageSize);
    },
    []
  );

  const onAddCollectionItem = () => {
    const newCollectionItems = collectionItems.concat(selectedItems);

    setCollectionItems(newCollectionItems);
  };

  const toggleDisableEditing = () => setDisableEditing(!disableEditing);

  const isDynamicCollection = (type = "") =>
    (type || collection?.collection_type)?.toUpperCase() === "DYNAMIC";

  const isManualCollection = (type = "") =>
    (type || collection?.collection_type)?.toUpperCase() === "MANUAL";

  const addNewRule = () => {
    setCollectionRules(
      collectionRules.concat({
        id: _.uniqueId(),
        value: "",
        type: radioOptions[0].value,
        key: includeOptions[0].value,
      })
    );
  };

  const formatRules = (rules) => {
    return rules?.map((rule) => ({
      ...rule,
      id: _.uniqueId(),
    }));
  };

  const exportAsJSON = async (id) => {
    let combinedData = JSON.stringify(collection)+JSON.stringify(collectionItems);
    let blob = new Blob([JSON.stringify(combinedData)], { type: 'application/json' })

    saveAs(blob, `${id}.json`);
  };

  const onCancel = () => {
    setSelectedFilter("");
    setSearchQuery("");
    setContentPageIndex(0)
  };

  const onUpdateSelectedType = (role) => setSelectedType(role);


  return loading ? (
    <Loading />
  ) : (
    !_.isEmpty(collection) && (
      <>
        <AddBodyClasses classes={["disabled-overflow"]} />
        <div className="Collections-detail">
          <SubHeader
            title={collection.name}
            subtitle={goBackLabel}
            className="pl-5 pr-4"
            actions={
              <>
                <PublicationStateButtons
                  disabled={!hasWriteAccess}
                  publication={collection?.publication}
                  allowRevert={true}
                  onExport={() => exportAsJSON(collection.id)}
                />
                <DeleteButton
                  variant="secondary"
                  disabled={!hasWriteAccess}
                  modalProps={{
                    title: "Delete",
                    children: (
                      <>
                        Permanently delete 1 collection from the system? This
                        cannot be undone.
                      </>
                    ),
                  }}
                  onModalConfirm={onDeleteCollection}
                >
                  Delete
                </DeleteButton>
              </>
            }
            detailMode={true}
            onBack={goBack}
            icon={<Icon name={"arrow_right"} flip="horizontal" />}
          />

          <Flex>
            <div style={{ flex: 1 }} className="main-content">
              <EditPanel
                id="information"
                title="Collection Info"
                disableEditing={!hasWriteAccess}
                disableSave={collectionName === ""}
                onEnableEditing={toggleDisableEditing}
                onCancel={() => {
                  setCollectionName(collection.name);
                  setCollectionURL(collection.image);
                  setTrailerId(collection.trailerId);
                  setSelectedType(collection.collection_type)
                  setDecription(collection.description)
                  toggleDisableEditing();
                }}
                save={onUpdateCollectionInformation}
              >
                {(isEditing) => (
                  <Flex>
                    <div className="py-5 pl-6">
                      <div className= {isEditing ? "pb-12" : ''}>
                        <div
                          className={`Collections-detail-title${
                            isEditing ? " required" : ""
                          }`}
                        >
                          Collection Name
                        </div>
                        {isEditing ? (
                          <TextInput
                            value={collectionName}
                            onChange={(updatedName) =>
                              setCollectionName(updatedName)
                            }
                          />
                        ) : (
                          <div className="Collections-detail-content">
                            {collection.name}
                          </div>
                        )}
                      </div>
                      <div className="my-4">
                        <div className="Collections-detail-title">ID</div>
                        {isEditing ? (
                          <div className="Collections-detail-content pt-4">
                          {collection.id}
                        </div>
                        ) : (
                        <div className= {"Collections-detail-content"}>
                          {collection.id}
                        </div>)}
                      </div>
                      <div>
                        <div className="Collections-detail-title">Type</div>
                        {isEditing ? (
                          <SimpleSelect
                          className="select-input-lg mt-2"
                          value={selectedType}
                          options={typeOptions}
                          onChange={onUpdateSelectedType}
                        />
                        ) : (
                        <div className="Collections-detail-content">
                          {collection.collection_type}
                        </div>)}
                      </div>
                    </div>
                    <Flex
                      flexDirection="column"
                      justifyContent="space-between"
                      className="py-5 pl-6"
                    >
                      <div>
                        <div
                          className={`Collections-detail-title${
                            isEditing ? " required" : ""
                          }`}
                        >
                          Collection URL
                        </div>
                        {isEditing ? (
                          <TextArea
                            className="textArea"
                            value={collectionURL}
                            onChange={(updatedURL) =>
                              setCollectionURL(updatedURL)
                            }
                          />
                        ) : (
                          <div className="textArea">{collection.image}</div>
                        )}
                      </div>
                      <div>
                        <div
                          className={`Collections-detail-title${
                            isEditing ? " required" : ""
                          }`}
                        >
                          Trailer ID
                        </div>
                        {isEditing ? (
                          <TextInput
                          value={trailerId}
                          onChange={(id) =>
                            setTrailerId(id)
                          }
                        />
                        ) : (
                          <div className="textArea">{collection.trailerId}</div>
                        )}
                      </div>
                      <div>
                      <div
                          className={`Collections-detail-title mt-4`}
                        >
                          Description
                        </div>
                        {isEditing ? (
                          <TextInput
                          className="mt-2"
                          value={description}
                          onChange={(description) =>
                            setDecription(description)
                          }
                        />
                        ) : (
                          <div className="Collections-detail-content">{collection.description}</div>
                        )}
                      </div>
                    </Flex>
                  </Flex>
                )}
              </EditPanel>
              <AvailabilityDate
                idPanel="availibility"
                data={collection}
                endpointRootPath={COLLECTIONS_ENDPOINT}
                onEditModeChange={toggleDisableEditing}
                disableEditing={!hasWriteAccess}
              />
              <EditPanel
                id="items"
                title="Collection Items"
                disableEditing={!hasWriteAccess}
                onEnableEditing={toggleDisableEditing}
                onCancel={() => {
                  setCollectionItemsData(collectionItemsData);
                  setCollectionItems(collectionItemsData)
                  setCollectionRules(formatRules(collection?.rules));
                  toggleDisableEditing();
                  setSelectedFilter("");
                }}
                save={onUpdateCollectionItems}
              >
                {(isEditing) => (
                  <>
                    {isDynamicCollection() && isEditing && (
                      <div className="pt-1 pb-6 px-4">
                        {collectionRules.map(
                          ({ id, type, value, key }, index) => (
                            <div key={id} className="mt-4">
                              <Flex>
                                <div className="mr-7">
                                  <div className="Panel-label mb-1">
                                    Include
                                  </div>
                                  <SimpleSelect
                                    className="select-input-lg"
                                    value={key}
                                    options={includeOptions}
                                    onChange={(value) => {
                                      const updatedRules = [...collectionRules];

                                      updatedRules[index] = {
                                        key: value,
                                        value: "",
                                        type: radioOptions[0].value,
                                      };
                                      setCollectionRules(updatedRules);
                                    }}
                                  />
                                </div>
                                <div className="mr-12">
                                  <div className="Panel-label mb-1">
                                    Keywords
                                  </div>
                                  <RadioGroup
                                    value={type}
                                    options={radioOptions}
                                    onChange={(relationValue) => {
                                      const updatedRules = [...collectionRules];
                                      updatedRules[index].type = relationValue;
                                      setCollectionRules(updatedRules);
                                    }}
                                  />
                                </div>
                                <div className="ml-6">
                                  <div className="Panel-label mb-1">Value</div>
                                  <TextInput
                                    value={value}
                                    onChange={(newValue) => {
                                      const updatedRules =
                                        _.cloneDeep(collectionRules);
                                      setValue(value);
                                      updatedRules[index].value = newValue;
                                      setCollectionRules(updatedRules);
                                    }}
                                  />
                                </div>
                              </Flex>
                              <Button
                                className="delete-rule-btn"
                                variant="secondary"
                                onClick={() =>
                                  setCollectionRules(
                                    collectionRules.filter(
                                      (rule) => rule.id !== id
                                    )
                                  )
                                }
                              >
                                <p>-&nbsp;&nbsp;&nbsp;&nbsp;Clear</p>
                              </Button>
                              {/* <hr /> */}
                            </div>
                          )
                        )}
                        <Button
                          variant="secondary"
                          onClick={addNewRule}
                          className="Panel-button"
                        >
                          <p>+&nbsp;&nbsp;&nbsp;&nbsp;And Include</p>
                        </Button>
                        <div className="mt-10">
                          <div className="Panel-label mb-1">Max # of items</div>
                          <Counter
                            initialCount={maxNumItems}
                            onChange={(count: number) => setMaxNumItems(count)}
                          />
                        </div>
                        <div className="mt-5">
                          <div className="Panel-label mb-1">Default Sort</div>
                          <SimpleSelect
                            className="select-input-lg"
                            value={collectionSort}
                            options={sortOptions}
                            onChange={(optionValue) =>
                              setCollectionSort(optionValue)
                            }
                          />
                        </div>
                      </div>
                    )}
                    <Table
                      className={isEditing ? "isEditing" : ""}
                      data={collectionItems || []}
                      columns={commonColumns.concat(collectionItemsColumns)}
                      onClickDeleteRow={onClickDeleteRow}
                      onDragRow={onDragRow}
                      pageCount={getPageCount(
                        collectionItems?.length,
                        collectionPageSize
                      )}
                      pageIndex={collectionPageIndex}
                      pageSize={collectionPageSize}
                      // onPaginationChange={onCollectionPaginationChange}
                      hasPagination
                      hasDeletion
                      hasDragging
                      hasMoveTopDown
                      totalCount={collectionItems?.length}
                      manualPagination={false}
                    />
                    {!isDynamicCollection() && isEditing && (
                      <AddCollectionItemButton
                        className="ml-5 mb-5 mt-2"
                        variant="link"
                        modalProps={{
                          title: "Add a Collection Item",
                          addButtonText: "Add to Collection",
                          searchPlaceholder: "Search",
                          filters: contentFilters,
                          data: contentItemsData || [],
                          columns: contentItemsColumns,
                          pageCount: getPageCount(
                            contentTotalCount,
                            contentPageSize
                          ),
                          pageIndex: contentPageIndex,
                          pageSize: contentPageSize,
                          hasPagination: true,
                          onChangeFilter,
                          onClickSort,
                          onSearch,
                          onChangeSelection,
                          onPaginationChange: onContentPaginationChange,
                          onAddItem: onAddCollectionItem,
                          onCancel,
                          totalCount: contentTotalCount,
                          // manualPagination: false
                        }}
                      >
                        + Add Collection Item
                      </AddCollectionItemButton>
                    )}
                  </>
                )}
              </EditPanel>
            </div>
            <TableOfContents
              className="pt-12 mr-11"
              data={[
                {
                  id: "information",
                  title: "Collection Info",
                  offset: -250,
                },
                { id: "availibility", title: "Availablity", offset: -300 },
                { id: "items", title: "Collection Items", offset: -250 },
              ]}
              isSticky
              hasBorder
            />
          </Flex>
        </div>
      </>
    )
  );
};

export default compose(withRouter)(CollectionsDetail);
