/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { Flex, Icon } from "@brightcove/studio-components";

import SimpleSelect from "../ControlledComponents/SimpleSelect";
import Button from "../Button/Button";
import "./Pagination.scss";

interface PaginationProps {
  gotoPage: (val: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (val: number) => void;
  canPreviousPage: any;
  canNextPage: any;
  pageCount: any;
  pageOptions: any;
  pageIndex: any;
  pageSize: any;
  totalCount: any;
}

const Pagination: FC<PaginationProps> = ({
  gotoPage,
  previousPage,
  nextPage,
  setPageSize,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageOptions,
  pageIndex,
  pageSize,
  totalCount,
}) => {
  const [displayPageIndex, setDisplayPageIndex] = useState(pageIndex + 1);
  const [displayPageSize, setDisplayPageSize] = useState(pageSize);
  const [displaytotalCount, settotalCount] = useState(totalCount);

  useEffect(() => {
    totalCount <= pageSize
      ? setDisplayPageSize(totalCount)
      : setDisplayPageSize(pageSize*(pageIndex+1));
     if (totalCount <= pageSize) {
      gotoPage(0)
    }
    pageIndex === 0
      ? setDisplayPageIndex(pageIndex * pageSize + pageIndex + 1)
      : setDisplayPageIndex(pageIndex * pageSize + 1);
    settotalCount(totalCount);
    if(totalCount === 0 || totalCount === undefined) {
      setDisplayPageIndex(0);
      setDisplayPageSize(0);
      settotalCount(0);
    }

  }, [pageSize, totalCount]);

  useEffect(()=>{
    pageIndex === 0 
    ? setDisplayPageIndex(pageIndex * pageSize + pageIndex + 1)
    : setDisplayPageIndex(pageIndex * pageSize + 1);
    totalCount <= pageSize*(pageIndex+1)
      ? setDisplayPageSize(totalCount)
      : setDisplayPageSize(pageSize*(pageIndex+1));
    // settotalCount(totalCount);
    if(totalCount === 0 || totalCount === undefined) {
      setDisplayPageIndex(0);
      setDisplayPageSize(0);
      settotalCount(0);
    }
  },[pageIndex, totalCount])
  const nextClick = () => {
    nextPage();
    if (displayPageSize + pageSize >= totalCount) {
      setDisplayPageSize(totalCount);
    } else {
      setDisplayPageSize(displayPageSize + pageSize);
    }
    setDisplayPageIndex(displayPageIndex + pageSize);
  };

  const previousClick = () => {
    previousPage();
    setDisplayPageSize(pageIndex * pageSize);

    pageIndex === 1 ? setDisplayPageIndex(1): setDisplayPageIndex(((pageIndex-1)*pageSize)+1);
  };
  const gotoEndClick = () => {
    gotoPage(pageCount - 1);
    if (displayPageSize + (pageCount - 1) * pageSize >= totalCount) {
      setDisplayPageSize(totalCount);
    } else {
      setDisplayPageSize(displayPageSize + (pageCount - 1) * pageSize);
    }
    setDisplayPageIndex((pageCount - 1) * pageSize + 1);
  };
  const gotoStartClick = () => {
    gotoPage(0);
    setDisplayPageIndex(1);
    setDisplayPageSize(pageSize);
  };
 
  return (
    <div className="Pagination">
      <Flex {...{ alignItems: "baseline" }}>
        <p className="Pagination-show ml-4">Show: </p>
        <SimpleSelect
          className="ml-2 select-input-sm"
          value={pageSize}
          onChange={(value) => {
            setPageSize(Number(value));
            setDisplayPageSize(Number(value));
          }}
          options={[20, 30, 40, 50].map((optionPageSize) => ({
            label: `${optionPageSize} Rows`,
            value: optionPageSize,
          }))}
        />
      </Flex>
      <Flex {...{ alignItems: "center" }}>
        <Button
          variant="primary"
          onClick={() => gotoStartClick()}
          disabled={!canPreviousPage}
          className="Pagination-button"
        >
          <Icon className="Pagination-icon" color="white" name="beginning_filled" />
        </Button>

        <Button
          className="Pagination-button"
          variant="primary"
          onClick={() => previousClick()}
          disabled={!canPreviousPage}
        >
          <Icon className="Pagination-icon" color="white" name="triangle_right_filled" rotate="180" />
        </Button>
        <span className="Pagination-info">
          <strong>
            {displayPageIndex} - {displayPageSize}{" "}
          </strong>
          <span className="Pagination-of">of</span>
          <strong>{` ${displaytotalCount}`}</strong>
        </span>
        <Button
          className="Pagination-button"
          variant="primary"
          onClick={() => nextClick()}
          disabled={!canNextPage}
        >
          <Icon className="Pagination-icon" color="white" name="triangle_right_filled" />
        </Button>
        <Button
          className="Pagination-button"
          variant="primary"
          onClick={() => gotoEndClick()}
          disabled={!canNextPage}
        >
          <Icon className="Pagination-icon" color="white" name="end_filled" />
        </Button>
      </Flex>
    </div>
  );
};

export default Pagination;
