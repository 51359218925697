/* eslint-disable no-empty-pattern */
import classnames from "classnames";
import _ from "lodash";
import React from "react";
// import ToggleButton from "../ToggleButton/ToggleButton";
import ControlledProps from "./ControlledProps";

interface ItemListInputProps {
  className?: string
  items: [any]
  addModal: any// React.Component<any, any> | React.FunctionComponent<any>
  getLabel?: ({}) => string
  getId?: ({}) => string
  addText?: string
  inline?: boolean
}

export default class ItemListInput extends React.Component<ControlledProps & ItemListInputProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      items: props.items,
    }
  }

  // componentDidMount() {
  //   const { onChange, property } = this.props;
  //   const { items } = this.state;
  //   onChange && onChange(items, property);
  // }

  onRemove = (removal) => {
    return () => {
      const { getId, onChange, property } = this.props;
      const { items: currentItems } = this.state;
      const removalId = (getId && getId(removal)) || removal;

      const items = currentItems.filter((item) => {
        const itemId = (getId && getId(item)) || item;
        return itemId !== removalId;
      })

      this.setState({ items })
      if (onChange) {
        onChange(items, property);
      }
    }
  }

  onAdd = (data) => {
    const { getId, onChange, property } = this.props;
    const { items: currentItems } = this.state;

    const additions = Array.isArray(data) ? data : [data];
    const items = getId ? _.unionBy(currentItems, additions, getId) : _.union(currentItems, additions);

    this.setState({ items })
    if (onChange) {
      onChange(items, property);
    }
  }

  render() {
    const { className, inline } = this.props;
    const { items } = this.state;
    // const AddButton = withModal(ToggleButton, addModal);
    return (
      <div className={classnames(["linked-list", className])}>
        {
          items.map((item, index) => (
            <div key={index} style={{display: inline ? "inline-block" : "block"}}>
              {/* <ToggleButton
                type="remove"
                text={(getLabel && getLabel(item)) || item}
                onClick={this.onRemove((getId && getId(item)) || item)}
              /> */}
            </div>
          ))
        }
        {/* <AddButton type="add" text={addText} onModalConfirm={this.onAdd} /> */}
      </div>
    )
  }
}
