/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import {
  Flex,
  PlainRadioGroup,
  RadioOption,
} from "@brightcove/studio-components";
import "./RadioGroupDatePicker.scss";
import { formatDate, formatTimeStamp } from "../../utils";
import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
import moment from "moment";
interface RadioGroupDatePickerProps {
  placeholder?: string;
  date?: string;
  startDate?: string;
  endDate?: string;
  label: string;
  editing: boolean;
  onChangeDatePicker: (val: string) => void;
}

const IMMEDIATELY = "immediately";
const SCHEDULED = "scheduled";
export const RadioGroupDatePicker: FC<RadioGroupDatePickerProps> = ({
  placeholder,
  date,
  startDate,
  endDate,
  onChangeDatePicker,
  editing,
  label,
}) => {
  const [type, setType] = useState(
    startDate?.length || endDate?.length ? SCHEDULED : IMMEDIATELY
  );
  const [currentStartTime, setCurrentStartTime] = useState<any>(
    startDate ? new Date(startDate) : ""
  );
  const [currentEndTime, setCurrentEndTime] = useState<any>(
    endDate ? new Date(endDate) : ""
  );
  useEffect(() => {
    if (type === IMMEDIATELY) {
      onChangeDatePicker("");
    } else {
      if (label === "Available Immediately" && currentStartTime)
        onChangeDatePicker(currentStartTime.toString());
      if (label === "No End Date" && currentEndTime)
        onChangeDatePicker(currentEndTime.toString());
    }
  }, [type]);
  const [isFocused, setIsFocused] = useState(false);
  const setOpen = (active: boolean) => {
    if (!active) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };

  return (
    <>
      <PlainRadioGroup
        name="names"
        value={type}
        onChange={setType}
        className="Radio-group-date-picker"
      >
        <RadioOption label={label} value={IMMEDIATELY} />

        <RadioOption
          label=""
          value={SCHEDULED}
          className="Radio-group-date-picker-schedule"
        >
          {(selected) => {
            return (
              <>
                <Flex>
                  {label === "Available Immediately" ? (
                    <>
                      <DatePicker
                        className="w-3/5"
                        timeInputProps={{ use12HourClock: true }}
                        placeholder={"Start Date and Time"}
                        value={currentStartTime ? currentStartTime : undefined}
                        defaultValue={
                          currentStartTime ? currentStartTime : undefined
                        }
                        max={undefined}
                        includeTime
                        // onKeyDown={(e) => e.preventDefault()}
                        // disabled={!selected}
                        onChange={(
                          value: Date | null | undefined,
                          rawValue: string
                        ) => {
                          let fullYear = value?.getFullYear?.();
                          if (fullYear && fullYear >= 1900) {
                            value && setCurrentStartTime(value);
                            value && onChangeDatePicker(value.toString());
                            // setIsOpen(false)
                          } else {
                            // alert(valueTime?.getFullYear()+"9999999")
                            onChangeDatePicker("");
                            setCurrentStartTime(undefined);
                          }
                        }}
                        onBlur={() => setOpen(false)}
                        onFocus={() => setOpen(true)}
                        onSelect={() => setOpen(false)}
                        // open={isFocused}
                      />
                    </>
                  ) : (
                    <>
                      <DatePicker
                        className="w-3/5"
                        timeInputProps={{ use12HourClock: true }}
                        placeholder={"End Date and Time"}
                        value={currentEndTime ? currentEndTime : undefined}
                        defaultValue={
                          currentEndTime ? currentEndTime : undefined
                        }
                        max={undefined}
                        includeTime
                        onChange={(
                          value: Date | null | undefined,
                          rawValue: string
                        ) => {
                          let fullYear = value?.getFullYear?.();
                          if (fullYear && fullYear >= 1900) {
                            value && setCurrentEndTime(value);
                            value && onChangeDatePicker(value.toString());
                          } else {
                            // alert(valueTime?.getFullYear()+"9999999")
                            onChangeDatePicker("");
                            setCurrentStartTime(undefined);
                          }
                          // setIsOpen(false)
                        }}
                        onBlur={() => setOpen(false)}
                        onFocus={() => setOpen(true)}
                        onSelect={() => setOpen(false)}                        
                        // open={isFocused}
                      />
                    </>
                  )}
                </Flex>
              </>
            );
          }}
        </RadioOption>
      </PlainRadioGroup>
    </>
  );
};
