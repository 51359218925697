import {  PlainInputProps } from "@brightcove/studio-components";
import React from "react";
import ControlledProps from "./ControlledProps";

export default class Input extends React.Component<PlainInputProps & ControlledProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value || "",
    }
  }

  componentDidMount() {
    const { onChange, property } = this.props;
    const { value } = this.state;
    if (onChange) {
      onChange(value, property);
    }
  }

  onChange = (value) => {
    const { onChange, property, type } = this.props;
    if (type === "number") {
      value = Number(value)
    }

    this.setState({value})
    if (onChange) {
      onChange(value, property);
    }
  }

  render() {
    // const { property, className } = this.props;
    return (
      // <StudioPlainInput className={classnames(["input", className])}  {...props} value={this.state.value} onChange={this.onChange}>
      //   {this.props.children}
      // </StudioPlainInput>
      <div>Input</div>
    )
  }
}
