import React, { FC, useState } from 'react';
import classnames from 'classnames';
import { Flex } from '@brightcove/studio-components';

import Table, { TableProps } from '../Table/Table';
import SideNavigationItem from '../SideNavigation/SideNavigationItem';
import SideNavigation from '../SideNavigation/SideNavigation';
import InputSearch from '../ControlledComponents/InputSearch';
import Button from '../Button/Button';

import GenericModal, { GenericModalProps } from './GenericModal';
import './AddItemModal.scss';

interface AddRelatedContentModalProps extends GenericModalProps, TableProps {
  onChangeFilter?: (val: string) => void;
  filters?: any[];
  searchPlaceholder?: string;
  onSearch: (val: string) => void;
  onAddContent?: () => void;
  addButtonText?: string;
  onCancel?: () => void;
  onChangeSelection?: (val)=> void;
}

const AddRelatedContentModal: FC<AddRelatedContentModalProps> = ({
  show,
  className,
  onClose,
  onCancel,
  onChangeFilter,
  onChangeSelection,
  onPaginationChange,
  onAddContent,
  filters,
  data,
  columns,
  searchPlaceholder,
  onSearch,
  onClickSort,
  addButtonText,
  title,
  pageCount,
  pageIndex,
  pageSize,
  hasPagination,
  singleSelection,
  totalCount
}) => {

  const [buttonEnable, setButtonEnable] = useState(false);
  const handleCloseModal = () => {
    onCancel?.();
    onClose?.();
  };
  const handleChangeFilter = (param) => {console.log("param"+JSON.stringify(param))
    if (onChangeFilter) {
      onChangeFilter(param);
    }
  };

  const onChangeSelectionValue = (selectionRows) => {
    selectionRows.length > 0 ? setButtonEnable(true) : setButtonEnable(false);
    onChangeSelection?.(selectionRows);
    }

  return (
    <GenericModal
      className={classnames('Add-item-modal', className)}
      show={show}
      title={title}
      onClose={handleCloseModal}
      buttons={
        <>
          <button  className="cancel-button"  onClick={handleCloseModal}>Cancel</button>
          <Button
            className="ml-3"
            variant="primary"
            text={addButtonText}
            onClick={() => {
              if (onAddContent) {
                onAddContent();
              }

              handleCloseModal();
            }}
            disabled={!buttonEnable}
          />
        </>
      }
    >
      <Flex className="Add-item-modal-content">
        {filters && (
          <SideNavigation
          className='navigation'
            onChange={handleChangeFilter}
            item={SideNavigationItem}
            filters={filters}
            defaultValue={filters.length > 0 ? filters[0].id : ''}
          />
        )}
        <Flex flexDirection="column" style={{ flex: '1' }}>
          <div className="sub-header">
            <InputSearch placeholder={searchPlaceholder || ''} onSearch={onSearch} />
          </div>
          <Table
            data={data}
            columns={columns}
            onClickSort={onClickSort}
            onChangeSelection={onChangeSelectionValue}
            onPaginationChange={onPaginationChange}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            hasPagination={hasPagination}
            hasSelection
            singleSelection={singleSelection}
            totalCount={totalCount ? totalCount : data?.length}
          />
        </Flex>
      </Flex>
    </GenericModal>
  );
};

export default AddRelatedContentModal;
