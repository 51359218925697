/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useRef, useState } from "react";
import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
import moment from "moment";
import MomentLocalizer from "react-widgets-moment";
import { DateLocalizer, NumberLocalizer } from "react-widgets/IntlLocalizer";
import Localization from "react-widgets/cjs/Localization";
import { DateFormats } from "react-widgets/cjs";
import DateFnsLocalizer from "react-widgets-date-fns";

interface DateTimePickerProps {
  placeholder?: string;
  index?: string;
  onChangeDatePicker: (val: string) => void;
  value?: any;
  editing: boolean;
  time: boolean;
}

export const DateTimePickerComponent: FC<DateTimePickerProps> = ({
  placeholder,
  index,
  onChangeDatePicker,
  value,
  editing,
  time,
}) => {
  moment.locale("en");
  const localizer = new MomentLocalizer(moment);

  const [, setSavedDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    editing && !!value?.length ? new Date(value) : undefined
  );
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const setOpen = (active: boolean) => {
    if (!active) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };

  return (
    <>
      <div ref={datePickerRef}>
      <Localization date={new DateFnsLocalizer()}>
          <DatePicker
            className="w-3/5"
            timeInputProps={{ use12HourClock: true }}
            placeholder={placeholder || "Select Date"}
            defaultValue={value ? new Date(value) : undefined}
            value={value ? new Date(value) : undefined}
            max={undefined}
            includeTime
            onChange={(
              value: Date | null | undefined,
              rawValue: string
            ) => {
              let fullYear = value?.getFullYear?.();
              if (fullYear && fullYear >= 1900) {
                value && setSelectedDate(value);
                value && onChangeDatePicker(value.toString());
              } else {
                // alert(valueTime?.getFullYear()+"9999999")
                onChangeDatePicker("");
                setSelectedDate(undefined);
              }
            }}
            // parse={(str: any) => 
            //   new Date(moment.unix(Date.parse(str.toLocaleString())/1000).format("MM-DD-YYYY HH:mm"))
            //   // moment.unix(Date.parse(new Date(str).toLocaleString())/1000).format("DD/MM/YYYY hh:mm").toDate()
            //   //moment(str, "DD/MM/YYYY hh:mm").toDate()
            //   // format(new Date(str), 'MMMM do yyyy, h:mm:ss a')
            // }
            onFocus={() => setOpen(true)}
            onSelect={() => setOpen(false)}
          />
        </Localization>
      </div>
    </>
  );
};
