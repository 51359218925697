import { Checkbox as StudioCheckbox, CheckboxProps } from "@brightcove/studio-components";
import classnames from "classnames";
import React from "react";
import ControlledProps from "./ControlledProps";

export default class Checkbox extends React.Component<CheckboxProps & ControlledProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
    }
  }

  componentDidMount() {
    const { onChange, property } = this.props;
    const { value } = this.state;
    if (onChange) {
      onChange(value, property);
    }
  }

  onChange = (value) => {
    const { onChange, property } = this.props;
    this.setState({value})
    if (onChange) {
      onChange(value, property);
    }
  }

  render() {
    const { property, className, ...props } = this.props;
    return (
      <StudioCheckbox className={classnames(["checkbox", className])} {...props} checked={this.state.value} onChange={this.onChange}>
        {this.props.children}
      </StudioCheckbox>
    )
  }
}
