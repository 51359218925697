/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router';
import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { Flex, Icon } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { ModuleName, formatDate, getModulePermissions } from '../../utils';
import { getUrlWithOptions, getPageCount, pageFilters } from '../../utils';
import { useApi } from '../../hooks/useApi';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import Loading from '../../components/Loading/Loading';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Button from '../../components/Button/Button';
import './Pages.scss';
import { useSelector } from 'react-redux';

interface PagesProps {
  embedded?: boolean;
  pageChangeModal?: boolean;
  selectedFilter?: string;
  onRowSelect?: (param: any[]) => void;
}
const INITIAL_KEY = '';

const Pages: FC<PagesProps> = ({ embedded, pageChangeModal, selectedFilter, onRowSelect }) => {
  const { apiGet, apiPost, apiDelete, refetchGet } = useApi();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [filter, setFilter] = useState<string>(selectedFilter || '');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sort, setSort] = useState<string>('updated_at:dsc');
  const [pagesData, setPagesData] = useState<any>();
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const navigate = useNavigate();
  const pagesUrl = getUrlWithOptions('/ops/pages');
  const [startKey, setStartKey] = useState(INITIAL_KEY);
  const [startKeyData, setStartKeyData] = useState('');
  const prevCountRef = useRef<string>(INITIAL_KEY);
  const [paginationKeyArr, setPaginationKeyArr] = useState<any>([]);
  const permissions = useSelector((state:any) => state.permissions);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.PAGES_SCREEN, permissions);

  useEffect(() => {
    (async () => {
      const { data } = await apiGet(
        getUrlWithOptions(
          '/ops/pages',
          searchQuery,
          sort,
          filter ? getFilters(filter) : undefined,
          currentPageIndex,
          currentPageSize,
          startKey
        )
      );

      setPagesData(data?.results);
      if (data?.results?.totalCount <= (currentPageIndex * currentPageSize)  && searchQuery) {
        setTotalCount(data?.results?.totalCount);
        setCurrentPageIndex(0)
      } else if (data?.results?.totalCount > (currentPageIndex * currentPageSize) && searchQuery){
        setTotalCount(data?.results?.totalCount);
        let pageCount: number = Math.ceil(data?.results?.totalCount / currentPageSize);
        pageCount<= currentPageIndex?   setCurrentPageIndex(pageCount) : setCurrentPageIndex(currentPageIndex);
      } else {
        setTotalCount(data?.results?.totalCount);
      }      
      setLoading(false);
      if (data?.lastEvaluatedKey) {
        setStartKeyData(data?.lastEvaluatedKey);
      }
    })();
   
  }, [searchQuery, sort, filter, currentPageIndex, currentPageSize, startKey]);

  useEffect(() => {
    prevCountRef.current = startKey;
    paginationKeyArr[currentPageIndex+1]= startKeyData;
  }, [startKeyData]);

  const getFilters = (filters) => {
    return filters
      .split(',')
      .map((type) => (type ? `type:${type}` : ''))
      .join(',');
  };

  const onChangeSelection = (items: any[]) => {
    setSelectedItems(items);
    onRowSelect?.(items);
  };

  const onDeletePages = async () => {
    const pagesToDelete = selectedItems.map((item) => item.id).toString();
    const { error } = await apiDelete(`${pagesUrl}?ids=${pagesToDelete}`);

    if (!error) {
      const { data } = await refetchGet();

      setPagesData(data?.results);
      setTotalCount(data?.results?.totalCount);
    }
  };

  const onDuplicatePages = async () => {
    const pageToDuplicate = selectedItems?.[0];

    if (!pageToDuplicate) {
      return;
    }

    setIsDuplicating(true);

    const { error } = await apiPost(`${pagesUrl}/${pageToDuplicate.id}/duplicate`);

    if (!error) {
      const { data } = await refetchGet();

      setPagesData(data?.results);
      setTotalCount(data?.results?.totalCount);
    }

    setIsDuplicating(false);
  };

  const onClickSort = (field: string, order: string) => {
    if (field && order) {
      setSort(`${field}:${order}`);
    }
  };

  const getPageTypeLabel = (type) => {
    const pageFilter = pageFilters?.filter((pageFilter) => pageFilter?.id?.toLowerCase() === type?.toLowerCase());
    const pageFilterLabel = pageFilter.length > 0 ? pageFilter[0]?.name : '';

    return pageFilterLabel.substring(0, pageFilterLabel.length - 1);
  };

  const onPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
    setStartKey(pageIndex === 0? '' : paginationKeyArr[pageIndex]);
  }, []);

  const columns = [
    {
      Header: 'Page Name',
      accessor: 'internal_name',
      sortable: true,
      Cell: ({ value, row }) => (
        <div style={{width:"250px", textOverflow:"ellipsis", overflowWrap: "break-word"}}>
        <TitleDescription
          title={value}
          description={row.original.id}
          id={row.original.id}
          onClick={(id) => navigate(`../${ROUTES.PAGES}/edit/${id}`)}
        />
        </div>
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
      className: 'type',
      sortable: true,
      Cell: ({ value }) => getPageTypeLabel(value),
    },
    {
      Header: "Status",
      accessor: "status", // accessor is the "key" in the data
      Cell: ({ value }) => {
        return (
          <Icon
        name={value === "active" ? "check2" : "warning"}
        color={value === "active" ? "blue" : "burgundy"}
      />
        );
      },
      sortable: true,
      className: "",
    },
    {
      Header: 'Created',
      accessor: 'created_at',
      sortable: true,
      Cell: ({ value, row }) => (
        <>
          <p>{formatDate(value)}</p>
          <p className="subtitle">{row.original.createdBy}</p>
        </>
      ),
      className: 'created-date',
    },
    {
      Header: 'Updated',
      accessor: 'updated_at',
      sortable: true,
      Cell: ({ value, row }) => (
        <>
          <p>{formatDate(value)}</p>
          <p className="subtitle">{row.original.updatedBy}</p>
        </>
      ),
      className: 'updated-date',
    },
  ];

  return (<>
    {loading &&
      <Loading />}
    <Flex className="Pages">
      
      <Flex className="Pages-content" flexDirection="column">
        <SubHeader
          icon={<Icon name={'card'} />}
          title={"Pages"}
          subtitle={''}
          quantitySubtitle={`${pagesData?.totalCount || 0} Page${pagesData?.totalCount === 1 ? '' : 's'}`}
          actions={<InputSearch placeholder="Search" onSearch={(val) => setSearchQuery(val)} />}
        />
        {/* {loading ? (
          <Loading />
        ) : ( */}
          <>
            { (
              <Flex className="mt-4 mb-4 mx-10" alignItems="center">
                <Button
                  variant="primary"
                  text="Create Page"
                  onClick={() => navigate(`../${ROUTES.PAGES}/create`)}
                  disabled={!hasWriteAccess}
                />
                <Button
                  variant="secondary"
                  text={isDuplicating ? 'Duplicating' : 'Duplicate Page'}
                  disabled={selectedItems.length !== 1 || isDuplicating || !hasWriteAccess}
                  onClick={onDuplicatePages}
                />
                
              </Flex>
            )}
            <Table
              className=""
              data={pagesData?.items || []}
              columns={columns}
              onChangeSelection={onChangeSelection}
              onClickSort={onClickSort}
              hasSelection
              hasPagination
              defaultSortingValues={[{ id: 'updated_at', desc: true }]}
              pageCount={getPageCount(currentTotalCount, currentPageSize)}
              onPaginationChange={onPaginationChange}
              pageIndex={currentPageIndex}
              pageSize={currentPageSize}
              singleSelection={pageChangeModal}
              totalCount={currentTotalCount}
            />
          </>
        {/* )} */}
      </Flex>
    </Flex>
    </>
  );
};

export default Pages;
