import React, { FC } from 'react';
import classnames from 'classnames';
import './TextInput.scss';

interface TextInputProps {
  onChange: (val: string, property?: string) => void;
  property?: string;
  value: string | number;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  min?: string;
  max?: string;
}
const TextInput: FC<TextInputProps> = ({
  onChange,
  property,
  value,
  className,
  placeholder,
  disabled,
  type,
  max,
  min,
}) => {
  return (
    <input
      disabled={disabled}
      type={type?.length ? type : 'text'}
      max={max?.length ? max : undefined}
      min={min?.length ? min : undefined}
      className={classnames('TextInput', className)}
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value, property)}
    />
  );
};

export default TextInput;