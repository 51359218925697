import React, { FC, useEffect, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import { getUrlWithOptions } from '../../../utils';
import { useApi } from '../../../hooks/useApi';
import Panel from '../../../components/Panel/Panel';
import TextInput from '../../../components/ControlledComponents/TextInput';
import { RadioGroup } from '../../../components/ControlledComponents/RadioGroup';
import Button from '../../../components/Button/Button';

interface ContentProps {
  idPanel: string;
  data: any;
  onSavedData: () => void;
}

type AdvertisingType = 'FREE' | 'AD_SUPPORTED';

const ContentAdTrailer: FC<ContentProps> = ({ idPanel, data, onSavedData }) => {
  const { apiPatch, isLoading } = useApi();
  const { id, extId, source, name, adId, advertising, adType, adLanguage, adKeys } = data;
  const [editing, setEditing] = useState<boolean>(false);
  const [currentAdId, setCurrentAdId] = useState<string>('');
  const [currentAdType, setCurrentAdType] = useState<string>('');
  const [advertisingType, setAdvertisingType] = useState<AdvertisingType>('FREE');
  const [currentAdLanguage, setCurrentAdLanguage] = useState<string>('');
  const [currentAdKeys, setCurrentAdKeys] = useState<string>('');

  useEffect(() => {
    setCurrentAdId(adId);
    setCurrentAdType(adType);
    setCurrentAdLanguage(adLanguage);
    setCurrentAdKeys(adKeys);
    setAdvertisingType(advertising);
  }, [adId, adType, adLanguage, adKeys, advertising]);

  const onSaveAdContent = async () => {
    const body = {
      adId: currentAdId,
      adType: currentAdType,
      adLanguage: currentAdLanguage,
      adKeys: currentAdKeys,
      advertising: advertisingType,
    };
    const response = await apiPatch(getUrlWithOptions(`/content/${id}`), {
      body,
    });

    if (response?.data) {
      setEditing(false);
      onSavedData();
    }
  };

  const restoreData = () => {
    const { adId, advertising, adType, adLanguage, adKeys } = data;

    setCurrentAdId(adId);
    setCurrentAdType(adType);
    setCurrentAdLanguage(adLanguage);
    setCurrentAdKeys(adKeys);
    setAdvertisingType(advertising);
  };

  return (
    <div id={idPanel} className="Content">
      <Panel
        title="Content"
        actions={
          <>
            {editing ? (
              <>
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    restoreData();
                    setEditing(false);
                  }}
                />
                <Button variant="primary" text="Save" onClick={onSaveAdContent} disabled={isLoading} />
              </>
            ) : (
              <Button
                variant="primary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                }}
              />
            )}
          </>
        }
      >
        <>
          {editing ? (
            <>
              <Flex>
                <div>
                  <div className="ml-4">
                    <p className="title">ID</p>
                    <p className="description">{id}</p>
                  </div>
                  <div className="ml-4 mt-6">
                    <p className="title">Source</p>
                    <p className="description">{source ? source : '-'}</p>
                  </div>
                  <div className="ml-4 mt-6">
                    <p className="title">Name</p>
                    <p className="description">{name ? name : '-'}</p>
                  </div>
                  <div className="ml-4 mt-6">
                    <p className="title ">Reference ID</p>
                    {extId?.length ? (
                      <p className="link">
                        <a href={extId}>{extId}</a>
                      </p>
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="ml-4 mt-6">
                    <p className="title">Advertising</p>
                    <RadioGroup
                      value={advertisingType}
                      options={[
                        { label: 'Free', value: 'FREE' },
                        { label: 'Ad supported', value: 'AD_SUPPORTED' },
                      ]}
                      onChange={(value) => {
                        setAdvertisingType(value as AdvertisingType);
                      }}
                    />{' '}
                  </div>
                  <div className="ml-4 mt-6">
                    <p className="title">Ad ID</p>
                    <TextInput value={currentAdId?.length ? currentAdId : ''} onChange={setCurrentAdId} />
                  </div>
                  <div className="ml-4 mt-6">
                    <p className="title">Ad Type</p>
                    <TextInput
                      value={currentAdType?.length ? currentAdType : ''}
                      onChange={setCurrentAdType}
                    />
                  </div>
                  <div className="ml-4 mt-6">
                    <p className="title">Ad Language</p>
                    <TextInput
                      value={currentAdLanguage?.length ? currentAdLanguage : ''}
                      onChange={setCurrentAdLanguage}
                    />
                  </div>
                  <div className="ml-4 mt-6">
                    <p className="title">Ad Keys</p>
                    <TextInput
                      value={currentAdKeys?.length ? currentAdKeys : ''}
                      onChange={setCurrentAdKeys}
                    />
                  </div>
                </div>
              </Flex>
            </>
          ) : (
            <Flex>
              <div>
                <div className="ml-4">
                  <p className="title">ID</p>
                  <p className="description">{id}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Source</p>
                  <p className="description">{source ? source : '-'}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Name</p>
                  <p className="description">{name ? name : '-'}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title ">Reference ID</p>
                  {extId?.length ? (
                    <p className="link">
                      <a href={extId}>{extId}</a>
                    </p>
                  ) : (
                    '-'
                  )}
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Advertising</p>
                  <p className="description">{advertisingType ? advertising : '-'}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Ad ID</p>
                  <p className="description">{currentAdId?.length ? currentAdId : '-'}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Ad Type</p>
                  <p className="description">{currentAdType?.length ? currentAdType : '-'}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Ad Language</p>
                  <p className="description">{currentAdLanguage?.length ? currentAdLanguage : '-'}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Ad Keys</p>
                  <p className="description">{currentAdKeys?.length ? currentAdKeys : '-'}</p>
                </div>
              </div>
            </Flex>
          )}
        </>
      </Panel>
    </div>
  );
};

export default ContentAdTrailer;
