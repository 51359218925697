export const HOME: string = "/";
export const CREATE_PASSWORD: string = "/create-password";
export const RESET_PASSWORD: string = "/reset-password";
export const LOGIN: string = "/login";
export const PAGES: string = "/pages";
export const PROFILE: string = "/profile";
export const CHANNELS: string = "/channels";
export const STRINGS: string = "/dynamic-strings";
export const NEW_STRINGS: string = "/dynamic-strings/new";
export const USER_MANAGEMENT: string = "/user-management";
export const MAINTENANCE_MODE = '/maintenance-mode';
export const ERROR: string = "/error";
export const ACTIVITY: string = "/activity-history";
export const CONTENT_MANAGEMENT: string = "/content-management";
export const COLLECTIONS: string = "/collections";
export const SUBSCRIPTION: string = "/subscription";

