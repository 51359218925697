/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import classnames from "classnames";
import "./SimpleSelect.scss";
import Select, {
  components,
  DropdownIndicatorProps,
  IndicatorSeparatorProps,
} from "react-select";
import { Icon } from "@brightcove/studio-components";
import { getOptionValue } from "../../utils";
interface SimpleSelectProps {
  property?: string;
  value: any;
  onChange: (val: string, property?: string) => void;
  options: any[];
  className?: string;
  disabled?: boolean;
}
const SimpleSelect: FC<SimpleSelectProps> = ({
  property,
  value: initialValue,
  onChange,
  options,
  className,
  disabled = false,
}) => {
  const [value, setValue] = useState(initialValue);
  const onSelectionChange = (selectedValue) => {
    setValue(selectedValue);
    onChange?.(selectedValue?.value, property);
  };

  const DropdownIndicator = (props: DropdownIndicatorProps<true>) => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon
          className="iconClass"
          name="triangle_right_filled"
          rotate="90"
          color="#808080"
        />
      </components.DropdownIndicator>
    );
  };

  const indicatorSeparatorStyle = {
    display: "none",
  };

  const IndicatorSeparator = ({
    innerProps,
  }: IndicatorSeparatorProps<true>) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
  };

  const style = {
    control: (base) => ({
      ...base,
      height: "36px !important",
      border: "0.5px solid rgba(0, 0, 0, 0.20)",
      boxShadow: "0 !important",
      backgroundColor : "none",
      borderStyle: "none",
    }),
  };

  useEffect(() => {
    const initialValuieObj = getOptionValue(initialValue, options);
    setValue(initialValuieObj);
  }, [initialValue]);

  return (
    <Select
      className={classnames(`SimpleSelect`, className)}
      value={value}
      options={options}
      onChange={onSelectionChange}
      isDisabled={disabled}
      components={{ DropdownIndicator, IndicatorSeparator }}
      menuPosition={"fixed"}
      styles={style}
      menuPlacement="auto"
    />
  );
};

export default SimpleSelect;
