/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Flex, Icon } from "@brightcove/studio-components";

import * as ROUTES from "../routes";
import { formatDate, getUrlWithOptions } from "../../utils";
import { useApi } from "../../hooks/useApi";
import SubHeader from "../../components/SubHeader/SubHeader";
import Panel from "../../components/Panel/Panel";

import "./ActivityLogDetail.scss";

const ActivityLogDetail = () => {
  const navigate = useNavigate();
  const { id: activityId } = useParams();
  const { apiGet } = useApi();
  const [activity, setActivity] = useState<any>({});

  useEffect(() => {
    (async () => {
      const { data: activityData } = await apiGet(
        getUrlWithOptions(`/ops/activity_logs/${activityId}`)
      );

      if (activityData) {
        setActivity(activityData);
      }
    })();
  }, []);

  const formatResponse = (str) => {
    try {
      const parsed = JSON.parse(str);
      return JSON.stringify(parsed, undefined, 4);
    } catch (error) {
      return str; // If parsing fails, return the original string
    }
  };

  const {
    activity_type,
    resource_id,
    success,
    changed_by,
    body,
    header,
    error,
    changed_at,
  } = activity;
  
  return (
    <div className="Activity-log-detail">
      <SubHeader
        className="Sub-header"
        title={activity_type}
        detailMode
        subtitle="Back to Activity History"
        icon={<Icon name="arrow_right" flip="horizontal" />}
        onBack={() => navigate(`../${ROUTES.ACTIVITY}`)}
      />
      <div className="main-content">
        <Panel
          title={<div className="panel-content">Activity Details</div>}
          className="panel-content pt-2"
        >
          <div>
            <Flex flexDirection="column">
              <div>
                <Flex flexDirection="row" justifyContent="space-between">
                  <div className="mb-5">
                    <div className="panel-label">ID</div>
                    <div className="panel-label-content">{activity_type}</div>
                  </div>
                  <div className="mb-5">
                    <div className="panel-label">Content ID</div>
                    <div className="panel-label-content">{resource_id}</div>
                  </div>
                  <div className="mb-5">
                    <div className="panel-label">User ID</div>
                    <div className="panel-label-content">
                      {changed_by?.user_id}
                    </div>
                  </div>
                </Flex>
              </div>
              <div>
                <Flex flexDirection="row" justifyContent="space-between">
                  <div className="my-5">
                    <div className="panel-label">Status</div>
                    <div className="panel-label-content">
                      {success === "true" ? "Success" : "Failure"}
                    </div>
                  </div>
                  <div className="mb-5 my-5">
                    <div className="panel-label">User Email</div>
                    <div className="panel-label-content">
                      {changed_by?.email}
                    </div>
                  </div>
                  <div className="mb-5 my-5">
                    <div className="panel-label">Created</div>
                    <div className="panel-label-content">
                      {formatDate(changed_at)}
                    </div>
                  </div>
                </Flex>
              </div>
            </Flex>

            <div className="mb-5">
              <div className="panel-label">Body</div>
              {body ? (
                <pre className="panel-label-content mb-0 mt-1">
                  {formatResponse(body)}
                </pre>
              ): <div>-</div>}
            </div>

            <div>
              <div className="panel-label">Header</div>
              {header ? (
                <pre className="panel-label-content mb-0 mt-1">
                  {formatResponse(header)}
                </pre>
              ): <div>-</div>}
            </div>

            <div>
              <div className="panel-label">Error</div>
              {error ? (
                <pre className="panel-label-content mb-0 mt-1">
                  {formatResponse(error)}
                </pre>
              ) : <div>-</div>}{" "}
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default ActivityLogDetail;
