/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router';
import React, { FC, useEffect, useRef, useState } from 'react';
import { flowRight as compose } from 'lodash';
import { Flex, Icon } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { ModuleName, formatDate, getModulePermissions } from '../../utils';
import { getPageCount, getUrlWithOptions } from '../../utils';
import './Channels.scss';
import { useApi } from '../../hooks/useApi';
import { withRouter } from '../../components/withRouter/withRouter';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import Loading from '../../components/Loading/Loading';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Button from '../../components/Button/Button';
import { useSelector } from 'react-redux';

const DeleteButton = withModal(Button, DeleteConfirmationModal);

interface CollectionsProps {
  embedded?: boolean;
  filter?: string;
  singleSelection?: boolean;
  onRowSelect?: (param: any[]) => void;
}
const INITIAL_KEY = '';
const Channels: FC<CollectionsProps> = ({ embedded, filter, singleSelection, onRowSelect }) => {
  const navigate = useNavigate();
  const { apiGet, apiDelete, refetchGet } = useApi();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sort, setSort] = useState<string>('updated_at:dsc');
  const [channelsData, setChannelsData] = useState<any>(undefined);
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startKey, setStartKey] = useState('');
  const [startKeyData, setStartKeyData] = useState('');
  const prevCountRef = useRef<string>(INITIAL_KEY);
  const [paginationKeyArr, setPaginationKeyArr] = useState<any>([]);
  const permissions = useSelector((state:any) => state.permissions);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.CHANNELS_SCREEN, permissions);

  const columns = [
    {
      Header: 'Channel Name',
      sortable: true,
      accessor: 'name',
      Cell: ({ value, row }) => (
        <div style={{width:"250px", textOverflow:"ellipsis", overflowWrap: "break-word"}}>
        <TitleDescription
          title={value}
          description={row.original.id}
          id={row.original.id}
          onClick={(id) => navigate(`../${ROUTES.CHANNELS}/edit/${id}`)}
        />
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status', // accessor is the "key" in the data
      Cell: ({ value }) => {
        return (
          <Flex justifyContent="center">
            <Icon
              name={value === "active" ? "check2" : "warning"}
              color={value === "active" ? "blue" : "burgundy"}
      />
          </Flex>
        );
      },
      sortable: true,
      className: '',
    },
    {
      Header: 'Created',
      accessor: 'created_at',
      sortable: true,
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.createdBy}</div>
        </>
      ),
      className: '',
    },
    {
      Header: 'Updated',
      accessor: 'updated_at',
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.updatedBy}</div>
        </>
      ),
      sortable: true,
      className: '',
    },
  ];

  useEffect(() => {
    (async () => {
      if (!channelsData) {
        setLoading(true);
      }

      const { data } = await apiGet(
        getUrlWithOptions(
          '/ops/channel',
          searchQuery,
          sort,
          filter || undefined,
          currentPageIndex,
          currentPageSize,
          startKey
        )
      );
      setChannelsData(data?.results);
      if (data?.results?.totalCount <= (currentPageIndex * currentPageSize) && searchQuery) {
        setTotalCount(data?.results?.totalCount);
        setCurrentPageIndex(0);
      } else if (data?.results?.totalCount > (currentPageIndex * currentPageSize) && searchQuery){
        setTotalCount(data?.results?.totalCount);
        let pageCount: number = Math.ceil(data?.results?.totalCount / currentPageSize);
        pageCount<= currentPageIndex?   setCurrentPageIndex(pageCount) : setCurrentPageIndex(currentPageIndex);
      } else {
        setTotalCount(data?.results?.totalCount);
      }
      if (data?.lastEvaluatedKey) {
        setStartKeyData(data?.lastEvaluatedKey);
      }
      setLoading(false);
    })();
  }, [searchQuery, sort, currentPageIndex, currentPageSize, startKey]);

  const onChangeSelection = (items) => {
    setSelectedItems(items);
    onRowSelect?.(items);
  };

  const onDeleteItems = async () => {
    const idsToDelete = selectedItems.map((item) => item.id).join(",");
    const { error } = await apiDelete(getUrlWithOptions(`/ops/channel/${idsToDelete}`));

    if (!error) {
      const { data } = await refetchGet();

      setChannelsData(data?.results);
      setTotalCount(data?.results?.totalCount);
    }
  };

  const onClickSearch = (query) => setSearchQuery(query);

  const onClickSort = (field, order) => {
    if (field && order) {
      setSort(`${field}:${order}`);
    }
  };
  useEffect(() => {
    prevCountRef.current = startKey;
    paginationKeyArr[currentPageIndex+1]= startKeyData;
  }, [startKeyData]);

  const onPaginationChange = ({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
    setStartKey(pageIndex === 0? '' : paginationKeyArr[pageIndex]);
  };

  return loading ? (
    <Loading />
  ) : (
    <Flex className="Channels" flexDirection="column">
      <SubHeader
        title="Channels"
        subtitle=""
        actions={<InputSearch placeholder="Search" onSearch={onClickSearch} />}
        icon={<Icon name={"card"} />}
        quantitySubtitle={`${currentTotalCount || 0} Channel${
          currentTotalCount === 1 ? "" : "s"
        }`}
      />
      {/* {!embedded && ( */}
      <Flex className="mt-4 mx-10" alignItems="center">
        <Button
          variant="primary"
          text="Create Channel"
          onClick={() => navigate(`../${ROUTES.CHANNELS}/create`)}
          disabled={!hasWriteAccess}
        />
        <DeleteButton
          variant="secondary"
          disabled={selectedItems.length === 0 || !hasWriteAccess}
          modalProps={{
            title: "Delete",
            children: (
              <>
                Permanently delete{" "}
                {selectedItems.length === 1
                  ? selectedItems.length + " channel"
                  : selectedItems.length + " channels"}{" "}
                from the system? This cannot be undone.
              </>
            ),
          }}
          onModalConfirm={onDeleteItems}
        >
          Delete
        </DeleteButton>
      </Flex>
      {/* )} */}
      <Table
        className={"mt-4"}
        data={channelsData?.items || []}
        columns={columns}
        onChangeSelection={onChangeSelection}
        onClickSort={onClickSort}
        hasSelection
        defaultSortingValues={[{ id: "updated_at", desc: true }]}
        hasPagination
        pageCount={getPageCount(currentTotalCount, currentPageSize)}
        onPaginationChange={onPaginationChange}
        pageIndex={currentPageIndex}
        pageSize={currentPageSize}
        singleSelection={singleSelection}
        totalCount={currentTotalCount}
      />
    </Flex>
  );
};

export default compose(withRouter)(Channels);
