/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Button, Input } from "@brightcove/studio-components";
import _ from "lodash";
import React, { useEffect, useState } from "react"
import { CheckboxGroup } from "../../components/ControlledComponents";
import Row from "../../components/Row/Row";
import UserRow from "../../components/Row/UserRow";
import { useApi } from "../../hooks/useApi";
import { getModulePermissions, getUrlWithOptions, ModuleAccessOptions, PermissionLevel, ModuleWriteAccessOptions } from "../../utils";
import "./UserManagement.scss";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import Loading from "../../components/Loading/Loading";
import { useSelector } from "react-redux";
import FirebaseAuthenticator from "../../auth/Auth-Firebase";
import { useNavigate } from "react-router";
import * as ROUTES from '../routes';

const NewUserProps = {
  readAccess: "newUserReadAccess",
  writeAccess: "newUserWriteAccess",
  email: "newUserEmail",
  firstName: "newUserFirstName",
  lastName: "newUserLastName",
};

interface IUserManagementErrors {
  email?: string,
  firstName?: string,
  lastName?: string,
  writeAccess?: string,
  readAccess?: string
};

const AddUserButton: React.FunctionComponent<any> = ({ changes, getPermissions, isValidInput, onCompleted, onSubmit, onError,setChanges, ...props }) => {
  const { apiPost } = useApi();
  const authenticator = new FirebaseAuthenticator();
  const addUser = async (values) => {
    // const constructedDisplayName = values.firstName ? values.firstName : ""  + values.lastName ? values.lastName : "";
    const modulesWithReadAccess = values.permissionsInput.READ;
    const modulesWithWriteAccess = values.permissionsInput.WRITE;
    const permissionsGranted = {};
    for (const moduleWithReadAccess of modulesWithReadAccess) {
      let moduleType = '';
      if (modulesWithWriteAccess.includes(moduleWithReadAccess)) {
        // both read and write permission available so max privilege is "write"
        moduleType = moduleWithReadAccess.toLowerCase();
        permissionsGranted[moduleType+'Permission'] = `${PermissionLevel.WRITE}_${moduleType.toUpperCase()}`;
      } else {
        // only read permission available so max privilege is "read"
        moduleType = moduleWithReadAccess.toLowerCase();
        permissionsGranted[moduleType+'Permission'] = `${PermissionLevel.READ}_${moduleType.toUpperCase()}`;
      }
    }
    const body = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      permissions: permissionsGranted,
    }
    console.log("body is", body);

    const { data, error } = await apiPost(
      getUrlWithOptions(`/ops/users`),
      {
        body,
      },
    );
    if (error) {
      onError(error);
    }
    if (data) {
      authenticator.initiateResetPassword(values.email, (result) => {
        if (result) {
          console.log('password reset sent');
        }
      },
      (error) => {
        if (error) {
          console.log('user created but error sending reset link', error.message);
        }
      });
      onCompleted(data);
    }

  };
  const handleSubmit = async () => {
    const valid = await onSubmit();
    if (valid) {
      await addUser({
        email: changes && changes[NewUserProps.email],
        firstName: changes && changes[NewUserProps.firstName],
        lastName: changes && changes[NewUserProps.lastName],
        permissionsInput: getPermissions(),
      });
      setChanges({});
    }
  };
  return (
    <Button className="btn-red validation-btn" text="Add User" type="submit" onClick={handleSubmit} />
  )
};

const UserManagement: React.FunctionComponent<any> = ({ moduleName }) => {

  const userTable = React.createRef<HTMLDivElement>();
  const tableHeaderRow = React.createRef<HTMLDivElement>();
  const scrollBar = React.createRef<HTMLDivElement>();
  const { apiDelete, apiGet, refetchGet } = useApi();
  const [ changes, setChanges ] = React.useState<object>({});
  const [ errors, setErrors ] = React.useState<any>({});
  const [ users, setUsers ] = React.useState<any>([]);
  const [ deleteStatus, setDeleteStatus] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const  permissions  = useSelector((state:any) => state.permissions);


  const { hasWriteAccess } = getModulePermissions(moduleName, permissions);
  const scrollBarContentWidth = tableHeaderRow.current ? `${tableHeaderRow.current.scrollWidth}px` : "auto";

  const onInputChange = (change, event) => {
    const property = event.target.name;
    // onChange(change, property);
    setChanges({
      ...changes,
      [property]: change,
    })
  }

  const onSelectAll = async (event) => {
    const property = event.target.name;
    const values = ModuleAccessOptions.map((option) => option.value);

    if (property === NewUserProps.writeAccess) {
      // if user enables write permissions to all modules, then enable read permissions for all modules too
      setChanges({
        ...changes,
        [NewUserProps.readAccess]: values,
        [NewUserProps.writeAccess]: values,
      })
    } else {
      setChanges({
        ...changes,
        [property]: values,
      })
    }
  }

  const onChangePermissions = async (change, property) => {
    // const { changes } = this.state;
    const writeAccessProp = NewUserProps.writeAccess;
    const readAccessProp = NewUserProps.readAccess;
    const currentReadPermissions = (changes && changes[readAccessProp]) || [];
    const currentWritePermissions = (changes && changes[writeAccessProp]) || [];

    if (property === writeAccessProp && change.length > currentWritePermissions.length) {
      // add corresponding read permission when a write permission is being added
      const newModule = change[change.length - 1];

      if (!currentReadPermissions.includes(newModule)) {
        const updatedReadPermissions = currentReadPermissions.concat(newModule);
        // await onChange(updatedReadPermissions, readAccessProp);
        setChanges({
          ...changes,
          [readAccessProp]: updatedReadPermissions,
          [writeAccessProp]: change,
        })
      } else {
        setChanges({
          ...changes,
          [writeAccessProp]: change,
        })
      }
    } else if (property === readAccessProp && change.length < currentReadPermissions.length) {
      // remove corresponding write permission when a read permission is being removed
      const deletedModule = _.difference(currentReadPermissions, change)[0];

      if (currentWritePermissions.includes(deletedModule)) {
        const updatedWritePermissions = currentWritePermissions.filter((module) => module !== deletedModule);
        setChanges({
          ...changes,
          [writeAccessProp]: updatedWritePermissions,
          [readAccessProp]: change,
        })
      } else {
        setChanges({
          ...changes,
          [readAccessProp]: change,
        })
      }
    } else {
      setChanges({
        ...changes,
        [property]: change,
      })
    }

  }

  const getPermissions = () => {
    const selectedPermissions = {
      [PermissionLevel.READ]: (changes && changes[NewUserProps.readAccess]) || [],
      [PermissionLevel.WRITE]: (changes && changes[NewUserProps.writeAccess]) || [],
    };
    return selectedPermissions;
  }

  const isValidEmail = (email) => {
    return email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  }

  const isValidInput = () => {
    return _.isEmpty(errors);
  }

  const onSubmit = async () => {
    const validationErrors: IUserManagementErrors = {};

    if (!changes || !changes[NewUserProps.email] || !isValidEmail(changes[NewUserProps.email])) {
      validationErrors.email = "A valid email address is required";
    }
    if (!changes || !changes[NewUserProps.firstName]) {
      validationErrors.firstName = "Please enter your first name";
    }
    if (!changes || !changes[NewUserProps.lastName]) {
      validationErrors.lastName = "Please enter your last name";
    }

    setErrors(validationErrors);
    return _.isEmpty(validationErrors);
  }

  const onCancel = () => {
    setChanges({});
    setErrors({});
  }

  const onError = (error) => {
    console.error(error.message);
  }

  const onSuccess = (data) => {
    if (data) {
      setUsers(data);
    }
  };

  const fetchData = async () => {
      const { data } = await apiGet(getUrlWithOptions("/ops/users"));
      if (data) {
        setUsers(data);
        onSuccess(data);
      }
      setLoading(false);
      setDeleteStatus(false);      
  }

  useEffect(() => {
    fetchData();
  },[]);

  const onDeleteUser = async (id) => {
    const { error } = await apiDelete(
      getUrlWithOptions(`/ops/users/${id}`));
    if (error) {
      onError(error);
    }
    setDeleteStatus(true);
    if (!error) {console.log("inside fetch again")
      const { data } = await apiGet(getUrlWithOptions("/ops/users"));
      if (data) {
        setUsers(data);
      }
      navigate(ROUTES.USER_MANAGEMENT)
    }
  }

  const clearData = () => {
    setChanges({});
  }
  
  return (
<>
    {loading && <Loading />}
    <div className="main">
      <div className="content">
        <SectionHeader title="User Management"/>
        <div className="user-management-content">
          {hasWriteAccess &&
            <><div className="add-user-section">
              <h3 className="add-user-title">Add a User</h3>
              <div className="add-user-info">
                <div className="add-user-field-container">
                  <Input className={`add-user-field${errors.email ? " error" : ""}`}
                    name={NewUserProps.email}
                    label="Email"
                    placeholder="Email"
                    value={(changes && changes[NewUserProps.email]) || ""}
                    onChange={onInputChange}
                    onFocus={()=> setErrors({})}
                    required />
                  {errors.email && <div className="error-message">{errors.email}</div>}
                </div>
                <div className="add-user-field-container">
                  <Input className={`add-user-field${errors.firstName ? " error" : ""}`}
                    name={NewUserProps.firstName}
                    label="First Name"
                    placeholder="First Name"
                    value={(changes && changes[NewUserProps.firstName]) || ""}
                    onChange={onInputChange}
                    onFocus={()=> setErrors({})}
                    required />
                  {errors.firstName && <div className="error-message">{errors.firstName}</div>}
                </div>
                <div className="add-user-field-container">
                  <Input className={`add-user-field${errors.lastName ? " error" : ""}`}
                    name={NewUserProps.lastName}
                    label="Last Name"
                    placeholder="Last Name"
                    value={(changes && changes[NewUserProps.lastName]) || ""}
                    onChange={onInputChange}
                    onFocus={()=> setErrors({})}
                    required />
                  {errors.lastName && <div className="error-message">{errors.lastName}</div>}
                </div>
              </div>
              <div className="module-access read">
                <div className="module-access-label">
                  <label className="required">Module Access: Read</label>
                  <button
                    className="select-all-btn btn-red-simple"
                    type="button"
                    name={NewUserProps.readAccess}
                    onClick={onSelectAll}
                  >
                    Select All
                  </button>
                </div>
                <CheckboxGroup property={NewUserProps.readAccess}
                  onChange={onChangePermissions}
                  values={(changes && changes[NewUserProps.readAccess]) || []}
                  options={ModuleAccessOptions}
                  className="module-access-options" />
                {errors.readAccess && <div className="error-message">{errors.readAccess}</div>}
              </div>
              <div className="module-access write">
                <div className="module-access-label">
                  <label className="required">Module Access: Write</label>
                  <button
                    className="select-all-btn btn-red-simple"
                    name={NewUserProps.writeAccess}
                    onClick={onSelectAll}
                  >
                    Select All
                  </button>
                </div>
                <CheckboxGroup property={NewUserProps.writeAccess}
                  onChange={onChangePermissions}
                  values={(changes && changes[NewUserProps.writeAccess]) || []}
                  options={ModuleWriteAccessOptions}
                  className="module-access-options" />
                {errors.writeAccess && <div className="error-message">{errors.writeAccess}</div>}
              </div>
            </div><div className="add-user-validation-btns">
                <Button look='secondary' className="btn-red cancel validation-btn" text="Cancel" onClick={onCancel} />
                <AddUserButton
                  changes={changes}
                  getPermissions={getPermissions}
                  onCompleted={() => fetchData()}
                  onError={onError}
                  onSubmit={onSubmit}
                  isValidInput={isValidInput}
                  setChanges={() => clearData()} />
              </div></>
          }

          {/* <Fetch url={getUrlWithOptions("/ops/users")} onSuccess={onSuccess}>
            {(loading, fetchErrors, data, status, refetch) => {
              setUsers(data)
              return ( */}
                <React.Fragment>
                  {/* {loading && <Loading />} */}
                  
                  <div className="user-management-table">
                    <div key={"user-scroll-bar"} className="scroll-bar" ref={scrollBar} onScroll={() => {
                      if (userTable.current && scrollBar.current) {
                        userTable.current.scrollLeft = scrollBar.current.scrollLeft;
                      }
                    }}><div style={{ width: scrollBarContentWidth }}></div></div>
                    <div key={"user-table-content"} className="table-content" ref={userTable} onScroll={() => {
                      if (userTable.current && scrollBar.current) {
                        scrollBar.current.scrollLeft = userTable.current.scrollLeft;
                      }
                    }}>
                      <Row key="row-column-header" ref={tableHeaderRow}>
                        {/* <div className="row column-headers" > */}
                          <div className="column-header">Users</div>
                          <div className="column-header">Last Login</div>
                          {
                            ModuleAccessOptions.map((option) => {
                              return (
                                <div key={option.value} className="column-header">{option.label}</div>
                              );
                            })
                          }
                        {/* </div> */}
                      </Row>
                      {
                        users?.items?.map((user) => {
                          return (
                            <UserRow key={user.id} user={user} onDeleteUser={()=>{onDeleteUser(user.id);}} hasWriteAccess={hasWriteAccess} />
                          );
                        })
                      }
                    </div>
                  </div>
                </React.Fragment>
               {/* )} */}
            {/* } */}
          {/* </Fetch> */}
        </div>
      </div>
    </div>
    </>
  );
}

export default UserManagement;
