/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from "react";
import { Flex } from "@brightcove/studio-components";

import { formatDate, formatTimeStamp, Toaster} from "../../utils";
import {
  PlatformData,
  ScheduledTextPanelData,
  StatusData,
} from "../../interfaces/ScheduledTextPanel";
import Panel from "../../components/Panel/Panel";
import SimpleSelect from "../../components/ControlledComponents/SimpleSelect";
import { RadioGroup } from "../../components/ControlledComponents/RadioGroup";
import Button from "../../components/Button/Button";
import { DateTimePickerComponent } from "../../components/ControlledComponents/DateTimePicker";

interface MaintenanceModeCreateProps extends ScheduledTextPanelData {
  setShowCreateModal?: (val: boolean) => void;
  showDeleteModal?: (val: boolean, id: string) => void;
  onCreate: (val: ScheduledTextPanelData, id?: string) => void;
  setEditingId: (val: string) => void;
}

const MaintenanceModeCreate: FC<MaintenanceModeCreateProps> = ({
  id = "",
  status,
  start_time,
  end_time,
  message,
  setEditingId,
  onCreate,
  setShowCreateModal,
  showDeleteModal,
  scope,
}) => {
  const editing = !!id.length;
  const [currentStartTime, setCurrentStartTime] = useState<string | null>(editing? start_time : null);
  const [currentEndTime, setCurrentEndTime] = useState<string | null>(editing? end_time : null);
  const [currentStartDate, setCurrentStartDate] = useState<string | null>(editing? start_time : null);
  const [currentEndDate, setCurrentEndDate] = useState<string | null>(editing? end_time : null);
  const [currentStatus, setCurrentStatus] = useState<"Active" | "Inactive">(editing ? status : "Inactive");
  const [currentPlatform, setCurrentPlatform] = useState(editing ? scope : "web");
  const platformOptions = [{"label":"Web", "value":"web"}, {"label":"iOS", "value":"iOS"}, {"label":"Android", "value":"android"}];
  const [currentMessages, setCurrentMessages] = useState(message);
  const [selectedMessageValue, setSelectedMessageValue] = useState("");

  useEffect(() => {
    const messageValue = currentMessages;
    setSelectedMessageValue(messageValue?.length ? messageValue : "");
  }, [currentMessages]);

  const areDatesValid = () => {
    const startTime = currentStartTime && new Date(currentStartTime);
    const endTime = currentEndTime && new Date(currentEndTime);
   
    const formattedStartDate = formatTimeStamp(currentStartDate);
    const formattedEndDate = formatTimeStamp(currentEndDate);
    let areDatesValid = true;
    if (!formattedStartDate || !formattedEndDate) {
      Toaster.warn({
        message: "Please enter start date and end date",
      });
      areDatesValid = false;
    }
   
    if (formattedStartDate && formattedEndDate && formattedStartDate > formattedEndDate) {
      Toaster.warn({
        message: 'Start date/time should be less than end date/time',
      });
      areDatesValid = false;
    }

    return areDatesValid;
  };

  const createMaintenanceMode = () => {
   const formattedStartDate = formatTimeStamp(currentStartDate);
    const formattedEndDate = formatTimeStamp(currentEndDate);
    
    if (areDatesValid() === true) {
      onCreate(
        {
          status: currentStatus,
          scope: currentPlatform,
          start_time: formattedStartDate ,
          end_time: formattedEndDate ,
          message: currentMessages,
        },
        id
      );
   } 
  };

  const onChangeDatePickerStartDate = (val: string): void => {
    setCurrentStartDate(formatDate(val));
  }

  const onChangeDatePickerEndDate = (val: string): void => {
    setCurrentEndDate(formatDate(val));
  }

  const onChangeDatePickerStartTime = (val: string): void => {
    setCurrentStartTime(val);
  }

  const onChangeDatePickerEndTime = (val: string): void => {
    setCurrentEndTime(val);
  }

  return (
    <Panel
      title={<p>Maintenance Mode</p>}
      actions={
        <>
          {editing ? (
            <>
              <Button
                variant="secondary-invert"
                text="Cancel"
                onClick={() => {
                  setEditingId("");
                }}
              />
              <Button
                variant="primary"
                text="Save"
                onClick={createMaintenanceMode}
                disabled={!currentMessages}
              />
              {/* <Button
                variant="secondary"
                text="Delete"
                onClick={() => {
                  if (editing && showDeleteModal) {
                    showDeleteModal(true, id);
                  }
                }}
              /> */}
            </>
          ) : (
            <>
              <Button
                variant="secondary-invert"
                text="Cancel"
                onClick={() => {
                  if (setShowCreateModal) {
                    setShowCreateModal(false);
                  }

                  setEditingId("");
                }}
              />
              <Button
                variant="primary"
                text="Save"
                // disabled={!currentMessages}
                onClick={createMaintenanceMode}
              />
            </>
          )}
        </>
      }
    >
      <>
        <Flex style={{ justifyContent: "space-between" }}>
          <Flex style={{ flexDirection: "column", flex: 3 }}>
            <Flex>
                <div className="Maintenance-mode-create">
                <p className="Maintenance-mode-create-title">Maintenance Mode</p>
                <RadioGroup
                  value={currentStatus}
                  options={[
                    { label: "Active", value: "Active" },
                    { label: "Inactive", value: "Inactive" },
                  ]}
                  onChange={(value) => {
                    setCurrentStatus(value as StatusData);
                  }}
                />
              </div>
              <div className="Maintenance-mode-create">
                <p className="Maintenance-mode-create-title">Platform</p>
                <SimpleSelect
                  className="select-input-lg mr-2"
                  value={currentPlatform}
                  options={platformOptions}
                  onChange={(value) =>
                    setCurrentPlatform(value as PlatformData)
                  }
                />
              </div>
            </Flex>
            <Flex>
              <div className="Maintenance-mode-create">
                <p className="Maintenance-mode-create-title">Message</p>
                <textarea
                  className="Maintenance-mode-create-body"
                  value={selectedMessageValue}
                  onChange={(e) => {
                    setCurrentMessages(e.target.value);
                  }}
                ></textarea>
              </div>
            </Flex>
          </Flex>
          <Flex>
            <div className="Maintenance-mode-create">
              <p className="Maintenance-mode-create-title">Date & Time</p>
              <div className="Maintenance-mode-create-content">
              <p className="Maintenance-mode-create-title"></p>
                <DateTimePickerComponent
                  placeholder={"Start Date and Time"}
                  onChangeDatePicker={onChangeDatePickerStartDate}
                  value={currentStartDate ? currentStartDate : undefined}
                  editing={editing}
                  time={false}
                />
                </div>
                <div className="Maintenance-mode-create-content">
                <p className="Maintenance-mode-create-title"></p>
                <DateTimePickerComponent
                  placeholder={"End Date and Time"}
                  onChangeDatePicker={onChangeDatePickerEndDate}
                  value={currentEndDate ? currentEndDate : undefined}
                  editing={editing}
                  time={false}
                />
              </div>
            </div>
          </Flex>
        </Flex>
      </>
    </Panel>
  );
};
export default MaintenanceModeCreate;
