/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Flex, Icon, Thumbnail } from "@brightcove/studio-components";

import * as ROUTES from "../../routes";
import { GenericNameId } from "../../../interfaces";
import TitleDescription from "../../../components/TitleDescription/TitleDescription";
import Table from "../../../components/Table/Table";
import Panel from "../../../components/Panel/Panel";

import "./CollectionsContained.scss";
import Button from "../../../components/Button/Button";
import { AddItemModal, withModal } from "../../../components/Modals";
import { formatDate, getPageCount, getUrlWithOptions } from "../../../utils";
import { useApi } from "../../../hooks/useApi";
import Loading from "../../../components/Loading/Loading";
import EditPanel from "../../../components/Panel/EditPanel";
import AddRelatedContentModal from "../../../components/Modals/AddRelatedContentModal";

interface RelatedContentProps {
  idPanel: string;
  data: GenericNameId[];
  contentId: string;
  onSavedData: () => void;
  disabledEditing?: boolean;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
}
const AddCollectionItemButton = withModal(Button, AddRelatedContentModal);

const RelatedResource: FC<RelatedContentProps> = ({
  idPanel,
  data,
  contentId,
  onSavedData,
  disabledEditing,
  onEditModeChange,
}) => {
  const ALL_CONTENT = "allContent";
  const FILTER_DEFAULT = "";
  const SORT_DEFAULT = "updated_at:dsc";
  const VALID_CONTENT_SORT_FIELDS = {
    Name: "name",
    id: "id",
    Created: "created_at",
    Type: "collection_type",
    Updated: "updated_at",
    published: "published",
  };
  const VALID_SORT_FIELDS = {
    Name: "Name/Title",
    content_type: "content_type",
    status: "status",
    Created: "created_at",
    Updated: "updated_at",
    published: "published",
  };
  const navigate = useNavigate();
  const [editing, setEditing] = useState<boolean>(false);
  const { apiGet, apiPatch } = useApi();
  const [contentTotalCount, setTotalCount] = useState(0);
  const [contentSearch, setContentSearch] = useState<string>("");
  const [contentPageSize, setContentPageSize] = useState(20);
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [contentPageIndex, setContentPageIndex] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [, setCurrentFilter] = useState(FILTER_DEFAULT);
  const [contentSort, setContentSort] = useState<string>(SORT_DEFAULT);
  const [selectedCollectionRows, getSelecetdCollectionRows] = useState([]);
  const [collectionsData, setCollectionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort] = useState<string>("updated_at:dsc");
  const [collectionItems, setCollectionItems] = useState<any[]>([]);
  const [disableEditing, setDisableEditing] = useState(true);
  const [contentContainedData, setContentContainedData] = useState(data);
  const [contentData, setContentData] = useState(contentContainedData);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [contentDataFlag, setContentDataFlag] = useState(false)
  let newCollectionItems: any = [];
  // const [currentPageSize, setCurrentPageSize] = useState(50);

  const onContentSearch = (val: string) => setContentSearch(val);
  useEffect(() => {
    (async () => {
      if (!collectionsData) {
        setLoading(true);
      }

      const { data } = await apiGet(
        getUrlWithOptions(
          "/ops/content",
          contentSearch,
          contentSort,
          undefined,
          contentPageIndex,
          contentPageSize
        )
      );
      setCollectionsData(data?.results?.items);
      setTotalCount(data?.results?.totalCount);
      if (data?.results?.totalCount <= contentPageIndex * contentPageSize  && contentSearch) {
        setTotalCount(data?.results?.totalCount);
        setContentPageIndex(0)
      } else if (data?.results?.totalCount > (contentPageIndex * contentPageSize) && contentSearch){
        setTotalCount(data?.results?.totalCount);
        let pageCount: number = Math.ceil(data?.results?.totalCount / contentPageSize);
        pageCount<= contentPageIndex?   setContentPageIndex(pageCount) : setContentPageIndex(contentPageIndex);
      }
      else {
        setTotalCount(data?.results?.totalCount);
      }
      setLoading(false);
    })();
  }, [contentSearch, contentSort, contentPageIndex, contentPageSize, contentDataFlag]);

  const onAddCollectionItem = async (): Promise<any> => {
    const newCollectionItems = collectionItems.concat(selectedCollectionRows);
    // const contentId = selectedRows.map((item: any) => item.id).join(",");
    const unique = contentData.filter((obj:any, index) => {
      return (
        index === contentData.findIndex((o: any) => obj?.video_id === o?.video_id)
      );
    });
    setContentContainedData(unique)
    setContentData(unique)
    const { data } = await apiGet(
      getUrlWithOptions(`/ops/content/${contentId}`)
    );
    let bodyString: any = [];
    unique.map((item: any) => {
      bodyString.push({
        name: item.display_name || item.name,
        id: item.video_id,
      });
    });
    // }
    const body = {
      related_content: bodyString,
    };
    const response = await apiPatch(
      getUrlWithOptions(`/ops/content/${contentId}`),
      {
        body,
      }
    );

    if (response?.data) {
      // onEditModeChange?.(!disabledEditing, true);
      setEditing(false);
      toggleDisableEditing();
      onSavedData();
      setContentContainedData(response?.data?.related_content)
      setContentData(response?.data?.related_content)
    }
    // setCollectionItems(newCollectionItems);

    return {};
  };
  const collectionFilters = [
    {
      iconName: "FILM",
      name: "Videos",
      id: ALL_CONTENT,
    },
  ];
  const onChangeSelection = (items) =>{
    setSelectedItems(items);
  }

  const onCancel = () => {
    // setEditing(false);
    // onEditModeChange?.(!disabledEditing, true);
    setContentSearch('')
  };
  const getAllContentFilter = () => {
    let allContentFilter = "";
    return allContentFilter;
  };

  const onChangeFilter = (id: string) => {
    const filter = id.search(ALL_CONTENT) >= 0 ? getAllContentFilter() : id;

    setCurrentPageIndex(0);
    setCurrentFilter(filter);
  };
  const onClickContentSort = (field, order) => {
  if(field === 'Video title') {
    setContentSort(
      `name:${order.toLocaleLowerCase()}`
    );
  } else if (field && order) {
    setContentSort(`${VALID_SORT_FIELDS[field]}:${order.toLocaleLowerCase()}`);
  }
  };
  const onContentPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setContentPageIndex(pageIndex);
    setContentPageSize(pageSize);
  }, []);

  const onAddContentItem = () => {
    newCollectionItems = contentData?.concat(selectedItems);
    setContentData(newCollectionItems);
  };
  const columns = [
    {
      Header: "",
      accessor: "images",
      Cell: ({ value , row}) => (
        <Thumbnail className="Content-management-thumbnail" src={row?.original?.images?.thumbnail?.src} />
      ),
    },
    {
      Header: "Content",
      //sortable: true,
      accessor: "name",
      Cell: ({ value, row }) => (
        <TitleDescription
          title={value}
          description={row.original.id}
          id={row.original.id}
          // onClick={(id) =>
          //   navigate(`../${ROUTES.COLLECTIONS}/edit/${id}`)
          // }
          type={"noClickable"}
        />
      ),
    },
    {
      Header: "Type",
      accessor: "",
      //sortable: true,
      Cell: ({ value,row }) => (
        <>
          <div>{row?.original?.custom_fields?.content_type}</div>
        </>
      ),
      className: "type",
    },
    {
      Header: "Created",
      accessor: "created_at",
      //sortable: true,
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.createdBy}</div>
        </>
      ),
      className: "date",
    },
    {
      Header: "Updated",
      accessor: "updated_at",
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.updatedBy}</div>
        </>
      ),
     // sortable: true,
      className: "date",
    },
    {
      Header: "Status",
      accessor: "state", // accessor is the "key" in the data
      Cell: ({ value }) => {
        return (
          <Flex justifyContent="center">
            <Icon name={"check2"} color={"blue"} />
          </Flex>
        );
      },
      //sortable: true,
      className: "Content-management-published",
    },
  ];
  const contentcolumns = [
    {
      Header: "Videos",
      accessor: "image",
      Cell: ({ value }) => (
        <Thumbnail className="Content-management-thumbnail" src={value} />
      ),
    },
    {
      Header: "Video title",
      accessor: (row) => [row.display_name || row.name, row.id], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [name, id] = value;

        return (
          <TitleDescription
            title={name}
            description={id}
            id={id}
            onClick={(itemId) =>
              navigate(`../${ROUTES.CONTENT_MANAGEMENT}/edit/${itemId}`)
            }
            type={"noClickable"}
          />
        );
      },
      sortable: true,
    },
    {
      Header: "Status",
      accessor: "status", // accessor is the "key" in the data
      Cell: ({ value }) => {
        return (
          <Flex justifyContent="center">
            <Icon name={"check2"} color="#3886F7" />
          </Flex>
        );
      },
      sortable: true,
      className: "Content-management-published",
    },
    {
      Header: "Created",
      sortable: true,
      accessor: (row) => [row.created_at], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [createdAt] = value;

        return (
          <TitleDescription
            classNameTitle="Content-management-column-updated-item-title"
            classNameDescription="Content-management-column-updated-item-description"
            title={formatDate(createdAt)}
            // description={email}
          />
        );
      },
    },
    {
      Header: "Updated",
      sortable: true,
      accessor: (row) => [row.updated_at], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [updated] = value;

        return (
          <TitleDescription
            classNameTitle="Content-management-column-updated-item-title"
            classNameDescription="Content-management-column-updated-item-description"
            title={formatDate(updated)}
            // description={email}
          />
        );
      },
    },
  ];
  const toggleDisableEditing = () => setDisableEditing(!disableEditing);

  const onPaginationChange = ({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  };

  const onClickDeleteRow = (id) => {
    setContentData((prev) => prev.filter((item: any) => item.id !== id));
  };
  const onDragRow = (reorderedRows) => setContentData(reorderedRows);
  const onClickSort = (field, order) => {
    if (field && order) {
      setContentSort(
        `${VALID_CONTENT_SORT_FIELDS[field]}:${order.toLocaleLowerCase()}`
      );
    }
  };
  return (
    <div id={idPanel} className="CollectionsContained">
      <Panel
        // id="items"
        title="Related content"
        actions={
          <>
            {!disableEditing ? (
              <>
                <Button
                  variant="secondary-invert"
                  text="Cancel"
                  onClick={() => {
                    // onSavedData?.();
                    setContentData(data);
                    toggleDisableEditing();
                  }}
                />
                <Button
                  variant="primary"
                  text="Save"
                  onClick={() => {
                    onAddCollectionItem();
                  }}
                  // disabled={isLoading}
                />
              </>
            ) : (
              <Button
                variant="secondary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                  toggleDisableEditing()
                  setContentDataFlag(true)
                }}
                disabled={disabledEditing}
              />
            )}
          </>
        }
      >
        {/* {(isEditing) => ( */}
          <div className="">
              <Table
                className=""
                data={contentData?.length ? contentData : []}
                columns={columns}
                // onChangeSelection={onChangeSelection}
                onClickSort={onClickSort}
                // hasSelection
                // defaultSortingValues={[{ id: 'updated_at', desc: true }]}
                hasPagination
                pageCount={Math.ceil(contentData?.length / currentPageSize)}
                onPaginationChange={onPaginationChange}
                pageIndex={currentPageIndex}
                pageSize={currentPageSize}
                totalCount={contentData?.length}
                onClickDeleteRow={onClickDeleteRow}
                onDragRow={onDragRow}
                hasDeletion={!disableEditing}
                hasDragging={!disableEditing}
                manualPagination={false}
              />

      {!disableEditing && (
        <AddCollectionItemButton
          className="ml-5 mb-5 mt-2"
          variant="link"
          // onClick={() => toggleDisableEditing}
          modalProps={{
            title: "Add related content",
            addButtonText: "Add Related Content",
            searchPlaceholder: "Search",
            filters: collectionFilters,
            data: collectionsData || [],
            columns: contentcolumns,
            pageCount: getPageCount(contentTotalCount, contentPageSize),
            pageIndex: contentPageIndex,
            pageSize: contentPageSize,
            hasPagination: true,
            onChangeFilter: onChangeFilter,
            onClickSort: onClickContentSort,
            onSearch: onContentSearch,
            onChangeSelection,
            onPaginationChange: onContentPaginationChange,
            onAddContent: onAddContentItem,
            onCancel,
            totalCount: contentTotalCount,
          }}
        >
          + Add Content Item
        </AddCollectionItemButton>
      )}
          </div>
        {/* )} */}
      </Panel>

    </div>
  );
};

export default RelatedResource;
