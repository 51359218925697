/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { Button, Flex, Input } from "@brightcove/studio-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SVGImage } from "../../assets/images";
import FirebaseAuthenticator from "../../auth/Auth-Firebase";
import * as ROUTES from "../routes";
import "./LoginPage.scss";
import constants from "../../constants";
import { useDispatch } from 'react-redux';
import { login } from "../../store/slices/userSlice";
import { ModuleMap } from "../../utils";

const LoginPage: React.FunctionComponent<any> = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    invalidUser : "",
    password: "",
  });
  const [forgotPassword, setForgotPassword] = useState(false);
  const [success] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const authenticator = new FirebaseAuthenticator();

  const onKeyDown = (event) => {
    if (event.key === "Enter" && canSubmit()) {
      console.log("calling onSubmit");
      onSubmit();
    }
  }

  const isValidEmail = (emailString) => {
    console.log("validating emailString", emailString);
    console.log(emailString.match(/^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/));
    return emailString.match(/^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  }

  const canSubmit = () => {
    console.log(" in canSubmit");
    if (forgotPassword) {
      return email.length > 0;
    } else {
      return email.length > 0 && password.length > 0;
    }
  }

  const onChangeFn = (setFieldFunction) => {
    return (value) => {
      console.log("changed valu is", value);
      setFieldFunction(value);
      setErrors({
        email: "",
        invalidUser : "",
        password: "",
      });
    }
  }

  const onSubmit = () => {
    console.log("onSubmit called");
    if (!isValidEmail(email)) {
      setErrors({
        ...errors,
        email : "Please enter a valid email address",
      });
    // } else if (!password) {
    //   errors["password"] = "Please enter a password";
    //   setErrors({ errors });
    // }
    } else {
      console.log("CALLING AUTH WITH ->>>>", email, password);
      authenticator.authenticate(email, password, handleAuthSuccess, handleAuthError);
    }
  }

  const handleAuthSuccess = (result) => {
    console.log("in handleAuthSuccess res is", result);
    if (result) {
      setErrors({
        email: "",
        invalidUser : "",
        password: "",
      });
      console.log('before dispatch', result);
      dispatch(login(result));
      console.log('before redirect');
      redirectToHome(result?.permissions);
    } else {
      setPassword("");
    }
  }

  const handleAuthError = (error) => {
    console.log("in handleAuthError error is", error);
    if (error) {
      let errorMessage = '';
      // Error codes reference for auth call - https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_12
      switch(error.code) {
        case 'auth/user-not-found' :
          errors.invalidUser = constants.ERROR_MESSAGES.INVALID_EMAIL;
          break;
        case 'auth/wrong-password' :
          errors.password = constants.ERROR_MESSAGES.INVALID_PASSWORD;
          break;
        case 'auth/invalid-email' :
        case 'auth/user-disabled' :
        default :
          // errorMessage = error.message; // pass on the message configured at firebase using error.message
          break;
      }
      // errors.invalidUser = errorMessage;
      setErrors({
        ...errors,
      });
    } else {
      setPassword("");
    }
  }

  const toggleForgotPassword = () => {
    // this.setState({ forgotPassword: !this.state.forgotPassword, email: "", password: "", errors: {} });
    setForgotPassword(!forgotPassword);
    setEmail("");
    setPassword("");
    setErrors({
      email: "",
      invalidUser : "",
      password: "",
    });
  }

  const onClickSendLink = () => {
    if (!isValidEmail(email)) {
      setErrors({
        ...errors,
        email : "Please enter a valid email address",
      });
    } else {
      authenticator.initiateResetPassword(email, (result) => {
        if (result) {
          setForgotPassword(false);
          setEmail("");
          setErrors({
            email: "",
            invalidUser : "",
            password: "",
          });
        }
      },
      (error) => {
        if (error) {
          let errorMessage = '';
          // possible error codes for triggering password reset email - https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_6
          switch(error.code) {
            case 'auth/user-not-found' :
            case 'auth/invalid-email' :
              errorMessage = 'Username not found.';
              break;
            default :
              errorMessage = error.message; // pass on the message configured at firebase using error.message
              break;
          }
          errors.invalidUser = errorMessage;
          setErrors({
            ...errors,
          });
        }
      });
    }
  }
  const checkTabOnPermission = (permissions) => {
    const moduleArr = ['SUBSCRIPTION', 'USER', 'MAINTENANCE', 'STRINGS', 'ACTIVITY', 'PAGE', 'CHANNELS', 'CONTENT']
    moduleArr.map((moduleName)=>{
      if (permissions.hasOwnProperty(moduleName)){
        navigate(ModuleMap?.[moduleName]);
      }
    })
  }

  
  const redirectToHome = (permissions) => {
    console.log('redirecting to hom');
    checkTabOnPermission(permissions);
    if (Object.keys(permissions)?.length === undefined || Object.keys(permissions)?.length === 0) {
      navigate(ROUTES.HOME);
    }
  }

  return (
    <div className="LoginPage">
      <div className="loginContent">
        <img className="login-logo" src={SVGImage.One31Logo} alt="One31 Logo" />
        {
          success ?
            null :
            <React.Fragment>
              <div className="sign-in-error">{errors.invalidUser}</div>
              {!forgotPassword ?
                <>
                  <p className="heading">Sign In</p>
                  <Input className={`sign-in-field${errors.email ? " error" : ""}`} label="Email" placeholder="Email" value={email} onChange={onChangeFn(setEmail)} required />
                  <div className="sign-in-error sub-error">{errors.email}</div>
                  <span onKeyDown={onKeyDown}>
                    <Input className={`sign-in-field${errors.password ? " error" : ""}`} label="Password" placeholder="Password" type="password" value={password} onChange={onChangeFn(setPassword)} required />
                    <div className="sign-in-error sub-error">{errors.password}</div>
                  </span>
                  <div>
                    <button className="secondary-btn" onClick={toggleForgotPassword}>Forgot Password</button>
                  </div>
                  <Flex gap="no" className="sign-in-btn" justifyContent="center">
                    <Button className={canSubmit() ? "red" : ""} text="Sign In" onClick={onSubmit} disabled={!canSubmit()} />
                  </Flex>
                </>
                : <></>
              }
              {
                forgotPassword && (
                  <div className="forgotPasswordContent">
                    <p className="heading">Forgot Password</p>
                    <Input className={`sign-in-field${errors.email ? " error" : ""}`} label="Email" placeholder="Email" value={email} onChange={onChangeFn(setEmail)} required />
                    <div className="sign-in-error sub-error">{errors.email}</div>
                    <Flex className="sign-in-btn" justifyContent="center">
                      <Button className={canSubmit() ? "red" : ""} small text="Send link" onClick={onClickSendLink} disabled={!canSubmit()} />
                    </Flex>
                    <button className="secondary-btn" onClick={toggleForgotPassword}>Back to sign in</button>
                  </div>
                )
              }
            </React.Fragment>
        }
      </div>
    </div>
  )
};

export default LoginPage;
