/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router";
import React, { FC, useEffect, useState } from "react";
import _ from "lodash";
import { Flex } from "@brightcove/studio-components";

import Button from "../Button/Button";
import {
  Toaster,
  formatDate,
  formatTimeStamp,
  getUrlWithOptions,
  isValidDate,
} from "../../utils";
import { AvailabilityData } from "../../interfaces";
import { useApi } from "../../hooks/useApi";

import Panel from "./Panel";
import "./AvailibilityDate.scss";
import { DateTimePickerComponent } from "../ControlledComponents/DateTimePicker";

interface AvailabilityDateProps {
  idPanel: string;
  data: AvailabilityData;
  endpointRootPath: string;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
  disableEditing?: boolean;
}

const AvailabilityDate: FC<AvailabilityDateProps> = ({
  idPanel,
  data,
  endpointRootPath,
  onEditModeChange,
  disableEditing,
}) => {
  const { id } = useParams();
  const [availabilityPanelData, setAvailabilityPanelData] = useState<any>(
    data || {}
  );
  const { apiPatch, isLoading } = useApi();
  const [editing, setEditing] = useState<boolean>(false);
  const [savedData, setSavedData] = useState(false);
  const [defaultData, setDefaultData] = useState<any>({});
  const [currentStartDateString, setCurrentStartDateString] =
    useState<string>("");
  const [currentEndDateString, setCurrentEndDateString] = useState<string>("");
  const [currentStartTime, setCurrentStartTime] = useState<string | null>("");
  const [currentEndTime, setCurrentEndTime] = useState<string | null>("");
  const [currentStartDate, setCurrentStartDate] = useState<any>("");
  const [currentEndDate, setCurrentEndDate] = useState<any>("");

  const areDatesValid = () => {
    // const startTime = currentStartTime && new Date(currentStartTime);
    // const endTime = currentEndTime && new Date(currentEndTime);
    const formattedStartDate = formatTimeStamp(currentStartDate);
    const formattedEndDate = formatTimeStamp(currentEndDate);

    let areDatesValid = true;
    if (!formattedStartDate || !formattedEndDate) {
      Toaster.warn({
        message: "Please enter start date and end date",
      });
      areDatesValid = false;
    }
    if (
      formattedStartDate &&
      formattedEndDate &&
      new Date(formattedStartDate) > new Date(formattedEndDate)
    ) {
      Toaster.warn({
        message: "Start date/time should be less than end date/time",
      });
      areDatesValid = false;
    }

    return areDatesValid;
  };

  const onSaveAvailability = async () => {
    if (!areDatesValid()) {
      return;
    }
    // const startTime = currentStartTime && new Date(currentStartTime);
    // const endTime = currentEndTime && new Date(currentEndTime);
    const formattedStartDate = formatTimeStamp(currentStartDate);
    const formattedEndDate = formatTimeStamp(currentEndDate);
    const body = {
      start_date: formattedStartDate || null,
      end_date: formattedEndDate || null,
    };

    const response = await apiPatch(
      getUrlWithOptions(`${endpointRootPath}/${id}`),
      {
        body,
      }
    );

    if (response?.data) {
      setEditing(false);
      setSavedData(true);
      setCurrentStartDateString(response?.data?.start_date || today);
      setCurrentEndDateString(response?.data?.end_date || today);
    }
  };

  useEffect(() => {
    if (!_.isEqual(availabilityPanelData, data)) {
      setAvailabilityPanelData(data);
      setDefaultData(data);
    }
  }, [data]);

  useEffect(() => {
    const { start_date, end_date, status, offlineAccess, availability } =
      availabilityPanelData;

    setCurrentStartDateString(start_date || "");
    setCurrentEndDateString(end_date || "");
    setCurrentStartDate(start_date || "");
    setCurrentEndDate(end_date || "");
    setCurrentStartTime(start_date || "");
    setCurrentEndTime(end_date || "");
  }, [availabilityPanelData]);

  useEffect(() => {
    onEditModeChange && onEditModeChange(editing, savedData);
  }, [editing]);

  const onChangeDatePickerStartDate = (val: any): void => {
    val && setCurrentStartDate(formatDate(val));
  };

  const onChangeDatePickerEndDate = (val: any): void => {
    val && setCurrentEndDate(formatDate(val));
  };

  const onChangeDatePickerStartTime = (val: string): void => {
    setCurrentStartTime(val);
  };

  const onChangeDatePickerEndTime = (val: string): void => {
    setCurrentEndTime(val);
  };
  const today = new Date();

  return (
    <div id={idPanel} className="AvailabilityDate">
      <Panel
        title="Availability"
        actions={
          <>
            {editing ? (
              <>
                <Button
                  variant="secondary-invert"
                  text="Cancel"
                  onClick={() => {
                    setEditing(false);
                    setCurrentStartDate(data?.start_date || "");
                    setCurrentEndDate(data?.end_date || "");
                  }}
                />
                <Button
                  variant="primary"
                  text="Save"
                  onClick={onSaveAvailability}
                  disabled={isLoading}
                />
              </>
            ) : (
              <Button
                variant="secondary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                  // onEditModeChange();
                }}
                disabled={disableEditing}
              />
            )}
          </>
        }
      >
        <>
          {editing ? (
            <>
              <Flex>
                <div className="">
                  <p className="title">Start date</p>
                  <Flex>
                    <DateTimePickerComponent
                      index="0"
                      placeholder={"Start Date and Time"}
                      onChangeDatePicker={onChangeDatePickerStartDate}
                      value={currentStartDate ? currentStartDate : undefined}
                      editing={editing}
                      time={false}
                    />
                  </Flex>
                </div>
                <Flex
                  flexDirection="column"
                  justifyContent="space-between"
                  className="ml-12"
                >
                  <div className="ml-5">
                    <p className="title ">End date</p>
                    <Flex>
                      <DateTimePickerComponent
                        index="2"
                        placeholder={"End Date and Time"}
                        onChangeDatePicker={onChangeDatePickerEndDate}
                        value={currentEndDate ? currentEndDate : undefined}
                        editing={editing}
                        time={false}
                      />
                    </Flex>
                  </div>
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex>
              <div className="">
                <p className="title">Start Date</p>
                <p className="description">
                  {formatDate(currentStartDateString)}
                </p>
              </div>
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                className="ml-12"
              >
                <div className="ml-5">
                  <p className="title">End Date</p>
                  <p className="description">
                    {formatDate(currentEndDateString)}
                  </p>
                </div>
              </Flex>
            </Flex>
          )}
        </>
      </Panel>
    </div>
  );
};

export default AvailabilityDate;
