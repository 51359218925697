import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import './SideNavigation.scss';
import { Icon } from '@brightcove/studio-components';

interface SideNavigationProps {
  item: React.ComponentType<any>;
  filters: any[];
  className?: string;
  defaultValue?: string;
  onChange: (param: string) => void;
}

const SideNavigation: FC<SideNavigationProps> = ({
  className,
  onChange,
  item: Item,
  filters,
  defaultValue,
}) => {
  const [selected, setSelected] = useState<string>('');
  useEffect(() => {
    if (defaultValue !== undefined) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className={classnames('Side-navigation', className)}>
      {filters.map((filter: any) => {
        return (
          <Item
            key={filter.id}
            onClick={(e: React.MouseEvent<any>) => {
              setSelected(e.currentTarget.id);
              onChange(e.currentTarget.id);
            }}
            name={filter.name}
            id={filter.id}
            icon={
              filter.iconName ? (
                <Icon name={filter.iconName} />
              ) : filter.image ? (
                <img src={filter.image} alt={`${filter.name} Icon`} className={
                  `${selected === filter.id ? "" : "noselect"}`
                }/>
              ) : (
                ''
              )
            }
            selected={selected === filter.id ? true : false}
          />
        );
      })}
    </div>
  );
};

export default SideNavigation;
