/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import { getNativeLanguage, getLocaleByLanguage, DEFAULT_LANGUAGE, CONTENT_SERIES } from '../../utils';
import { ContentDetailData, ContentLocalesData } from '../../interfaces/Content';
import AddBodyClasses from '../../helpers/AddBodyClasses';
import TableOfContents from '../../components/TableOfContents/TableOfContents';
import Images from '../../components/Panel/Images';
import Availability from '../../components/Panel/Availability';
import LanguageDropdown from '../../components/LanguageDropdown/LanguageDropdown';

import Seasons from './Panels/Seasons';
import LocaleInformation from './Panels/LocaleInformation';
import Episodes from './Panels/Episodes';
import Content from './Panels/Content';
import CollectionsContained from './Panels/CollectionsContained';
import './ContentManagementDetail.scss';

const initialContent = [
  { title: 'Content', id: 'sectionContent', offset: -100 },
  { title: 'Availability', id: 'sectionAvailability', offset: -100 },
  { title: 'Images', id: 'sectionImages', offset: -100 },
  {
    title: 'Locale Information',
    id: 'sectionLocaleInformation',
    offset: -100,
  },
  {
    title: 'Collections Contained In',
    id: 'sectionCollections',
    offset: -150,
  },
];
const CONTENT_PATH = '/content';
const DetailSeriesSeasons = ({ data, localesData, id, onSavedData, onLocaleInformationSave }) => {
  const [currentData, setCurrentData] = useState<ContentDetailData>();
  const [currentLocalesData, setCurrentLocalesData] = useState<ContentLocalesData[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const [currentId, setCurrentId] = useState(id ? id : '');
  const [currentLocaleInformation, setCurrentLocaleInformation] = useState<any>();
  const [currentImageData, setCurrentImages] = useState<any>();
  const [tableOfContentsData, setTableOfContentsData] = useState(initialContent);
  const [disableEditing, setDisableEditing] = useState(false);

  useEffect(() => {
    if (!!currentLanguage && !!currentLocalesData?.length && !!currentData) {
      setCurrentLocaleInformation(
        getDataLocaleInformation(getLocaleByLanguage(currentLocalesData, currentLanguage), currentLanguage)
      );
      setCurrentImages(
        getImageData(
          getLocaleByLanguage(currentLocalesData, currentLanguage)?.images,
          currentId,
          currentLanguage
        )
      );
    }
  }, [currentLanguage]);

  useEffect(() => {
    if (!!data && !!localesData?.length && !!id.length) {
      const nativeLanguage = getNativeLanguage(localesData) || DEFAULT_LANGUAGE;

      if (data.type === CONTENT_SERIES) {
        setTableOfContentsData([
          ...initialContent,
          {
            title: 'Seasons',
            id: 'sectionSeasons',
            offset: -100,
          },
        ]);
      } else {
        setTableOfContentsData([
          ...initialContent,
          {
            title: 'Episodes',
            id: 'sectionEpisodes',
            offset: -100,
          },
        ]);
      }

      setCurrentData(data);
      setCurrentLocalesData(localesData);
      setCurrentId(id);
      setCurrentLanguage(nativeLanguage);
      setCurrentLocaleInformation(
        getDataLocaleInformation(getLocaleByLanguage(localesData, currentLanguage), currentLanguage)
      );
      setCurrentImages(
        getImageData(getLocaleByLanguage(localesData, currentLanguage)?.images, id, currentLanguage)
      );
    }
  }, [data, localesData, id]);

  const getDataAvailability = (contentData: ContentDetailData) => {
    const { start_date, end_date, locations, accessControls } = contentData || {};

    return {
      start_date,
      end_date,
      locations,
      accessControls,
    };
  };

  const getDataContent = (ContentData: ContentDetailData, selectedId: string) => {
    const { reference_id, source, publicUrl, series, season, name, display_name, tags, custom_fields, description, long_description, related_content, genre, cast,director,year,studio } = ContentData || {};

    return {
      id: selectedId,
      reference_id,
      source,
      publicUrl,
      series,
      season,
      name,
      display_name,
      tags,
      custom_fields,
      description,
      long_description,
      related_content,
      genre,
      cast,
      director,
      year,
      studio
    };
  };
  const getImageData = (imagesData: any[], selectedId: string, language: string) => {
    return {
      images: imagesData,
      id: selectedId,
      language,
    };
  };

  const getDataLocaleInformation = (dataLocales: ContentLocalesData, language: string) => {
    const { name, description, cta } = dataLocales || {};

    return { name, description, cta, language };
  };

  // const updateLocales = (updatedLocale, updatedLocales) => {
    // const index = _.findIndex(updatedLocales, (locale) => locale.language === updatedLocale.language);

    // if (index > -1) {
    //   updatedLocales[index] = updatedLocale;
    // } else {
    //   updatedLocales.push(updatedLocale);
    // }

    // setCurrentLocalesData(updatedLocales);
  // };

  const onLanguageChange = (value) => {
    setCurrentLanguage(value);
  };

  const onNativeLanguageChange = (updatedLocale) => {
    // const updatedLocales = currentLocalesData.map((locale) => {
    //   if (locale.language === updatedLocale.language) {
    //     return { ...locale, isNativeLanguage: true };
    //   } else {
    //     return { ...locale, isNativeLanguage: false };
    //   }
    // });

    // updateLocales(updatedLocale, updatedLocales);
    // onLocaleInformationSave();
  };

  return (
    <>
      {currentData && !!currentLocalesData?.length ? (
        <>
          <AddBodyClasses classes={['disabled-overflow']} />
          <span className="ml-11 mb-8">
            <Flex>
              <Flex className="mt-8" flexWrap="wrap" style={{ flex: 'auto' }}>
                <Content idPanel="sectionContent" data={getDataContent(currentData, currentId)} />
                <Availability
                  idPanel="sectionAvailability"
                  data={getDataAvailability(currentData)}
                  endpointRootPath={CONTENT_PATH}
                  onEditModeChange={(editing, isSavedData) => {
                    setDisableEditing(editing);

                    if (isSavedData) {
                      onSavedData();
                    }
                  }}
                  disableEditing={disableEditing}
                />
                <Images
                  idPanel="sectionImages"
                  data={currentImageData}
                  endpointRootPath={CONTENT_PATH}
                  onEditModeChange={(editing, isSavedData) => {
                    setDisableEditing(editing);

                    if (isSavedData) {
                      onSavedData();
                    }
                  }}
                  disableEditing={disableEditing}
                />
                <LocaleInformation
                  idPanel="sectionLocaleInformation"
                  data={currentLocaleInformation}
                  onEditModeChange={(editing, isSavedData) => {
                    setDisableEditing(editing);

                    if (isSavedData) {
                      onLocaleInformationSave();
                    }
                  }}
                  disabledEditing={disableEditing}
                />
                <CollectionsContained
                  idPanel="sectionCollections"
                  data={currentData?.contained_in_collections}
                  contentId={currentId}
                  onSavedData={onSavedData()}
                />
                {currentData.type === CONTENT_SERIES ? (
                  <Seasons
                    idPanel="sectionSeasons"
                    data={currentData?.seasons}
                    onEditModeChange={(editing, isSavedData) => {
                      setDisableEditing(editing);

                      if (isSavedData) {
                        onSavedData();
                      }
                    }}
                    disabledEditing={disableEditing}
                  />
                ) : (
                  <Episodes
                    idPanel="sectionEpisodes"
                    data={currentData?.episodes}
                    onEditModeChange={(editing, isSavedData) => {
                      setDisableEditing(editing);

                      if (isSavedData) {
                        onSavedData();
                      }
                    }}
                    disabledEditing={disableEditing}
                  />
                )}
              </Flex>
              <div className="side-menu pt-8 px-12">
                <Flex
                  className="mb-3 "
                  justifyContent="center"
                  flexDirection="column"
                  style={{ margin: 'auto', width: 235 }}
                >
                  <LanguageDropdown
                    title="Language"
                    currentLanguage={currentLanguage}
                    nativeLanguage={getNativeLanguage(currentLocalesData)}
                    endpointRootPath={CONTENT_PATH}
                    onNativeLanguageChange={onNativeLanguageChange}
                    onLanguageChange={onLanguageChange}
                  />
                </Flex>
                <TableOfContents data={tableOfContentsData} />
              </div>
            </Flex>
          </span>
        </>
      ) : null}
    </>
  );
};

export default DetailSeriesSeasons;
