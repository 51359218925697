const config = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  aws_region: process.env.REACT_APP_AWS_REGION,
  base_mw_url: process.env.REACT_APP_API_URL,
  mw_token: process.env.REACT_APP_API_TOKEN,
  firebase_config_key: process.env.REACT_APP_FIREBASE_CONFIG_KEY,
  firebase_client: process.env.REACT_APP_FIREBASE_CLIENT,
  reactplayer_accountid: process.env.REACT_APP_PLAYER_ACCOUNT_ID,
  redirect_url: process.env.REACT_APP_REDIRECT_URL
};

export default config;
