/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router";
import React, { FC, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  AutosaveTagListSuggestions,
  Flex,
} from "@brightcove/studio-components";

import Button from "../Button/Button";
import { Toaster, formatDate, formatTimeStamp, getUrlWithOptions } from "../../utils";
import { AvailabilityData } from "../../interfaces";
import { useApi } from "../../hooks/useApi";

import Panel from "./Panel";
import "./Availability.scss";
import TextInput from "../ControlledComponents/TextInput";
import { RadioGroupDatePicker } from "../ControlledComponents/RadioGroupDatePicker";
import SimpleSelect from "../ControlledComponents/SimpleSelect";
import countryList from "react-select-country-list";

interface AvailabilityProps {
  idPanel: string;
  data: AvailabilityData;
  endpointRootPath: string;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
  disableEditing?: boolean;
  onSavedData?: () => void;
}

const typeOptions = [
  { label: "Unrestricted", value: "unrestricted" },
  { label: "Allow viewing from the following countries", value: "allow" },
  { label: "Prevent viewing from the following countries", value: "prevent" },
];

const Availability: FC<AvailabilityProps> = ({
  idPanel,
  data,
  endpointRootPath,
  onEditModeChange,
  disableEditing,
  onSavedData,
}) => {
  const suggestions = useMemo(() => countryList().getData(), []);
  const { id } = useParams();
  const [availabilityPanelData, setAvailabilityPanelData] = useState<any>(
    data || {}
  );
  const { apiPatch, isLoading } = useApi();
  const [editing, setEditing] = useState<boolean>(false);
  const [, setCurrentStartDateString] = useState<string>("");
  const [, setCurrentStatus] = useState<string>("");
  const [geoFiltering, setGeoFiltering] = useState<string>("");
  const [, setCurrentAccess] = useState<string>("");
  const [availability, setAvailibility] = useState<string>("");
  const [savedData, setSavedData] = useState(false);
  const [defaultData, setDefaultData] = useState<any>({});
  const [currentStartTime, setCurrentStartTime] = useState<string | null>(
    availabilityPanelData?.start_date ? availabilityPanelData?.start_date : ""
  );
  const [currentEndTime, setCurrentEndTime] = useState<string | null>(
    availabilityPanelData?.end_date ? availabilityPanelData?.end_date : ""
  );
  const [selectedType, setSelectedType] = useState(
    availabilityPanelData?.geo?.exclude_countries === true
      ? "prevent"
      : availabilityPanelData?.geo?.restricted === true
      ? "allow"
      : "unrestricted"
  );
  const initialValue = ["UH-merica"];
  const [value, setValue] = React.useState(
    availabilityPanelData?.geo?.countries
      ? availabilityPanelData?.geo?.countries
      : []
  );
  const [selecteCountryList, setSelectedCountries] = React.useState(
    availabilityPanelData?.geo?.countries
      ? availabilityPanelData?.geo?.countries
      : []
  );
  const getDataAvailability = (contentData) => {
    const { start_date, end_date, geo, locations, accessControls } =
      contentData;

    return {
      start_date,
      end_date,
      locations,
      accessControls,
      geo,
    };
  };
  const [inputState, setInputState] = React.useState<any>({});
  const [availibilityString, setAvailibilityString] =
    useState("Always available");

    const areDatesValid = () => {
      // const startTime = currentStartTime && new Date(currentStartTime);
      // const endTime = currentEndTime && new Date(currentEndTime);
      const formattedStartDate = formatTimeStamp(currentStartTime);
      const formattedEndDate = formatTimeStamp(currentEndTime);
  
      let areDatesValid = true;
      // if (!formattedStartDate || !formattedEndDate) {
      //   Toaster.warn({
      //     message: "Please enter start date and end date",
      //   });
      //   areDatesValid = false;
      // }
      if (
        formattedStartDate &&
        formattedEndDate &&
        new Date(formattedStartDate) > new Date(formattedEndDate)
      ) {
        Toaster.warn({
          message: "Start date/time should be less than end date/time",
        });
        areDatesValid = false;
      }
  
      return areDatesValid;
    };
  const onSaveAvailability = async () => {
    if (!areDatesValid()) {
      return;
    }
    await updateGeoFiltering(value);
    const body = {
      geo: {
        countries: selectedType === "unrestricted" ? [] : selecteCountryList,
        exclude_countries: selectedType === "prevent" ? true : false,
        restricted: selectedType === "unrestricted" ? false : true,
      },
      availability: currentStartTime || currentEndTime ? '' :  availability,
    };

    const dateBody =
      currentStartTime && currentEndTime
        ? {
            start_date: formatTimeStamp(currentStartTime),
            end_date: formatTimeStamp(currentEndTime),
          }
        : currentStartTime
        ? { start_date: formatTimeStamp(currentStartTime), end_date: null }
        : currentEndTime
        ? { end_date: formatTimeStamp(currentEndTime), start_date: null }
        : { end_date: null, start_date: null };
        let finalBody = {...body, ...dateBody};
      
    const response = await apiPatch(
      getUrlWithOptions(`${endpointRootPath}/${id}`),
      { body: finalBody }
    );

    if (response?.data) {
      setAvailabilityPanelData(getDataAvailability(response?.data));
      setEditing(false);
      setSavedData(true);
      onSavedData?.();
    }
  };

  useEffect(() => {
    if (!_.isEqual(availabilityPanelData, data)) {
      setAvailabilityPanelData(data);
      setDefaultData(data);
      if (availabilityPanelData?.geo?.exclude_countries === true) {
        setGeoFiltering(
          "Available only in " +
            availabilityPanelData?.geo?.countries
              ?.map((item: any) => countryList().getLabel(item))
              .join(",")
        );
      } else if (availabilityPanelData?.geo?.restricted === true) {
        setGeoFiltering(
          "Available only in " +
            availabilityPanelData?.geo?.countries
              ?.map((item: any) => countryList().getLabel(item))
              .join(",")
        );
      } else if (availabilityPanelData?.geo?.restricted === false) {
        setGeoFiltering("unrestricted");
      }
    }
  }, [data]);

  useEffect(() => {
    const { start_date, end_date, status, offlineAccess, availability } =
      availabilityPanelData;
    CheckAvaililibiltyString();
    if (availabilityPanelData?.geo?.exclude_countries === true) {
      setGeoFiltering(
        "Restricted in " +
          availabilityPanelData?.geo?.countries
            ?.map((item: any) => countryList().getLabel(item))
            .join(",")
      );
    } else if (availabilityPanelData?.geo?.restricted === true) {
      setGeoFiltering(
        "Available only in " +
          availabilityPanelData?.geo?.countries
            ?.map((item: any) => countryList().getLabel(item))
            .join(",")
      );
    } else if (availabilityPanelData?.geo?.restricted === false) {
      setGeoFiltering("unrestricted");
    }
    setCurrentAccess(offlineAccess || "Yes");
    setAvailibility(availability || "Always Available");
  }, [availabilityPanelData]);

  useEffect(() => {
    onEditModeChange && onEditModeChange(editing, savedData);
  }, [editing]);

  const onUpdateSelectedType = (geo) => setSelectedType(geo);

  const updateGeoFiltering = async (value) => {
    var selectedCountries: any = [];
    var result = value?.map(function (val) {
      // selectedCountries.push(countryList().getLabel(val));
      selectedCountries.push(val);
    });
    if (selectedType === "allow" && selectedCountries) {
      setSelectedCountries(selectedCountries);
      console.log("selectedCountries"+JSON.stringify(selectedCountries))
      setGeoFiltering(
        "Available only in " +
          selectedCountries?.map((item: any) => countryList().getLabel(item)).join(",")
      );
    } else if (selectedType === "prevent" && selectedCountries) {
      setSelectedCountries(selectedCountries);
      setGeoFiltering(
        "Restricted in " + selectedCountries?.map((item: any) => countryList().getLabel(item)).join(",")
      );
    } else if (selectedType === "unrestricted") {
      setGeoFiltering("unrestricted");
      setSelectedCountries([]);
      setValue([]);
    }
  };

  const CheckAvaililibiltyString = () => {
    // let availibilityString = '';
    if (availabilityPanelData?.start_date && availabilityPanelData?.end_date) {
      setAvailibilityString(
        "Available from " +
          formatDate(currentStartTime) +
          " till " +
          formatDate(currentEndTime)
      );
    } else if (availabilityPanelData?.start_date) {
      setAvailibilityString("Available from " + formatDate(currentStartTime));
    } else if (availabilityPanelData?.end_date) {
      setAvailibilityString("Available till " + formatDate(currentEndTime));
    } else {
      setAvailibilityString("Always available");
    }
    // return availibilityString
  };
  const restoreData = () => {
    if (availabilityPanelData?.geo?.exclude_countries === true) {
      setGeoFiltering(
        "Restricted in " +
          availabilityPanelData?.geo?.countries
            ?.map((item: any) => countryList().getLabel(item))
            .join(",")
      );
      setSelectedType("prevent");
    } else if (availabilityPanelData?.geo?.restricted === true) {
      setGeoFiltering(
        "Available only in " +
          availabilityPanelData?.geo?.countries
            ?.map((item: any) => countryList().getLabel(item))
            .join(",")
      );
      setSelectedType("allow");
    } else if (availabilityPanelData?.geo?.restricted === false) {
      setGeoFiltering("unrestricted");
    }
    setSelectedType("unrestricted");
    setCurrentStartTime(availabilityPanelData?.start_date);
    setCurrentEndTime(availabilityPanelData?.end_date);
  };
  const today = new Date();

  return (
    <div id={idPanel} className="Availability">
      <Panel
        title="Availability"
        actions={
          <>
            {editing ? (
              <>
                <Button
                  variant="secondary-invert"
                  text="Cancel"
                  onClick={() => {
                    setEditing(false);
                    restoreData();
                  }}
                />
                <Button
                  variant="primary"
                  text="Save"
                  onClick={onSaveAvailability}
                  disabled={isLoading}
                />
              </>
            ) : (
              <Button
                variant="secondary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                }}
                disabled={disableEditing}
              />
            )}
          </>
        }
      >
        <>
          {editing ? (
            <>
              <Flex>
                <Flex style={{ flexDirection: "column", flex: 1 }}>
                  <p className="title">
                    Schedule Start date
                  </p>
                  <RadioGroupDatePicker
                    startDate={currentStartTime || ""}
                    // maxDate={currentEndTime || ''}
                    label="Available Immediately"
                    editing={editing}
                    onChangeDatePicker={(val) => {
                      setCurrentStartTime(formatTimeStamp(val));
                    }}
                    placeholder={"Start Date and Time"}
                  />
                  <p className="title">
                    Schedule End date
                  </p>
                  <RadioGroupDatePicker
                    endDate={currentEndTime || ""}
                    // minDate={currentStartTime || ''}
                    label="No End Date"
                    editing={editing}
                    onChangeDatePicker={(val) => {
                      setCurrentEndTime(formatTimeStamp(val));
                    }}
                    placeholder={"End"}
                  />
                  <p className="Maintenance-mode-create-title">Geo Filtering</p>
                  <SimpleSelect
                    className="select-input-lg mt-2"
                    value={selectedType}
                    options={typeOptions}
                    onChange={onUpdateSelectedType}
                  />
                  {selectedType &&
                  (selectedType === "allow" || selectedType === "prevent") ? (
                    <AutosaveTagListSuggestions
                      fieldId="countryField"
                      label=""
                      requireSuggestionMatch
                      suggestions={suggestions}
                      value={value}
                      editing={inputState.editing || false}
                      saving={inputState.saving || false}
                      saved={inputState.saved || false}
                      onStartEditing={() => {
                        // setValue(value);
                        updateGeoFiltering(value);
                        setInputState({ editing: true });
                      }}
                      onChange={(value) => {
                        setValue(value);
                        updateGeoFiltering(value);
                      }}
                      onCancel={() => setInputState({ editing: false })}
                      onSave={(value) => {
                        setInputState({ saving: true });
                        setValue(value);
                        updateGeoFiltering(value);
                        setInputState({ saved: true });
                      }}
                    />
                  ) : null}
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex>
              <div>
                <div className="ml-4">
                  <p className="title">Scheduling</p>
                  <p className="description">{availibilityString}</p>
                </div>
                <div className="ml-4">
                  <p className="title">Geo Filtering</p>
                  <p className="description">
                    {geoFiltering?.length ? geoFiltering : "Unrestricted"}
                  </p>
                </div>
              </div>
            </Flex>
          )}
        </>
      </Panel>
    </div>
  );
};

export default Availability;
