/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router";
import React, { FC, useEffect, useRef, useState } from "react";
import { flowRight as compose } from "lodash";
import { Flex, Icon, PanelTitleBar } from "@brightcove/studio-components";

import * as ROUTES from "../routes";
import { ModuleName, formatDate, getModulePermissions } from "../../utils";
import { getPageCount, getUrlWithOptions } from "../../utils";
import "./Collections.scss";
import { useApi } from "../../hooks/useApi";
import { withRouter } from "../../components/withRouter/withRouter";
import TitleDescription from "../../components/TitleDescription/TitleDescription";
import Table from "../../components/Table/Table";
import SubHeader from "../../components/SubHeader/SubHeader";
import { DeleteConfirmationModal, withModal } from "../../components/Modals";
import Loading from "../../components/Loading/Loading";
import InputSearch from "../../components/ControlledComponents/InputSearch";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import { SVGImage } from "../../assets/images";

const DeleteButton = withModal(Button, DeleteConfirmationModal);
const INITIAL_KEY = '';
interface CollectionsProps {
  embedded?: boolean;
  filter?: string;
  singleSelection?: boolean;
  onRowSelect?: (param: any[]) => void;
}

const Collections: FC<CollectionsProps> = ({
  embedded,
  filter,
  singleSelection,
  onRowSelect,
}) => {
  const navigate = useNavigate();
  const { apiGet, apiDelete, refetchGet } = useApi();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sort, setSort] = useState<string>("updated_at:dsc");
  const [collectionsData, setCollectionsData] = useState<any>(undefined);
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startKey, setStartKey] = useState(INITIAL_KEY);
  const [startKeyData, setStartKeyData] = useState("");
  const permissions = useSelector((state:any) => state.permissions);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.CONTENT_MANAGEMENT_SCREEN, permissions);

  const prevCountRef = useRef<string>(INITIAL_KEY);
  const [paginationKeyArr, setPaginationKeyArr] = useState<any>([]);
  const columns = [
    {
      Header: "Collection Title",
      sortable: true,
      accessor: "name",
      Cell: ({ value, row }) => (
        <div style={{width:"250px", textOverflow:"ellipsis", overflowWrap: "break-word"}}>
        <TitleDescription
          title={value}
          description={row.original.id}
          id={row.original.id}
          onClick={(id) => 
            embedded? "" :
            navigate(`../${ROUTES.COLLECTIONS}/edit/${id}`)}
          type={embedded ? "noClickable": ""}
        />
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status", // accessor is the "key" in the data
      Cell: ({ value }) => {
        return (
          // <Flex justifyContent="center">
            <Icon name={"check2"} color="#3886F7" />
          // </Flex>
        );
      },
      sortable: true,
      className: "",
    },
    {
      Header: "Type",
      accessor: "collection_type",
      sortable: true,
      className: "",
    },
    {
      Header: "Created",
      accessor: "created_at",
      sortable: true,
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.createdBy}</div>
        </>
      ),
      className: "",
    },
    {
      Header: "Updated",
      accessor: "updated_at",
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.updatedBy}</div>
        </>
      ),
      sortable: true,
      className: "",
    },
  ];

  useEffect(() => {
    (async () => {
      if (!collectionsData) {
        setLoading(true);
      }

      const { data } = await apiGet(
        getUrlWithOptions(
          "/ops/collections",
          searchQuery,
          sort,
          filter || undefined,
          currentPageIndex,
          currentPageSize,
          startKey
        )
      );

      setCollectionsData(data?.results);
      if (data?.results?.totalCount <= (currentPageIndex * currentPageSize) && searchQuery) {
        setTotalCount(data?.results?.totalCount);
        setCurrentPageIndex(0);
      } else if (data?.results?.totalCount > (currentPageIndex * currentPageSize) && searchQuery){
        setTotalCount(data?.results?.totalCount);
        let pageCount: number = Math.ceil(data?.results?.totalCount / currentPageSize);
        pageCount<= currentPageIndex?   setCurrentPageIndex(pageCount) : setCurrentPageIndex(currentPageIndex);
      } else {
        setTotalCount(data?.results?.totalCount);
      }
      if (data?.lastEvaluatedKey) {
        setStartKeyData(data?.lastEvaluatedKey);
      }
      setLoading(false);
    })();
  }, [searchQuery, sort, currentPageIndex, currentPageSize, startKey]);

  const onChangeSelection = (items) => {
    setSelectedItems(items);
    onRowSelect?.(items);
  };

  const onDeleteItems = async () => {
    const idsToDelete = selectedItems.map((item) => item.id).join(",");
    const { error } = await apiDelete(
      getUrlWithOptions(`/ops/collections?ids=${idsToDelete}`)
    );

    if (!error) {
      const { data } = await refetchGet();

      setCollectionsData(data?.results);
      setTotalCount(data?.results?.totalCount);
    }
  };

  const onClickSearch = (query) => setSearchQuery(query);

  const onClickSort = (field, order) => {
    if (field && order) {
      setSort(`${field}:${order}`);
    }
  };
  useEffect(() => {
    prevCountRef.current = startKey;
    paginationKeyArr[currentPageIndex+1]= startKeyData;
  }, [startKeyData]);

  const onPaginationChange = ({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
    setStartKey(pageIndex === 0? '' : paginationKeyArr[pageIndex]);
  };

  return loading && !embedded ? (
    <Loading />
  ) : (
    <>
      <div className="Collections-embed">
        {" "}
        {embedded && (
          <div>
            {/* <SubHeader
              className="Sub-header"
              title={"Add a Page Block - Select Collection"}
              subtitle=""
              actions={
                <><button className="close-btn">
                  </button></>
              }
            /> */}
            <div className="serach">
              <InputSearch placeholder="Search" onSearch={onClickSearch} />
            </div>
          </div>
        )}
      </div>
      <Flex
        className={embedded ? "Collections-embed" : "Collections"}
        flexDirection="column"
      >
        {!embedded && (
          <SubHeader
            title={"Collections"}
            subtitle=""
            actions={
              <InputSearch placeholder="Search" onSearch={onClickSearch} />
            }
            icon={<img src={SVGImage.Collection} alt={"video"} height="26px" width="26px"/>}
            quantitySubtitle={`${collectionsData?.totalCount || 0} Collection${
              collectionsData?.totalCount === 1 ? "" : "s"
            }`}
          />
        )}
        {!embedded && (
          <Flex className="mt-4 mx-10" alignItems="center">
            <Button
              variant="primary"
              text="Create Collection"
              onClick={() => navigate(`../${ROUTES.COLLECTIONS}/create`)}
              disabled={!hasWriteAccess}
            />
            <DeleteButton
              variant="secondary"
              disabled={selectedItems.length === 0 || !hasWriteAccess}
              modalProps={{
                title: "Delete",
                children: (
                  <>
                Permanently delete{" "}
                {selectedItems.length === 1
                  ? selectedItems.length + " collection"
                  : selectedItems.length + " collections"}{" "}
                from the system? This cannot be undone.
              </>
                ),
              }}
              onModalConfirm={onDeleteItems}
            >
              Delete
            </DeleteButton>
          </Flex>
        )}
        <Table
          className={"mt-4"}
          data={collectionsData?.items || []}
          columns={columns}
          onChangeSelection={onChangeSelection}
          onClickSort={onClickSort}
          hasSelection
          defaultSortingValues={[{ id: "updated_at", desc: true }]}
          hasPagination
          pageCount={getPageCount(currentTotalCount, currentPageSize)}
          onPaginationChange={onPaginationChange}
          pageIndex={currentPageIndex}
          pageSize={currentPageSize}
          singleSelection={embedded}
          totalCount={currentTotalCount}
        />
      </Flex>
    </>
  );
};

export default compose(withRouter)(Collections);
