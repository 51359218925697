/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./DynamicStrings.scss";
import SubHeader from "../../components/SubHeader/SubHeader";
import { Icon } from "@brightcove/studio-components";
import Button from '../../components/Button/Button';
import Table from "../../components/Table/Table";
import { useNavigate } from "react-router";
import { useApi } from "../../hooks/useApi";
import { ModuleName, getModulePermissions, getUrlWithOptions } from "../../utils";
import { DeleteConfirmationModal } from "../../components/Modals";
import TitleDescription from "../../components/TitleDescription/TitleDescription";
import * as ROUTES from "../routes";
import InputSearch from "../../components/ControlledComponents/InputSearch";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";

const SORT_DEFAULT = "strings_label:dsc";
const VALID_SORT_FIELDS = {
  Key: "strings_label",
};
const resource = "/ops/dynamic_strings";
const INITIAL_KEY = '';
const DynamicStrings: React.FunctionComponent<any> = ({ moduleName }) => {
  const navigate = useNavigate();
  const { apiGet, apiDelete, isLoading, refetchGet } = useApi();

  const [dynamicStringAPIData, setDynamicStringAPIData] = useState<any>();
  const [currentTotalCount, setTotalCount] = useState(0);
  const [dynamicStrings, setString] = useState([]);
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [selectedRowCount, getSelectedRowCount] = useState(0);
  const [selectedRows, getSelecetdRows] = useState([]);
  const [deleteButtonEnable, setDeleteButton] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentSort, setCurrentSort] = useState<string>(SORT_DEFAULT);
  const [currentSearch, setCurrentSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchUrl, ] = useState<string>(
    getUrlWithOptions(
      "/ops/dynamic_strings",
      currentSearch,
      currentSort,
      undefined,
      currentPageIndex,
      currentPageSize
    )
  );
  
  const [startKey, setStartKey] = useState(INITIAL_KEY);
  const [startKeyData, setStartKeyData] = useState('');
  const prevCountRef = useRef<string>(INITIAL_KEY);
  const [paginationKeyArr, setPaginationKeyArr] = useState<any>([]);
  const permissions = useSelector((state:any) => state.permissions);
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(ModuleName.DYNAMIC_STRINGS_SCREEN, permissions);


  const onPaginationChange = ({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
    setStartKey(pageIndex === 0? '' : paginationKeyArr[pageIndex]);
  };
  useEffect(() => {
    prevCountRef.current = startKey;
    paginationKeyArr[currentPageIndex+1]= startKeyData;
  }, [startKeyData]);

  const onChangeSelection = useCallback((selectionRows) => {
    getSelectedRowCount(selectionRows.length);
    getSelecetdRows(selectionRows);
    selectionRows.length > 0 ? setDeleteButton(false) : setDeleteButton(true);
  }, []);

  const onDeleteItems = () => {
    const ids = selectedRows.map((item: any) => item.id).join(",");
    const deleteData = async () => {
      const { error } = await apiDelete(
        getUrlWithOptions(`/ops/dynamic_strings/${ids}`)
      );

      setShowDeleteModal(false);

      if (!error) {
        const { data } = await refetchGet();

        setString(data?.results?.items || []);
        setTotalCount(data?.results?.totalCount);
      }
    };

    deleteData();
  };

  const onSearch = (val: string) => {
    setCurrentSearch(val);
  };

  useEffect(() => {
    (async () => {
      if (!dynamicStringAPIData) {
        setLoading(true);
      }
    //   console.log("fetchUrl" + fetchUrl);
      const [{ data: dynamicStringsData }] = await Promise.all([
        apiGet(
          getUrlWithOptions(
            resource,
            currentSearch,
            currentSort,
            undefined,
            currentPageIndex,
            currentPageSize,
            startKey
          )
        ),
      ]);
      if (dynamicStringsData) {
        setDynamicStringAPIData(dynamicStringsData);
        setLoading(false);
      }
      if (dynamicStringsData?.results?.items) {
        if (dynamicStringsData?.results?.totalCount <= (currentPageIndex * currentPageSize)  && currentSearch) {
          setTotalCount(dynamicStringsData?.results?.totalCount);
          setCurrentPageIndex(0)
        } 
        else if (dynamicStringsData?.results?.totalCount > (currentPageIndex * currentPageSize) &&currentSearch){
          setTotalCount(dynamicStringsData?.results?.totalCount);
          let pageCount: number = Math.ceil(dynamicStringsData?.results?.totalCount / currentPageSize);
          pageCount<= currentPageIndex?   setCurrentPageIndex(pageCount) : setCurrentPageIndex(currentPageIndex);
        }
        else {
          setTotalCount(dynamicStringsData?.results?.totalCount);
        }
        setString(dynamicStringsData?.results?.items);
      }
      if (dynamicStringsData?.lastEvaluatedKey) {
        setStartKeyData(dynamicStringsData?.lastEvaluatedKey);
        } 
      // ? setStartKey() : '';
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUrl, currentSearch, currentSort, currentPageIndex, currentPageSize, startKey]);

   const onClickSort = (field, order) => {
    if (field && order) {
      setCurrentSort(
        `${VALID_SORT_FIELDS[field]}:${order.toLocaleLowerCase()}`
      );
    }
  };

  const columns = [
    {
      Header: "Key",
      accessor: (row) => [row.label],
      Cell: ({ value, row: { original } }) => (
        <div style={{width:"200px", textOverflow:"ellipsis", overflowWrap: "break-word"}}>
        <TitleDescription
          title={value}
          description={""}
          id={original.id}
          onClick={(row) =>
            navigate(`../${ROUTES.STRINGS}/edit/${original.id}`)
          }
        /></div>
      ),
      sortable: true,
    },
    {
      Header: "Scope",
      id: "scope",
     
      accessor: (row) => [row.scope],
      Cell: ({ value, row }) => <div>{value}</div>,
    },
    {
      Header: "Language",
      id: "lang",
      accessor: (row) => [row.lang],
      Cell: ({ value, row }) => <div>{value}</div>,
      },
    {
      Header: "Value",
      accessor: (row) => [row.value],
      Cell: ({ value }) => <div  style={{width:"500px", textOverflow:"ellipsis", overflowWrap: "break-word"}}>{value}</div>,
      },
  ];
  return (<>
    {loading && <Loading />}
    <div className="dynamic-strings">
      <div>
        <SubHeader
          title="Dynamic Strings"
          subtitle=""
          icon={<Icon name="card" />}
          quantitySubtitle={`${currentTotalCount || 0} String${
            currentTotalCount === 1 ? "" : "s"
          }`}
          actions={<InputSearch placeholder="Search" onSearch={onSearch} />}
        />
      </div>
      <DeleteConfirmationModal
        title="Delete"
        show={showDeleteModal}
        onDelete={onDeleteItems}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        actionDisabled={isLoading}
      >
        <>
          Permanently delete{" "}
          {selectedRowCount === 1
            ? selectedRowCount + " Dynamic String"
            : selectedRowCount + " Dynamic Strings"}{" "}
          from the system? This cannot be undone.
        </>
      </DeleteConfirmationModal>
      <div className="dynamic-strings-validation-btns pl-9">
        <Button
          className="dynamic-strings-validation-btns-create-btn"
          text="Create a String"
          onClick={() => {
            navigate("/dynamic-strings/new");
          }}
          variant="primary"
          disabled={!hasWriteAccess}
        />
        <Button
          className={`delete-btn${deleteButtonEnable ? "" : " enabled"}`}
          // className="secondary"
          text="Delete"
          onClick={() => {
            setShowDeleteModal(true);
          }}
          disabled={deleteButtonEnable || !hasWriteAccess}
          variant="secondary"
        />
      </div>
      <br />
      <Table
        className=""
        data={dynamicStrings}
        columns={columns}
        hasPagination
        pageCount={Math.ceil(currentTotalCount / currentPageSize)}
        pageIndex={currentPageIndex}
        pageSize={currentPageSize}
        onPaginationChange={onPaginationChange}
        hasSelection={true}
        onChangeSelection={onChangeSelection}
        onClickSort={onClickSort}
        totalCount={currentTotalCount}
        // defaultSortingValues={[{ id: 'strings_label', desc: true }]}
      />
    </div>
    </>
  );
};

export default DynamicStrings;
