/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate, useLocation } from "react-router-dom";
import HomePage from "./routes/HomePage/HomePage";
import LoginPage from "./routes/LoginPage/LoginPage";
import CreatePasswordPage from "./routes/CreatePasswordPage/CreatePasswordPage";
import * as ROUTES from "./routes/routes";
import UserManagement from "./routes/UserManagement/UserManagement";
import { ModuleName, getUrlWithOptions } from "./utils";
import {persistor,store} from "./store/index";
import Header from "./components/Header/Header";
import Channels from "./routes/Channels/Channels";
import Profile from "./routes/Profile/Profile";
import DynamicStrings from "./routes/DynamicStrings/DynamicStrings";
import Subscription from "./routes/Subscription/Subscription";
// import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import NewDynamicString from "./routes/DynamicStrings/NewDynamicString";
import { Toaster } from "@brightcove/studio-components";
import MaintenanceMode from "./routes/MaintenanceMode/MaintenanceMode";
import ContentManagementDetail from "./routes/ContentManagement/ContentManagementDetail";
import ContentManagementCreate from "./routes/ContentManagement/ContentManagementCreate";
import ContentManagement from "./routes/ContentManagement/ContentManagement";
import Collections from "./routes/Collections/Collections";
import CollectionsCreate from "./routes/Collections/CollectionsCreate";
import CollectionsDetail from "./routes/Collections/CollectionsDetail";
import ChannelCreate from "./routes/Channels/ChannelCreate";
import ChannelDetail from "./routes/Channels/ChannelDetail";
import Pages from "./routes/Pages/Pages";
import CreatePage from "./routes/Pages/CreatePage";
import PageDetail from "./routes/Pages/PageDetail";
import DynamicStringDetails from "./routes/DynamicStrings/DynamicStringDetails";
import ActivityLog from "./routes/ActivityLog/ActivityLog";
import ActivityLogDetail from "./routes/ActivityLog/ActivityLogDetail";
import { useApi } from "./hooks/useApi";
import { logout } from "./store/slices/userSlice";
import FirebaseAuthenticator from "./auth/Auth-Firebase";


import { useDispatch } from "react-redux";

const AppWrapper =()=> {
  const accessToken= useSelector((state:any) => state.accessToken);
  const navigate = useNavigate();
  const { apiGet } = useApi();
  const dispatch = useDispatch();
  const location = useLocation();
  const authenticator = new FirebaseAuthenticator();

  const validateToken = async () => {
    const  response  = await apiGet(getUrlWithOptions(`/ops/user/token`));
    if (response.status !== 204) {
      dispatch(logout());
      navigate(ROUTES.LOGIN);
    }
  }


  useEffect(() => {
    if (!accessToken) 
    navigate(ROUTES.LOGIN);
    validateToken();
  }, []);

   useEffect(() => {
    if (!accessToken) 
    navigate(ROUTES.LOGIN);
    if (accessToken) {
     const token =  authenticator?.getIdToken()
      validateToken();
    }
  }, [location.pathname])

    return (
      <React.Fragment>
        <Routes>
          <Route path={ ROUTES.HOME } element={<ContentManagement />}/>
          <Route path={ ROUTES.LOGIN } element={<LoginPage />}/>
          <Route path={ ROUTES.CREATE_PASSWORD } element={<CreatePasswordPage />}/>
          <Route path={ ROUTES.USER_MANAGEMENT } element={ <UserManagement moduleName={ModuleName.USER_MANAGEMENT_SCREEN} />}  />
          <Route path={ ROUTES.CHANNELS } element={ <Channels moduleName={ModuleName.CHANNELS_SCREEN} />}  />
          <Route path={`${ROUTES.CHANNELS}/create`} element={<ChannelCreate />} />
          <Route path={`${ROUTES.CHANNELS}/edit/:id`} element={<ChannelDetail />} />
          <Route path={ ROUTES.STRINGS } element={ <DynamicStrings moduleName={ModuleName.CHANNELS_SCREEN} />}  />
          <Route path={ `${ROUTES.STRINGS}/new` } element={ <NewDynamicString moduleName={ModuleName.DYNAMIC_STRINGS_SCREEN} />}  />
          <Route path={`${ROUTES.STRINGS}/edit/:id`} element={ <DynamicStringDetails moduleName={ModuleName.DYNAMIC_STRINGS_SCREEN} />}  />
          <Route path={ ROUTES.MAINTENANCE_MODE } element={ <MaintenanceMode />}  />
          <Route path={ ROUTES.ACTIVITY } element={ <ActivityLog />}  />
          <Route path={`${ROUTES.ACTIVITY}/:id`} element={<ActivityLogDetail />} />
          <Route path={ ROUTES.PROFILE } element={<Profile />}  />
          <Route path={`${ROUTES.CONTENT_MANAGEMENT}`} element={<ContentManagement/>} />
          <Route path={`${ROUTES.CONTENT_MANAGEMENT}/edit/:id`} element={<ContentManagementDetail />} />{' '}
          <Route path={`${ROUTES.CONTENT_MANAGEMENT}/create`} element={<ContentManagementCreate />} />
          <Route path={`${ROUTES.COLLECTIONS}`} element={<Collections />} />
          <Route path={`${ROUTES.COLLECTIONS}/create`} element={<CollectionsCreate />} />
          <Route path={`${ROUTES.COLLECTIONS}/edit/:id`} element={<CollectionsDetail />} />
          <Route path={`${ROUTES.PAGES}`} element={<Pages />} />
          <Route path={`${ROUTES.PAGES}/create`} element={<CreatePage />} />
          <Route path={`${ROUTES.PAGES}/edit/:id`} element={<PageDetail />} /> 
          <Route path={ ROUTES.SUBSCRIPTION } element={ <Subscription moduleName={ModuleName.SUBSCRIPTION_SCREEN} />} />
          {/* <Route path={`${ROUTES.PAGES}/edit/:pageId/sections/:id`} element={<PageSection />} /> */}
        </Routes>
      </React.Fragment>
    );
  // }
}

const AppBody = AppWrapper;

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              {/* <ReduxProvider store={store}> */}
              <Header />
              <AppBody />
              <Toaster />
              {/* </ReduxProvider> */}
            </PersistGate>
          </Provider>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
