import React from "react";
import { getModulePermissions } from "../../utils";
// import _ from "lodash";
import { useSelector } from "react-redux";
import "./Header.scss";

interface RestrictedHeaderItemProps {
  component: React.ElementType;
  className?: string;
  onClick?: (string) => void;
  moduleName: string;
  tabId?: string;
  children: React.ReactNode;
  label?: string;
}

const RestrictedHeaderItem: React.FC<RestrictedHeaderItemProps> = ({
  component: Component,
  className,
  moduleName,
  children,
  onClick,
  tabId,
  label,
  ...props
}) => {
  const permissions = useSelector((state:any) => state.permissions);
  
  const { hasReadAccess, hasWriteAccess } = getModulePermissions(moduleName, permissions);
  const checkAccess = hasReadAccess || hasWriteAccess ? true : hasReadAccess ? true : false;
  return (
    <Component onClick={onClick}  className={`${className}${checkAccess === false ? " hidden" : " "}`} props>
    {/* <Component onClick={onClick} className={`${className}`} {..._.omit(props, ["setUser", "changeLanguage", "clearStore"])}> */}
      {children}
    </Component>
  );
};

export default RestrictedHeaderItem;
