/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Flex, Icon } from "@brightcove/studio-components";

import * as ROUTES from "../../routes";
import { GenericNameId } from "../../../interfaces";
import TitleDescription from "../../../components/TitleDescription/TitleDescription";
import Table from "../../../components/Table/Table";
import Panel from "../../../components/Panel/Panel";

import "./CollectionsContained.scss";
import Button from "../../../components/Button/Button";
import { AddItemModal, withModal } from "../../../components/Modals";
import { formatDate, getPageCount, getUrlWithOptions } from "../../../utils";
import { useApi } from "../../../hooks/useApi";
import Loading from "../../../components/Loading/Loading";
import EditPanel from "../../../components/Panel/EditPanel";
import { SVGImage } from "../../../assets/images";

interface CollectionsContainedProps {
  idPanel: string;
  data: GenericNameId[];
  contentId: string;
  onSavedData: () => void;
  disabledEditing?: boolean;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
}
const AddCollectionItemButton = withModal(Button, AddItemModal);

const CollectionsContained: FC<CollectionsContainedProps> = ({
  idPanel,
  data,
  contentId,
  onSavedData,
  disabledEditing,
  onEditModeChange,
}) => {
  const ALL_CONTENT = "allContent";
  const FILTER_DEFAULT = "";
  const SORT_DEFAULT = "updated_at:dsc";
  const VALID_CONTENT_SORT_FIELDS = {
    Name: "name",
    id: "id",
    Created: "created_at",
    Type: "collection_type",
    Updated: "updated_at",
    published: "published",
  };
  const navigate = useNavigate();
  const [collectionContainedData, setCollectionContainedData] = useState(data);
  const [editing, setEditing] = useState<boolean>(false);
  const { apiGet, apiPatch } = useApi();
  const [contentTotalCount, setTotalCount] = useState(0);
  const [currentPageSize, setContentPageSize] = useState(20);
  const [collectionPageSize, setCollectionPageSize] = useState(20);
  const [contentPageIndex, setContentPageIndex] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [, setCurrentFilter] = useState(FILTER_DEFAULT);
  const [contentSort, setContentSort] = useState<string>(SORT_DEFAULT);
  const [selectedCollectionRows, getSelecetdCollectionRows] = useState([]);
  const [collectionsData, setCollectionsData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sort, setSort] = useState<string>("updated_at:dsc");
  const [collectionItems, setCollectionItems] = useState<any[]>([]);
  const [disableEditing, setDisableEditing] = useState(true);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  let newCollectionItems: any = [];
  const [collectioncontainedInitialData, setCollectioncontainedInitialData] =
    useState<any>([]);
  const onContentSearch = (val: string) => setSearchQuery(val);
  useEffect(() => {
    setCollectionContainedData(data);
    setCollectioncontainedInitialData(data);
  }, [loading]);

  const mapWithCollection = (resultarr :  any) => {
    let collectionArr: any = [];
    data.map((item: any) => {
      collectionsData?.map((innerdata: any) => {
        if (innerdata.id === item.item_id) {
          collectionArr.push(innerdata);
        } else {
        }
      });
    });
    return collectionArr;
    // setCollectionContainedData(collectionArr);
    // setCollectioncontainedInitialData(collectionArr);
  }
  useEffect(() => {
    (async () => {
      if (!collectionsData) {
        setLoading(true);
      }

      const { data } = await apiGet(
        getUrlWithOptions(
          "/ops/collections",
          searchQuery,
          contentSort,
          undefined,
          contentPageIndex,
          collectionPageSize
        )
      );

      setCollectionsData(data?.results?.items);
      setTotalCount(data?.results?.totalCount);
      setCollectionsData(data?.results?.items);
      if (data?.results?.totalCount <= (contentPageIndex * collectionPageSize) && searchQuery) {
        setTotalCount(data?.results?.totalCount);
        setContentPageIndex(0);
      } else if (data?.results?.totalCount > (contentPageIndex * collectionPageSize) && searchQuery){
        setTotalCount(data?.results?.totalCount);
        let pageCount: number = Math.ceil(data?.results?.totalCount / contentPageIndex);
        pageCount<= contentPageIndex?   setContentPageIndex(pageCount) : setContentPageIndex(contentPageIndex);
      } else {
        //console.log("data"+JSON.stringify(data?.results?.totalCount)+contentPageIndex +"*"+ collectionPageSize)
        setTotalCount(data?.results?.totalCount);
      }
      setLoading(false);
    })();
  }, [
    searchQuery,
    contentSort,
    contentPageIndex,
    collectionPageSize,
    data,
    loading,
  ]);

  const onSaveCollectionItem = async (): Promise<any> => {
    const unique = collectionContainedData.filter((obj, index) => {
      return (
        index === collectionContainedData.findIndex((o) => obj?.id === o?.id)
      );
    });
    let bodyString: any = [];
    unique.map((item) => {
      bodyString.push({
        name: item.name,
        item_id: item.id,
      });
    });
    // setCollectionContainedData(unique);

    const body = {
      contained_in_collections: bodyString,
    };

    const response = await apiPatch(
      getUrlWithOptions(`/ops/content/${contentId}`),
      {
        body,
      }
    );

    if (response?.data) {
      toggleDisableEditing();
      onSavedData();
      // let resultArr :any = mapWithCollection(response?.data?.contained_in_collections)
      setCollectionContainedData(response?.data?.contained_in_collections);
      setCollectioncontainedInitialData(response?.data?.contained_in_collections);
    }
    return {};
  };
  const collectionFilters = [
    {
      image: SVGImage.Content,
      name: "Collections",
      id: ALL_CONTENT,
    },
  ];

  const onChangeSelectionCollection = (items) => {
    setSelectedItems(items);
  };

  const onCancel = () => {
    // setEditing(false);
    // onEditModeChange?.(!disabledEditing, true);
    // toggleDisableEditing();
    setContentPageIndex(0)
    setSearchQuery('');
  };

  const getAllContentFilter = () => {
    let allContentFilter = "";
    return allContentFilter;
  };

  const onChangeFilter = (id: string) => {
    const filter = id.search(ALL_CONTENT) >= 0 ? getAllContentFilter() : id;

    setCurrentPageIndex(0);
    setCurrentFilter(filter);
  };
  const onClickContentSort = (field, order) => {
    if (field && order) {
      setContentSort(
        // `${VALID_CONTENT_SORT_FIELDS[field]}:${order.toLocaleLowerCase()}`
        `${field}:${order}`
      );
    }
  };
  const onClickSort = (field, order) => {
    if (field && order) {
      setSort(
        // `${VALID_CONTENT_SORT_FIELDS[field]}:${order.toLocaleLowerCase()}`
        `${field}:${order}`
      );
    }
  };
  const onChangeSelectionValue = (selectionRows) => {
    getSelecetdCollectionRows(selectionRows);
  };
  const onAddCollectionItemCollection = () => {
     newCollectionItems =
      collectionContainedData.concat(selectedItems);
    setCollectionContainedData(newCollectionItems);
  };
  const onContentPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setContentPageIndex(pageIndex);
    setCollectionPageSize(pageSize);
  }, []);
  const columns = [
    {
      Header: "Collection",
      //sortable: true,
      accessor: "name",
      Cell: ({ value, row }) => (
        <TitleDescription
          title={value}
          description={row.original.id}
          id={row.original.id}
          onClick={(id) => navigate(`../${ROUTES.COLLECTIONS}/edit/${id}`)}
        />
      ),
    },
    {
      Header: "Status",
      accessor: "status", // accessor is the "key" in the data
      Cell: ({ value }) => {
        return (
          <Flex justifyContent="center">
            <Icon name={"check2"} color="#3886F7" />
          </Flex>
        );
      },
      //sortable: true,
      className: "Content-management-published",
    },
    {
      Header: "Type",
      accessor: "collection_type",
      // sortable: true,
      className: "type",
    },
    {
      Header: "Created",
      accessor: "created_at",
      // sortable: true,
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
        </>
      ),
      className: "date",
    },
    {
      Header: "Updated",
      accessor: "updated_at",
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
        </>
      ),
      //sortable: true,
      className: "date",
    },
  ];
  const contentcolumns = [
    {
      Header: "Collection Title",
      sortable: true,
      accessor: "name",
      Cell: ({ value, row }) => (
        <TitleDescription
          title={value}
          description={row.original.id}
          id={row.original.id}
          onClick={(id) => navigate(`../${ROUTES.COLLECTIONS}/edit/${id}`)}
        />
      ),
    },
    {
      Header: "Status",
      accessor: "status", // accessor is the "key" in the data
      Cell: ({ value }) => {
        return (
          <Flex justifyContent="center">
            <Icon name={"check2"} color="#3886F7" />
          </Flex>
        );
      },
      sortable: true,
      className: "Content-management-published",
    },
    // {
    //   Header: "Type",
    //   accessor: "collection_type",
    //   sortable: true,
    //   className: "type",
    // },
    {
      Header: "Created",
      accessor: "created_at",
      sortable: true,
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
        </>
      ),
      className: "date",
    },
    {
      Header: "Updated",
      accessor: "updated_at",
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
        </>
      ),
      sortable: true,
      className: "date",
    },
  ];
  const onPaginationChange = ({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setContentPageSize(pageSize);
  };
  const toggleDisableEditing = () => setDisableEditing(!disableEditing);
  const onUpdateCollectionInformation = async (): Promise<any> => {};

  const onClickDeleteRow = (id) => {
    setCollectionContainedData((prev) =>
      prev.filter((item: any) => item.id !== id)
    );
  };
  const onDragRow = (reorderedRows) =>
    setCollectionContainedData(reorderedRows);

  return (
    <div id={idPanel} className="CollectionsContained">
      <Panel
        // id="items"
        title="Collections"
        // disableEditing={disabledEditing}
        // onEnableEditing={toggleDisableEditing}
        // onCancel={() => {
        //   setCollectionContainedData(collectioncontainedInitialData);
        //   toggleDisableEditing();
        // }}
        // save={onSaveCollectionItem}
        actions={
          <>
            {!disableEditing ? (
              <>
                <Button
                  variant="secondary-invert"
                  text="Cancel"
                  onClick={() => {
                    setCollectionContainedData(collectioncontainedInitialData);
                    toggleDisableEditing();
                  }}
                />
                <Button
                  variant="primary"
                  text="Save"
                  onClick={() => {
                    onSaveCollectionItem();
                  }}
                  // disabled={isLoading}
                />
              </>
            ) : (
              <Button
                variant="secondary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                  toggleDisableEditing();
                }}
                disabled={disabledEditing}
              />
            )}
          </>
        }
      >
        {/* {(isEditing) => ( */}
        <div>
          <Table
            className={""}
            data={
              collectionContainedData?.length ? collectionContainedData : []
            }
            columns={columns}
            hasPagination
            pageCount={getPageCount(
              collectionContainedData?.length,
              currentPageSize
            )}
            onPaginationChange={onPaginationChange}
            pageIndex={currentPageIndex}
            pageSize={currentPageSize}
            totalCount={collectionContainedData?.length}
            onClickDeleteRow={onClickDeleteRow}
            onDragRow={onDragRow}
            onClickSort={onClickSort}
            // hasPagination={!!isEditing()}
            hasDeletion={!disableEditing}
            hasDragging={!disableEditing}
            hasMoveTopDown={!disableEditing}
            manualPagination={false}
          />

          {!disableEditing && (
            <AddCollectionItemButton
              className="ml-5 mb-5"
              variant="link"
              // onClick={() => toggleDisableEditing}
              modalProps={{
                title: "Add Collection to Video",
                addButtonText: "Add Collection",
                searchPlaceholder: "Search",
                filters: collectionFilters,
                data: collectionsData || [],
                columns: contentcolumns,
                pageCount: getPageCount(
                  contentTotalCount,
                  collectionPageSize
                ),
                pageIndex: contentPageIndex,
                pageSize: collectionPageSize,
                hasPagination: true,
                onChangeFilter: onChangeFilter,
                onClickSort: onClickContentSort,
                onSearch: onContentSearch,
                onChangeSelection: onChangeSelectionCollection,
                onPaginationChange: onContentPaginationChange,
                onAddItem: onAddCollectionItemCollection,
                onCancel,
                totalCount: contentTotalCount,
              }}
            >
              + Add Video to Collection
            </AddCollectionItemButton>
          )}
        </div>
        {/* )} */}
      </Panel>
    </div>
  );
};

export default CollectionsContained;
