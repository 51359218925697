/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import './ContentManagementDetail.scss';
import { CONTENT_AD, DEFAULT_LANGUAGE, getLocaleByLanguage, getNativeLanguage } from '../../utils';
import { ContentDetailData, ContentLocalesData, CuePointData } from '../../interfaces/Content';
import Images from '../../components/Panel/Images';
import Availability from '../../components/Panel/Availability';
import CuePointsBar from '../../components/CuePointsBar/CuePointsBar';
import Button from '../../components/Button/Button';

import CuePoints from './Panels/CuePoints';
import ContentTrailer from './Panels/ContentTrailer';
import ContentAd from './Panels/ContentAd';

const CONTENT = '/content';
let player;
const DetailAdTrailer = ({ data, id, onSavedData, localesData }) => {
  const [isEditingPanel, setIsEditingPanel] = useState(false);
  const [currentLocalesData, setCurrentLocalesData] = useState<ContentLocalesData[]>([]);
  const [currentData, setCurrentData] = useState<ContentDetailData>();
  const [currentId, setCurrentId] = useState(id ? id : '');
  const [duration, ] = useState(0);
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const [currentImageData, setCurrentImages] = useState<any>([]);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [addNewCuePoint, setAddNewCuePoint] = useState<boolean>(false);
  const [editedCuePoints, setEditedCuePoints] = useState<CuePointData[]>();
  const [selectedCuePoint, setSelectedCuePoint] = useState<CuePointData | null>(null);

  // const onSuccess = (success) => {
  //   player = success.ref;
  //   player.on('durationchange', () => {
  //     setDuration(player.duration());
  //   });
  // };

  useEffect(() => {
    if (player) {
      player.currentTime(currentTime);
    }
  }, [currentTime]);

  useEffect(() => {
    // if (!config.account_id?.length) {
    //   // eslint-disable-next-line no-console
    //   console.error('Content Management - The player needs a REACT_APP_BC_ACCOUNT_ID');
    // } else {
    //   setCanShowPayer(true);
    // }
  }, [currentId]);

  useEffect(() => {
    if (!!currentLanguage && !!currentLocalesData?.length && !!currentData) {
      setCurrentImages(
        getImageData(
          getLocaleByLanguage(currentLocalesData, currentLanguage)?.images,
          currentId,
          currentLanguage
        )
      );
    }
  }, [currentLanguage]);

  useEffect(() => {
    if (!!data && !!id.length) {
      const nativeLanguage = getNativeLanguage(localesData) || DEFAULT_LANGUAGE;
      setCurrentData(data);
      setCurrentLocalesData(localesData);
      setCurrentId(id);
      setCurrentLanguage(nativeLanguage);

      // if (!config.account_id?.length) {
      //   // eslint-disable-next-line no-console
      //   console.error('Content Management - The player needs a REACT_APP_BC_ACCOUNT_ID');
      // } else {
      //   setCanShowPayer(true);
      // }

      setCurrentImages(
        getImageData(getLocaleByLanguage(localesData, currentLanguage)?.images, id, currentLanguage)
      );
    }
  }, [data, localesData, id]);

  useEffect(() => {
    if (!!data && !!id.length) {
      setCurrentData(data);
      setCurrentId(id);
    }
  }, [data, id]);

  const getDataContent = (ContentData: ContentDetailData, selectedId: string) => {
    const { reference_id, source, adKeys, adId, adType, adLanguage, advertising } = ContentData;

    return {
      id: selectedId,
      reference_id,
      source,
      adKeys,
      adId,
      adType,
      adLanguage,
      advertising,
    };
  };

  const getImageData = (imagesData: any[], selectedId: string, language: string) => {
    return {
      images: imagesData,
      id: selectedId,
      language,
    };
  };

  const getDataCuepoints = (ContentData: ContentDetailData, selectedId: string) => {
    const { cue_points } = ContentData;
    const tempCuepoints = editedCuePoints?.length ? editedCuePoints : cue_points;

    return {
      id: selectedId,
      cue_points: tempCuepoints.sort((a, b) => a.time - b.time),
    };
  };

  const getDataAvailability = (contentData: ContentDetailData) => {
    const { start_date, end_date, geo, locations, accessControls } = contentData;

    return {
      start_date,
      end_date,
      locations,
      accessControls,
      geo
    };
  };

  return (
    <>
      {currentData ? (
        <>
          {/* <Flex justifyContent="center" className="ReactVideoPlayer-container"> */}
            {/* {canShowPlayer && (
              <ReactPlayer
                accountId={config.account_id}
                videoId={currentData?.brightcoveVideoId || ''}
                onSuccess={onSuccess}
              />
            )} */}
          {/* </Flex> */}
          {currentData ? (
            <CuePointsBar
              cuePoints={editedCuePoints?.length ? editedCuePoints : currentData?.cue_points}
              duration={duration}
              selectedTime={currentTime}
              className="mt-6"
              onCuePointSelected={setSelectedCuePoint}
              onChangeTime={setCurrentTime}
            />
          ) : null}
          <span className="pl-11 pr-11 pb-8">
            <Flex className="mt-8">
              <Flex flexWrap="wrap" style={{ flex: 'auto' }}>
                <Button
                  variant="primary"
                  text="Add a cue point"
                  onClick={() => {
                    setAddNewCuePoint(true);
                  }}
                  className="mb-4"
                />
                {addNewCuePoint || selectedCuePoint ? (
                  <CuePoints
                    idPanel="sectionCuepoints"
                    data={getDataCuepoints(currentData, currentId)}
                    addNew={addNewCuePoint}
                    duration={duration}
                    onCancel={() => {
                      setAddNewCuePoint(false);
                      setSelectedCuePoint(null);
                    }}
                    selectedCuePoint={selectedCuePoint}
                    onChangeTime={setCurrentTime}
                    onSaveCuePoint={(cuePoints) => {
                      setEditedCuePoints(cuePoints);
                      onSavedData();
                    }}
                    onCuePointSelected={setSelectedCuePoint}
                  />
                ) : null}
                {currentData.type === CONTENT_AD ? (
                  <ContentAd
                    idPanel="sectionContent"
                    data={getDataContent(currentData, currentId)}
                    onSavedData={onSavedData}
                  />
                ) : (
                  <ContentTrailer idPanel="sectionContent" data={getDataContent(currentData, currentId)} />
                )}
                <Availability
                  idPanel="sectionAvailability"
                  data={getDataAvailability(currentData)}
                  endpointRootPath={CONTENT}
                  onEditModeChange={(editing, isSavedData) => {
                    setIsEditingPanel(editing);

                    if (isSavedData) {
                      onSavedData();
                    }
                  }}
                  disableEditing={isEditingPanel}
                />
                <Images
                  idPanel="sectionImages"
                  data={currentImageData}
                  endpointRootPath={CONTENT}
                  onEditModeChange={(editing, isSavedData) => {
                    setIsEditingPanel(editing);

                    if (isSavedData) {
                      onSavedData();
                    }
                  }}
                  disableEditing={isEditingPanel}
                />
              </Flex>
            </Flex>
          </span>
        </>
      ) : null}
    </>
  );
};

export default DetailAdTrailer;
