/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./NewDynamicString.scss";
import SubHeader from "../../components/SubHeader/SubHeader";
import { Flex, Icon } from "@brightcove/studio-components";
import { useNavigate, useParams } from "react-router";
import * as ROUTES from "../routes";
import Panel from "../../components/Panel/Panel";
import SimpleSelect from "../../components/ControlledComponents/SimpleSelect";
import TextInput from "../../components/ControlledComponents/TextInput";
import { useApi } from "../../hooks/useApi";
import Button from "../../components/Button/Button";
import { getUrlWithOptions } from "../../utils";
import { Toaster } from "../../utils";
import CONSTANTS from "../../constants";

const NewDynamicString: React.FunctionComponent<any> = ({ moduleName }) => {
  const { id } = useParams();
  const resource = "/ops/dynamic_strings";
  const DEFAULT_SCOPE = CONSTANTS.DYNAMIC_STRINGS.SCOPES[0];
  const DEFAULT_LANG = CONSTANTS.DYNAMIC_STRINGS.LANGS[0];
  const navigate = useNavigate();
  const { apiGet, apiPut, apiPost } = useApi();
  const [currentKeyTitle, setCurrentKeyTitle] = useState("");
  const [currentKey, setCurrentKey] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [currentId, setCurrentId] = useState<string>(id ? id : "");
  const [scope] = useState(CONSTANTS.DYNAMIC_STRINGS.SCOPES);
  const [currentScope, setScopeValue] = useState(DEFAULT_SCOPE);
  const [lang] = useState(CONSTANTS.DYNAMIC_STRINGS.LANGS);
  const [currentLang, setLangValue] = useState(DEFAULT_LANG);
  const [, setEditing] = useState(!id);


  const scopeOptions = CONSTANTS.DYNAMIC_STRINGS.SCOPES.map((value) => {
    return {
      label: value,
      value: value,
    };
  });

  const langOptions = CONSTANTS.DYNAMIC_STRINGS.LANGS.map((value) => {
    return {
      label: value,
      value: value,
    };
  });

  useEffect(() => {
    if (currentId?.length) {
      getData();
    }
  },[]);

  const getData = async () => {
    const { data } = await apiGet(
      getUrlWithOptions(`${resource}/${currentId}`)
    );
    // console.log("data" + JSON.stringify(data));
    setCurrentKeyTitle(data?.label);
    setCurrentKey(data?.label);
    setCurrentValue(data?.value);
    setScopeValue(data?.scope);
    setLangValue(data?.lang);
  };

  const onSubmit = async () => {
    let body;

    if (id?.length) {
      body = {
        label: currentKey,
        value: currentValue,
        scope: currentScope || DEFAULT_SCOPE,
        lang: currentLang || DEFAULT_LANG
      };

      const response = await apiPut(getUrlWithOptions(`${resource}/${id}`), {
        body,
      });

      if (response?.data?.label?.length) {
        Toaster.success({
          message: "Updated string saved",
        });
        setEditing(false);
      }
    } else {
      body = {
        label: currentKey,
        value: currentValue,
        scope: currentScope|| DEFAULT_SCOPE,
        lang: currentLang || DEFAULT_LANG
      };

      const response = await apiPost(getUrlWithOptions(`${resource}`), {
        body,
      });
      const createId = response?.data?.id;

      if (createId?.length) {
        setCurrentId(createId);
        navigate(`../${ROUTES.STRINGS}/edit/${createId}`);
      }
    }
  };

  return (
    <div className="main">
      <SubHeader
        icon={<Icon name="ARROW_RIGHT" rotate="180" />}
        title={
          !!currentKeyTitle?.length && !!currentId?.length
            ? currentKeyTitle
            : "New Strings"
        }
        subtitle={"Back to Dynamic Strings"}
        detailMode
        onBack={() => navigate(`../${ROUTES.STRINGS}`)}
        className="pl-5"
      />
      <div className="newString-content">
        <Panel
          className="mt-1"
          title={"String Details"}
        >
          <>
              <Flex {...{ flexDirection: "column" }}>
                <Flex {...{ flexDirection: "row" }}>
                  <div className="newString w-100">
                    <p className="newString-title">Key *</p>
                    <TextInput
                      value={currentKey}
                      onChange={setCurrentKey}
                      className="newString-box"
                      disabled={false}
                    />
                  </div>
                  <div className="newString pl-5 w-100">
                    <p className="newString-title">Scope *</p>
                      <SimpleSelect
                      className="newString-select"
                      value={currentScope}
                      options={scopeOptions as any}
                      onChange={(value) => {
                        setScopeValue(value);
                      }}
                    />
                  </div>
                </Flex>
                <Flex className="pt-5">
                  <div className="newString w-100">
                    <p className="newString-title">Value *</p>
                    <textarea
                      className="newString-body"
                      value={currentValue}
                      onChange={(e) => {
                        setCurrentValue(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="newString pl-5 w-100">
                    <p className="newString-title">Language *</p>
                      <SimpleSelect
                      className="newString-select"
                      value={currentLang}
                      options={langOptions as any}
                      onChange={(value) => {
                        setLangValue(value);
                      }}
                    />
                  </div>
                </Flex>
                <Flex className="newString-buttonFlex" align-items="flex-end" justify-content="center">
                  <Button
                    variant="secondary"
                    text="Cancel"
                    onClick={() => {
                      navigate(`../${ROUTES.STRINGS}`);
                    }}
                  />
                  <Button
                    variant="primary"
                    text={currentId ? "Save" : "Create"}
                    onClick={onSubmit}
                    disabled={!currentKey?.length || !currentValue?.length}
                  />
                </Flex>
              </Flex>
          </>
        </Panel>
      </div>
    </div>
  );
};

export default NewDynamicString;
