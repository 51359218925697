import { Button } from "@brightcove/studio-components";
import React from "react";
import { SVGImage } from "../../assets/images";
import { useApi } from "../../hooks/useApi";
import {
  AccessOptions,
  formatDate,
  getUrlWithOptions,
  PermissionLevel,
  PermissionLevelOptions,
} from "../../utils";
import { CheckboxGroup } from "../ControlledComponents";
import { DeleteConfirmationModal, withModal } from "../Modals";
import Row from "./Row";
import "./UserRow.scss";
import PERMISSIONS from "../../constants";

const RemoveUserButton: React.FunctionComponent<any> = ({ onClick }) => {
  return (
    <button
      className="btn-red-simple validation-btn remove-user-btn"
      onClick={onClick}
    >
      Remove user
    </button>
  );
};

const RemoveUserButtonWithModal = withModal(
  RemoveUserButton,
  DeleteConfirmationModal
);

const SaveChangesButton: React.FunctionComponent<any> = ({
  user: { id, displayName, email },
  getPermissions,
  onCompleted,
  onError,
  ...props
}) => {
  const { apiPatch } = useApi();
  const updateUser = async (values) => {
    const { data, error } = await apiPatch(
      getUrlWithOptions(`/ops/users/${values.id}`),
      { body: values.data }
    );
    if (error) {
      onError(error);
    }
    if (data) {
      onCompleted(data);
    }
  };
  const NameArr = displayName?.split(" ");
  const firstName = NameArr?.splice(0, 1)[0];
  const lastName = NameArr?.join(" ");
  return (
    <Button
      className="btn-red validation-btn"
      text="Save Changes"
      type="submit"
      onClick={() => {
        updateUser({
          id,
          data: {
            first_name: firstName,
            last_name: lastName,
            // displayName,
            email,
            permissions: getPermissions(),
          },
        });
      }}
    />
  );
};

class UserRow extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      enableEditUser: false,
      permissions: {},
    };
  }

  componentDidMount() {
    this.mapPermissionsToState();
  }

  mapPermissionsToState() {
    const mappedPermissions = {};
    const userPermissions = this.props.user.permissions;
    if (userPermissions) {
      for (const permission of Object.keys(userPermissions)) {
        const level = userPermissions[permission].split("_")[0]; // WRITE_MODULE or READ_MODULE
        if (level === PermissionLevel.WRITE) {
          mappedPermissions[permission] = ["READ", "WRITE"];
        } else if (level === PermissionLevel.READ) {
          mappedPermissions[permission] = ["READ"];
        }
      }
    }
    this.setState({ permissions: mappedPermissions });
  }

  toggleEditMode = () => {
    this.setState({
      enableEditUser: !this.state.enableEditUser,
    });
  };

  onClickEdit = () => {
    const { enableEditUser, permissions, savedPermissions } = this.state;

    /* handle situation where user makes changes but decides to cancel without saving */
    if (enableEditUser) {
      this.setState({ permissions: savedPermissions });
    } else {
      this.setState({ savedPermissions: { ...permissions } });
    }

    this.toggleEditMode();
  };

  isPermitted = (moduleName, permissionLevel) => {
    return (
      this.state.permissions[moduleName] &&
      this.state.permissions[moduleName].includes(permissionLevel)
    );
  };

  onChangePermissions = async (change, moduleName) => {
    const updatedPermissions = { ...this.state.permissions };
    updatedPermissions[moduleName] =
      change.length === 1 &&
      change.includes(PermissionLevel.WRITE) &&
      (!updatedPermissions[moduleName] ||
        updatedPermissions[moduleName].length !== 2) // add READ when WRITE is checked
        ? change.concat(PermissionLevel.READ)
        : change.length === 1 &&
          change.includes(PermissionLevel.WRITE) &&
          updatedPermissions[moduleName].includes(PermissionLevel.READ) // remove WRITE if READ is unchecked
        ? []
        : change;
    this.setState({ permissions: updatedPermissions });
  };

  onError = (data) => {
    console.error(data);

    this.setState({ permissions: this.state.savedPermissions });
  };

  onCompleted = (data) => {
    if (!data) {
      return;
    }
    this.toggleEditMode();
  };

  getPermissions = () => {
    const updatedPermissions: any = {};
    const { permissions } = this.state;
    for (const moduleName of Object.keys(permissions)) {
      if (permissions[moduleName] && permissions[moduleName].length > 0) {
        // only include permission if it exists
        let moduleValue = Object.keys(PERMISSIONS.PERMISSIONS).filter(
          (key) => PERMISSIONS.PERMISSIONS[key] === moduleName
        );
        updatedPermissions[moduleName] = `${
          permissions[moduleName].length > 1 && moduleValue?.[0]
            ? PermissionLevel.WRITE
            : PermissionLevel.READ
        }_${moduleValue?.[0]}`;
      }
    }
    return updatedPermissions;
  };

  isSelf = () => {
    const {
      store: { user: siteUser },
      user: rowUser,
    } = this.props;
    return siteUser.id === rowUser.id;
  };

  render() {
    const {
      user: { id, displayName, email, lastLoggedInAt },
      onDeleteUser,
      hasWriteAccess
    } = this.props;
    const { enableEditUser, permissions } = this.state;
    const isEditUserEnabled = true;
    // const  adminPermissions  = useSelector((state:any) => state.permissions);

    // const { hasWriteAccess } = getModulePermissions(ModuleName.USER_MANAGEMENT_SCREEN, this.getPermissions);
    
    return (
      <Row key={`row-${id}`}>
        <div className="user-info">
          <div className="user-details">
            {hasWriteAccess ? 
            <button
              className={`edit-user-btn${
                isEditUserEnabled ? "" : " restricted"
              }`}
              onClick={isEditUserEnabled ? this.onClickEdit : undefined}
            >
              <span className="user-name">{displayName}</span>
              {isEditUserEnabled  && (
                <img className="edit-icon" alt="pencil" src={SVGImage.Pencil} />
              )}
            </button> : <span className="user-name">{displayName}</span>}
            <div className="user-email">{email}</div>
          </div>
          {enableEditUser && (
            <div className="user-btns">
              <SaveChangesButton
                getPermissions={this.getPermissions}
                onCompleted={this.onCompleted}
                user={this.props.user}
                onError={this.onError}
                disabled={!hasWriteAccess}
              />
              <RemoveUserButtonWithModal
                onModalConfirm={onDeleteUser}
                disabled={!hasWriteAccess}
                modalProps={{
                  idToDelete: id,
                  type: "users",
                  title: "Remove User",
                  confirmBtnLabel: "Confirm",
                  children: (
                    <>
                      Are you sure you would like to remove {displayName}? This
                      action cannot be undone.
                    </>
                  ),
                }}
              />
            </div>
          )}
        </div>
        <div className={`last-login${lastLoggedInAt ? "" : " empty"}`}>
          <div>
            {lastLoggedInAt ? (
              formatDate(lastLoggedInAt, true, "YYYY/MM/DD h:mm A")
            ) : (
              <div className="no-content-dash"></div>
            )}
          </div>
        </div>

        {enableEditUser
          ? AccessOptions.map((permissionOption, index) => {
              return (
                <CheckboxGroup
                  key={`${id}-${permissionOption.value}`}
                  property={permissionOption.value}
                  onChange={this.onChangePermissions}
                  values={
                    permissions[permissionOption.value]
                      ? permissions[permissionOption.value]
                      : []
                  }
                  options={PermissionLevelOptions}
                  className="user-row-item permission-level-option"
                />
              );
            })
          : AccessOptions.map((permission, index) => {
              return (
                <div key={permission.value} className="user-row-item">
                  <div
                    className={`permission-label read${
                      this.isPermitted(permission.value, PermissionLevel.READ)
                        ? " allowed"
                        : ""
                    }`}
                  >
                    <img
                      className="status-icon"
                      alt="check mark"
                      src={SVGImage.StatusActive}
                    />
                    Read
                  </div>
                  <div
                    className={`permission-label write${
                      this.isPermitted(permission.value, PermissionLevel.WRITE)
                        ? " allowed"
                        : ""
                    }`}
                  >
                    <img
                      className="status-icon"
                      alt="check mark"
                      src={SVGImage.StatusActive}
                    />
                    Write
                  </div>
                </div>
              );
            })}
      </Row>
    );
  }
}

export default UserRow;
