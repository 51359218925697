import FirebaseAuthenticator from "../../auth/Auth-Firebase";
import { Button, Icon, Input } from "@brightcove/studio-components";
import React, { useState } from "react";
import "./Profile.scss";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import SubHeader from "../../components/SubHeader/SubHeader";

interface ProfileProps {}


const SaveChangesButton: React.FunctionComponent<any> = ({
  newPassword,
  isValidInput,
  onCompleted,
  onError,
  setChanges,
  setSuccess
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const authenticator = new FirebaseAuthenticator();
  const handleSubmit = async () => {
    if (isValidInput() && newPassword.length > 0) {
      setIsLoading(true);
      try {
        authenticator.updateNewPassword(newPassword, (success) => {
          if(success && onCompleted) {
            onCompleted(success);
          }
          setSuccess(true)
          setChanges({})
        }, (error) => {
          if(error && onError) {
            onError(error);
          }
        });
      } catch (error) {
        onError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Button className="btn-red validation-btn" text="Save Changes" type="submit" onClick={handleSubmit} disabled={isLoading} />
  );
};

const Profile: React.FunctionComponent<ProfileProps> = () => {
  const [changes, setChanges] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [success, setSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const email = useSelector((state:any) => state.email);
  const name  = useSelector((state:any) => state.name);
  const firstName = name.split(' ')[0];
  const lastName = name.split(' ')[1];
  const successMessage = "Your password was successfully updated.";

  const onInputChange = (change: any, event: React.ChangeEvent<HTMLInputElement>) => {
    const property = event.target.name;
    setChanges({ ...changes, [property]: change });
  };

  const isValidInput = () => {
    const newErrors: any = {};
    setErrors({});
    if (changes && changes['newPassword'] && (changes['newPassword'].length < 8 || changes['newPassword'].length > 20)) {
      newErrors.newPassword = "Your password must be at least 8 characters long, contain at least one number, contain at least one special character and have a mixture of uppercase and lowercase letters.";
    } else if (isValidPassword(changes['newPassword']) === false) {
      newErrors.newPassword = "Your password must be at least 8 characters long, contain at least one number, contain at least one special character and have a mixture of uppercase and lowercase letters.";
    } else if (changes && changes['newPassword'] && changes['newPassword'] !== changes['confirmPassword']) {
      newErrors.confirmPassword = "New password and confirm new password do not match.";
    }
    console.log('isValidInput ', newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onError = (error: Error) => {
    const newErrors: any = { ...errors };
    if (error.message === "Incorrect password") {
      newErrors.currentPassword = "Current password is incorrect";
    } else {
      // Handle other errors
    }
    setErrors(newErrors);
  };

  const isValidPassword = (passwordString) => {
  let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@#$%*?^&+=!])[A-Za-z\d.@#$%*?^&+=!]{8,20}$/;
  if (regex.test(passwordString)) {
    return true;
  } else {
    return false;
  }
}

  const onCompleted = () => {
    // show success message
    console.log("hereee");
    setChanges({});
    setSuccess(true);
  }

  return (
    <div className="main">
      <div className="content">
           <SubHeader
        icon={<Icon name="ARROW_RIGHT" rotate="180" />}
        title="Profile Information"
        subtitle={"Back"}
        detailMode
        onBack={() => {navigate(-1)}}
        className="pl-5"
        actions={
          <></>
        }
      />

        <div className="profile-content">
          <h3 className="profile-subheader">Personal</h3>
          <div className="profile-grid">
            <div className="firstName">
              <p className="profile-label">First Name</p>
              <p className="profile-text">{firstName}</p>
            </div>
            <div className="lastName">
              <p className="profile-label">Last Name</p>
              <p className="profile-text">{lastName}</p>
            </div>
          </div>
          <div className="profile-grid">
            <div className="email">
              <p className="profile-label">Email Address</p>
              <p className="profile-text-email">{email}</p>
            </div>
          </div>
          <div className="profile-grid fill">
            <Input className={`profile-item${errors.newPassword ? " error" : ""}`}
              name="newPassword"
              type="password"
              label="New Password (8-20 characters)"
              value={(changes && changes['newPassword']) || ""}
              onChange={onInputChange} />
            <Input className={`profile-item${errors.confirmPassword ? " error" : ""}`}
              name="confirmPassword"
              type="password"
              label="Confirm New Password"
              value={(changes && changes['confirmPassword']) || ""}
              onChange={onInputChange} />
              
            <div className="password-instructions">
              <span>Password must: </span>
              <div>
                <p>Be 8 to 20 characters long</p>
                <p>Have at least 1 uppercase letter</p>
                <p>Have at least 1 lowercase letter</p>
                <p>Have at least 1 number</p>
                <p>Have at least 1 special character</p>
              </div>
            </div>
          </div>
          <div className="btn-group">
           
            <div className="button-div">
              <SaveChangesButton
              newPassword={(changes && changes['newPassword'])}
              onCompleted={onCompleted}
              onError={onError}
              isValidInput={isValidInput}
              setChanges={setChanges}
              setSuccess={setSuccess}
            />
              </div> 
              <div className="message" >
              { Object.entries(errors).map(([key, value], index) => <div key={index} className="error-message">{value + ''}</div>) }
              { success ? <div className="success-message"> {successMessage} </div>: <></>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
