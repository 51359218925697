import classnames from "classnames";
import React from "react";
// import { ImageSelectionModal, withModal } from "../Modals";
import ControlledProps from "./ControlledProps";

interface ImageProps {
  onChange?: (image, property) => void
  image: any
  content?: any
  className?: string
  text?: string
}

// const SelectImageButton = withModal(Button, ImageSelectionModal);

export default class ImageInput extends React.Component<ControlledProps & ImageProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      image: props.image,
    }
  }

  componentDidMount() {
    const { onChange, property } = this.props;
    const { image } = this.state;
    if (onChange) {
      onChange(image, property);
    }
  }

  onChange = (selections) => {
    const { onChange, property } = this.props;
    const image = selections[0];
    this.setState({ image })
    if (onChange) {
      onChange(image, property);
    }
  }

  render() {
    const { className } = this.props;
    const { image } = this.state;
    return (
      <div className={classnames(["image-input", className])}>
        {(image.url || image) && <div><img alt="" src={image.url || image} /></div>}
        {/* <SelectImageButton
          className="btn-red"
          small
          text={text || "Change Image"}
          onModalConfirm={this.onChange}
          modalProps={{
            content,
          }}/> */}
      </div>
    )
  }
}
