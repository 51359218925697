/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Flex, Input } from "@brightcove/studio-components";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SVGImage } from "../../assets/images";
import FirebaseAuthenticator from "../../auth/Auth-Firebase";
import * as ROUTES from "../routes";
import "./CreatePasswordPage.scss";
import constants from "../../constants";

const CreatePasswordPage: React.FunctionComponent<any> = ({ store: { language }, onSignIn }) => {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [code, setCode] = useState('');
  const [errors, setErrors] = useState({
    code: "",
    repeatedPassword : "",
    invalidUser: "",
    password: "",
  });
  const [successfulReset, setSuccessfulReset] = useState(false);
  const navigate = useNavigate();

  const authenticator = new FirebaseAuthenticator();

  const onKeyDown = (event) => {
    if (event.key === "Enter" && canSubmit()) {
      console.log("calling onSubmit");
      onSubmit();
    }
  }

  useEffect(() => {
    // set OObCode from url
    const codeFromURL = searchParams.get('oobCode') || '';
    setCode(codeFromURL);
  },[]);

  const redirectToLogin = () => {
    navigate(ROUTES.LOGIN);
    // if (event.key === "Enter" && canSubmit()) {
    //   onSubmit();
    // }
  }

  // const isValidEmail = (emailString) => {
  //   return emailString.match(/^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  // }

  const isValidPassword = (passwordString) => {
    return passwordString.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,20}$/);
  }

  const canSubmit = () => {
    return password.length > 0 && repeatedPassword.length > 0 && code.length > 0;
  }

  const onChangeFn = (setFieldFunction) => {
    return (value) => {
      console.log("changed valu is", value);
      setFieldFunction(value);
      setErrors({
        code: "",
        invalidUser: "",
        repeatedPassword : "",
        password: "",
      });
    }
  }

  const onSubmit = () => {
    console.log("onSubmit called");
    if (!isValidPassword(password)) {
      setErrors({
        ...errors,
        password : constants.ERROR_MESSAGES.PASSWORD_CRITERIA_FAIL,
      });
    } else if (password !== repeatedPassword) {
      setErrors({
        ...errors,
        repeatedPassword : constants.ERROR_MESSAGES.CONFIRM_PASSWORD_MISMATCH
      });
    } else {
      console.log("to do call reset and redirect to login", password, code);
      // authenticator.authenticate(email, password, handleAuthResult);
    }
  }

  const onPasswordReset = () => {
    if(!isValidPassword(password)) {
      setErrors({
        ...errors,
        password : constants.ERROR_MESSAGES.PASSWORD_CRITERIA_FAIL,
      });
    } else if (password !== repeatedPassword) {
      setErrors({
        ...errors,
        repeatedPassword : constants.ERROR_MESSAGES.CONFIRM_PASSWORD_MISMATCH
      });
    } else {
      // show error Invalid verification code provided, please try again - https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_2
      authenticator.confirmNewPassword(code, password, (result) => {
        if (result) {
          setPassword("");
          setRepeatedPassword("");
          setCode("");
          setErrors({
            code: "",
            invalidUser : "",
            repeatedPassword: "",
            password: "",
          });
          console.log('setSuccessfulReset setting');
          setSuccessfulReset(true);
          console.log('setSuccessfulReset set complete');
        }
      }, (error) => {
        if (error) {
          errors.code = constants.ERROR_MESSAGES.INVALID_CODE;
          setErrors({
            ...errors,
          });
        }
      });
    }

  }

  return (
    <div className="CreatePasswordPage">
      <div className="loginContent resetPasswordContent">
        <img className="login-logo" src={SVGImage.One31Logo} alt="One31 Logo" />
        {/* <div className="sign-in-error">{errors.invalidUser}</div> */}
        {successfulReset ?
          <React.Fragment>
            <p className="heading">Your password was successfully reset.</p>
            <Flex className="sign-in-btn" justifyContent="center">
              <Button className="red" small text="Click here to sign in" onClick={redirectToLogin} />
            </Flex>
          </React.Fragment>
          :
          <React.Fragment>
            <p className="heading">Create Password</p>
            <Input className={`sign-in-field${errors.password ? " error" : ""}`} label="New Password" placeholder="Create your password" type="password" value={password} onChange={onChangeFn(setPassword)} required />
            <div className="sign-in-error sub-error">{errors.password}</div>

            <span onKeyDown={onKeyDown}>
            <Input className={`sign-in-field${errors.repeatedPassword ? " error" : ""}`} label="Confirm password" placeholder="Confirm password" type="password" value={repeatedPassword} onChange={onChangeFn(setRepeatedPassword)} required />
              <div className="sign-in-error sub-error">{errors.repeatedPassword}</div>
            </span>

            <Input className={`sign-in-field${errors.code ? " error" : ""}`} label="Verification Code" placeholder="Code" value={code} onChange={onChangeFn(setCode)} required />
            <div className="sign-in-error sub-error">{errors.code}</div>

            <div className="password-instructions">
              <span>Password must: </span>
              <div>
                <p>Be 8 to 20 characters long</p>
                <p>Have at least 1 uppercase letter</p>
                <p>Have at least 1 lowercase letter</p>
                <p>Have at least 1 number</p>
                <p>Have at least 1 special character</p>
              </div>
            </div>
            <Flex className="sign-in-btn" justifyContent="center">
              <Button className={canSubmit() ? "red" : ""} small text="Create Password" onClick={onPasswordReset} disabled={!canSubmit()} />
            </Flex>
          </React.Fragment>
        }
      </div>
    </div>
  )
};

export default CreatePasswordPage;
