/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Button, Input } from "@brightcove/studio-components";
import _ from "lodash";
import React, { useEffect, useState } from "react"
import { useApi } from "../../hooks/useApi";
import { getUrlWithOptions, PermissionLevel } from "../../utils";
import "./Subscription.scss";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import Loading from "../../components/Loading/Loading";
import FirebaseAuthenticator from "../../auth/Auth-Firebase";

const NewUserProps = {
    email: "newUserEmail",
    phone: "newUserphone",
    userId: "newUseruserId",
};

interface IUserManagementErrors {
    email?: string,
    phone?: string,
    userId?: string,
    user?: string
};

const SearchUserButton: React.FunctionComponent<any> = ({ changes, setLoading, isValidInput, onCompleted, onSubmit, onError, setChanges, ...props }) => {
    const { apiPost } = useApi();
    const searchUser = async (values) => {
        setLoading(true);

        const body = {
            phone: values.phone,
            userId: values.userId,
            email: values.email
        }
        console.log("body is", body);

        const { data, error } = await apiPost(
            getUrlWithOptions(`/ops/user/subscription_status`),
            {
                body,
            },
        );
        if (error) {
            onError(error);
            setLoading(false);
        }
        if (data) {
            onCompleted(data);
            setLoading(false);
        }

    };
    const handleSubmit = async () => {
        const valid = await onSubmit();
        if (valid) {
            await searchUser({
                email: changes && changes[NewUserProps.email],
                phone: changes && changes[NewUserProps.phone],
                userId: changes && changes[NewUserProps.userId]
            });
            setChanges({});
        }
    };
    return (
        <Button className="btn-red validation-btn" text="Search User" type="submit" onClick={handleSubmit} />
    )
};

const Subscription: React.FunctionComponent<any> = () => {
    const [changes, setChanges] = React.useState<object>({});
    const [errors, setErrors] = React.useState<any>({});
    const [loading, setLoading] = useState(false);
    const [user, setUser] = React.useState<any>(null);

    const onInputChange = (change, event) => {
        const property = event.target.name;
        // onChange(change, property);
        setChanges({
            ...changes,
            [property]: change,
        })
    }

    const isValidEmail = (email) => {
        return email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    }

    const isValidInput = () => {
        return _.isEmpty(errors);
    }

    const onSubmit = async () => {
        const validationErrors: IUserManagementErrors = {};
        if (_.isEmpty(changes)) {
            validationErrors.email = "A valid email address or phone number or user id is required";
        } else if ((NewUserProps.email in changes) && (!changes[NewUserProps.email] || !isValidEmail(changes[NewUserProps.email]))) {
            validationErrors.email = "A valid email address or phone number or user id is required";
        } else if ((NewUserProps.phone in changes) && (!changes[NewUserProps.phone])) {
            validationErrors.phone = "A valid email address or phone number or user id is required";
        } else if ((NewUserProps.userId in changes) && (!changes[NewUserProps.userId])) {
            validationErrors.userId = "A valid email address or phone number or user id is required";
        }

        setErrors(validationErrors);
        return _.isEmpty(validationErrors);
    }

    const onCancel = () => {
        setChanges({});
        setErrors({});
    }

    const onError = (error) => {
        console.error("" + error);
        const validationErrors: IUserManagementErrors = {};
        validationErrors.user = error?.message?.includes('NotFoundError') ? 'No user found' : error?.message;
        setErrors(validationErrors);
        setUser(null);
    }

    const clearData = () => {
        setChanges({});
    }

    return (
        <>
            {loading && <Loading />}
            <div className="main">
                <div className="content">
                    <SectionHeader title="Subscription" />
                    <div className="subscription-content">
                        <div className="subscription-section">
                            <div className="subscription-info">
                                <div className="subscription-field-container">
                                    <Input className={`subscription-field${errors.email ? " error" : ""}`}
                                        name={NewUserProps.email}
                                        label="Email"
                                        placeholder="Email"
                                        value={(changes && changes[NewUserProps.email]) || ""}
                                        onChange={onInputChange}
                                        onFocus={() => setErrors({})} />
                                    {errors.email && <div className="error-message">{errors.email}</div>}
                                </div>
                                <div className="subscription-field-container">
                                    <Input className={`subscription-field${errors.phone ? " error" : ""}`}
                                        name={NewUserProps.phone}
                                        label="Phone Number"
                                        placeholder="Phone Number"
                                        value={(changes && changes[NewUserProps.phone]) || ""}
                                        onChange={onInputChange}
                                        onFocus={() => setErrors({})} />
                                    {errors.phone && <div className="error-message">{errors.phone}</div>}
                                </div>
                                <div className="subscription-field-container">
                                    <Input className={`subscription-field${errors.userId ? " error" : ""}`}
                                        name={NewUserProps.userId}
                                        label="User Id / SP Account Id"
                                        placeholder="User Id / SP Account Id"
                                        value={(changes && changes[NewUserProps.userId]) || ""}
                                        onChange={onInputChange}
                                        onFocus={() => setErrors({})} />
                                    {errors.userId && <div className="error-message">{errors.userId}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="add-user-validation-btns">
                            <Button look='secondary' className="btn-red cancel validation-btn" text="Cancel" onClick={onCancel} />
                            <SearchUserButton
                                changes={changes}
                                setLoading={setLoading}
                                onError={onError}
                                onCompleted={(data) =>
                                    setUser(data)}
                                onSubmit={
                                    onSubmit}
                                isValidInput={isValidInput}
                                setChanges={() => clearData()} />
                        </div>
                    </div>

                    {errors.user && <div className="user-error-message" >{errors.user}</div>}
                    {user && (<table className='subscription-content user_data_table'>
                        <tbody>
                            <tr>
                                <td width="10%">First name</td>
                                <td width="50%">{user?.firstName}</td>
                            </tr>
                            <tr>
                                <td width="10%">Last name</td>
                                <td width="50%">{user?.lastName}</td>
                            </tr>
                            <tr>
                                <td width="10%">Gender</td>
                                <td width="50%">{user?.gender}</td>
                            </tr>
                            <tr>
                                <td width="10%">Email</td>
                                <td width="50%">{user?.email}</td>
                            </tr>
                            <tr>
                                <td width="10%">Phone number</td>
                                <td width="50%">{user?.phone}</td>
                            </tr>
                            <tr>
                                <td width="10%">SP Account ID</td>
                                <td width="50%">{user?.subscription?.sp_account_id}</td>
                            </tr>
                            <tr>
                                <td width="10%">Subscription status</td>
                                <td width="50%">{user?.subscription?.subscription_status}</td>
                            </tr>
                            <tr>
                                <td width="10%">Renewal date</td>
                                <td width="50%">{user?.subscription?.subscription_end_date}</td>
                            </tr>
                            <tr>
                                <td width="10%">Payment method</td>
                                <td width="50%">{user?.subscription?.payment_method}</td>
                            </tr>
                            <tr>
                                <td width="10%">Subscription plan</td>
                                <td width="50%">{user?.subscription?.subscription_plan_name}</td>
                            </tr>
                        </tbody>
                    </table>)}
                </div>
            </div>
        </>
    );
}

export default Subscription;
